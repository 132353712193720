<!-- <header-share></header-share> -->

<main id="main">
  <section class="top-box">
    <h2>간편 견적 내기</h2>
    <p>
      쉽고 빠르게 내 서비스의 견적을 확인 하세요.<br />
      견적과 함께 문의 시 더욱 명확한 상담이 가능합니다.
    </p>
  </section>
  <div class="all-wrap">
    <section class="check-area">
      <h2>
        기본 정보를 선택해주세요. <span class="blue-text">[필수 사항]</span>
      </h2>
      <span class="notice-area"
        >기능정보는 기본정보를 모두 체크 시 선택 가능합니다.</span
      >
      <section class="choice-box">
        <div class="card-wrap" id="osBox">
          <h3>
            <span class="m-title">{{ os.mainTitle }}</span>
            <span class="blue-text os-title">{{ os.sub }}</span>
          </h3>
          <div class="card-box">
            <mat-card *ngFor="let item of os.osDetail">
              <mat-card-header>
                <mat-card-title>{{ item.title }}</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <!-- <p>{{ item.price | number }}원</p> -->
                <p>{{ item.con }}</p>
              </mat-card-content>

              <mat-card-actions>
                <mat-button-toggle
                  [checked]="item.check"
                  (change)="chooseOs(item)"
                  >선택 하기</mat-button-toggle
                >
              </mat-card-actions>
            </mat-card>
          </div>
        </div>

        <div class="radio-card-wrap" id="pageBox">
          <h3>
            <span class="m-title"> {{ page.mainTitle }}</span>
            <span class="gray-text">{{ page.sub }}</span>
          </h3>
          <div class="card-box">
            <mat-button-toggle-group
              name="pageNum"
              aria-label="페이지 수"
              [(ngModel)]="pageNum"
              (change)="pageChoice('change')"
            >
              <mat-card *ngFor="let item of page.pageDetail">
                <mat-card-header>
                  <mat-card-title>{{ item.title }}</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <!-- <p>{{ item.price | number }}원</p> -->
                  <p>{{ item.con }}</p>
                </mat-card-content>

                <mat-card-actions>
                  <mat-button-toggle [value]="item" [checked]="item.check"
                    >선택 하기</mat-button-toggle
                  >
                </mat-card-actions>
              </mat-card>
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="radio-card-wrap">
          <h3>
            <span class="m-title">
              {{ plan.mainTitle }}
            </span>
            <span class="gray-text">{{ plan.sub }}</span>
          </h3>
          <div class="card-box" id="planBox">
            <mat-button-toggle-group
              name="plan"
              aria-label="기획"
              [(ngModel)]="planPick"
              (change)="planChoice('change')"
            >
              <mat-card *ngFor="let item of plan.planDetail">
                <mat-card-header>
                  <mat-card-title>{{ item.title }}</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <!-- <p>{{ item.price | number }}원</p> -->
                  <p>{{ item.con }}</p>
                </mat-card-content>

                <mat-card-actions>
                  <mat-button-toggle [value]="item" [checked]="item.check"
                    >선택 하기</mat-button-toggle
                  >
                </mat-card-actions>
              </mat-card>
            </mat-button-toggle-group>
          </div>
        </div>

        <div class="radio-card-wrap">
          <h3>
            <span class="m-title">
              {{ design.mainTitle }}
            </span>
            <span class="gray-text">{{ design.sub }}</span>
          </h3>
          <div class="card-box" id="designBox">
            <mat-button-toggle-group
              name="design"
              aria-label="디자인"
              [(ngModel)]="designPick"
              (change)="designChoice('change')"
            >
              <mat-card *ngFor="let item of design.designDetail">
                <mat-card-header>
                  <mat-card-title>{{ item.title }}</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <!-- <p>{{ item.price | number }}원</p> -->
                  <p>{{ item.con }}</p>
                </mat-card-content>

                <mat-card-actions>
                  <mat-button-toggle
                    value="item.designDetail"
                    [value]="item"
                    [checked]="item.check"
                    >선택 하기</mat-button-toggle
                  >
                </mat-card-actions>
              </mat-card>
            </mat-button-toggle-group>
          </div>
        </div>
      </section>
    </section>

    <!-----------***두번째 섹션---------------->
    <section class="check-area" [ngClass]="{ 'choose-box': !activeChoose() }">
      <h2>
        기능 정보를 선택해주세요. <span class="blue-text">[선택 사항]</span>
      </h2>

      <section
        class="choice-box"
        *ngFor="let info of additional; let i = index"
      >
        <div class="card-wrap" *ngIf="info.inputType == 'multiple'">
          <h3>
            <span class="m-title">{{ info.mainTitle }}</span>
            <span class="css-plus">
              <span class="blue-text .blue-text }">[중복 선택 가능]</span>
              <span class="gray-text">{{ info.sub }}</span>
            </span>
          </h3>
          <div class="card-box">
            <mat-card *ngFor="let item of info.detail">
              <mat-card-header>
                <mat-card-title>{{ item.title }}</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <!-- <p>{{ item.price | number }}원</p> -->
                <p>{{ item.con }}</p>
              </mat-card-content>

              <mat-card-actions>
                <mat-button-toggle
                  value="item"
                  [checked]="item.check"
                  (change)="subchoose(info, item)"
                  >선택 하기</mat-button-toggle
                >
              </mat-card-actions>
            </mat-card>
          </div>
        </div>

        <!--라디오-->
        <div
          class="radio-card-wrap"
          *ngIf="
            info.inputType == 'radio' && info.mainTitle == '푸시 메시지 기능'
          "
        >
          <h3>
            <span class="m-title"> {{ info.mainTitle }}</span>
            <span class="gray-text">{{ info.sub }}</span>
          </h3>
          <div class="card-box">
            <mat-button-toggle-group
              [(ngModel)]="pushCk"
              (change)="subchoose(info, pushCk)"
            >
              <mat-card *ngFor="let item of info.detail">
                <mat-card-header>
                  <mat-card-title>{{ item.title }}</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <!-- <p>{{ item.price | number }}원</p> -->
                  <p>{{ item.con }}</p>
                </mat-card-content>

                <mat-card-actions>
                  <mat-button-toggle [value]="item" [checked]="item.check"
                    >선택 하기</mat-button-toggle
                  >
                </mat-card-actions>
              </mat-card>
            </mat-button-toggle-group>
          </div>
        </div>
        <div
          class="radio-card-wrap"
          *ngIf="
            info.inputType == 'radio' && info.mainTitle == '지도 관련 기능'
          "
        >
          <h3>
            <span class="m-title"> {{ info.mainTitle }}</span>
            <span class="gray-text">{{ info.sub }}</span>
          </h3>
          <div class="card-box">
            <mat-button-toggle-group
              [(ngModel)]="mapCk"
              (change)="subchoose(info, mapCk)"
            >
              <mat-card *ngFor="let item of info.detail">
                <mat-card-header>
                  <mat-card-title>{{ item.title }}</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <!-- <p>{{ item.price | number }}원</p> -->
                  <p>{{ item.con }}</p>
                </mat-card-content>

                <mat-card-actions>
                  <mat-button-toggle [value]="item" [checked]="item.check"
                    >선택 하기</mat-button-toggle
                  >
                </mat-card-actions>
              </mat-card>
            </mat-button-toggle-group>
          </div>
        </div>
        <div
          class="radio-card-wrap"
          *ngIf="info.inputType == 'radio' && info.mainTitle == '다국어 처리'"
        >
          <h3>
            <span class="m-title"> {{ info.mainTitle }}</span>
            <span class="gray-text">{{ info.sub }}</span>
          </h3>
          <div class="card-box">
            <mat-button-toggle-group
              [(ngModel)]="langCk"
              (change)="subchoose(info, langCk)"
            >
              <mat-card *ngFor="let item of info.detail">
                <mat-card-header>
                  <mat-card-title>{{ item.title }}</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <!-- <p>{{ item.price | number }}원</p> -->
                  <p>{{ item.con }}</p>
                </mat-card-content>

                <mat-card-actions>
                  <mat-button-toggle [value]="item" [checked]="item.check"
                    >선택 하기</mat-button-toggle
                  >
                </mat-card-actions>
              </mat-card>
            </mat-button-toggle-group>
          </div>
        </div>
        <div
          class="radio-card-wrap"
          *ngIf="info.inputType == 'radio' && info.mainTitle == '타임존'"
        >
          <h3>
            <span class="m-title"> {{ info.mainTitle }}</span>
            <span class="gray-text">{{ info.sub }}</span>
          </h3>
          <div class="card-box">
            <mat-button-toggle-group
              [(ngModel)]="timeCk"
              (change)="subchoose(info, timeCk)"
            >
              <mat-card *ngFor="let item of info.detail">
                <mat-card-header>
                  <mat-card-title>{{ item.title }}</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <!-- <p>{{ item.price | number }}원</p> -->
                  <p>{{ item.con }}</p>
                </mat-card-content>

                <mat-card-actions>
                  <mat-button-toggle [value]="item" [checked]="item.check"
                    >선택 하기</mat-button-toggle
                  >
                </mat-card-actions>
              </mat-card>
            </mat-button-toggle-group>
          </div>
        </div>
        <div
          class="radio-card-wrap"
          *ngIf="
            info.inputType == 'radio' && info.mainTitle == '기타 외부 API 활용'
          "
        >
          <h3>
            <span class="m-title"> {{ info.mainTitle }}</span>
            <span class="gray-text">{{ info.sub }}</span>
          </h3>
          <div class="card-box">
            <mat-button-toggle-group
              [(ngModel)]="apiCk"
              (change)="subchoose(info, apiCk)"
            >
              <mat-card *ngFor="let item of info.detail">
                <mat-card-header>
                  <mat-card-title>{{ item.title }}</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <!-- <p>{{ item.price | number }}원</p> -->
                  <p>{{ item.con }}</p>
                </mat-card-content>

                <mat-card-actions>
                  <mat-button-toggle [value]="item" [checked]="item.check"
                    >선택 하기</mat-button-toggle
                  >
                </mat-card-actions>
              </mat-card>
            </mat-button-toggle-group>
          </div>
        </div>
        <div
          class="radio-card-wrap"
          *ngIf="info.inputType == 'radio' && info.mainTitle == 'PDF 생성'"
        >
          <h3>
            <span class="m-title"> {{ info.mainTitle }}</span>
            <span class="gray-text">{{ info.sub }}</span>
          </h3>
          <div class="card-box">
            <mat-button-toggle-group
              [(ngModel)]="pdfCk"
              (change)="subchoose(info, pdfCk)"
            >
              <mat-card *ngFor="let item of info.detail">
                <mat-card-header>
                  <mat-card-title>{{ item.title }}</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <!-- <p>{{ item.price | number }}원</p> -->
                  <p>{{ item.con }}</p>
                </mat-card-content>

                <mat-card-actions>
                  <mat-button-toggle [value]="item" [checked]="item.check"
                    >선택 하기</mat-button-toggle
                  >
                </mat-card-actions>
              </mat-card>
            </mat-button-toggle-group>
          </div>
        </div>
        <div
          class="radio-card-wrap"
          *ngIf="info.inputType == 'radio' && info.mainTitle == '기타 기능'"
        >
          <h3>
            <span class="m-title"> {{ info.mainTitle }}</span>
            <span class="gray-text">{{ info.sub }}</span>
          </h3>
          <div class="card-box">
            <mat-button-toggle-group
              [(ngModel)]="etcCk"
              (change)="subchoose(info, etcCk)"
            >
              <mat-card *ngFor="let item of info.detail">
                <mat-card-header>
                  <mat-card-title>{{ item.title }}</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <!-- <p>{{ item.price | number }}원</p> -->
                  <p>{{ item.con }}</p>
                </mat-card-content>

                <mat-card-actions>
                  <mat-button-toggle [value]="item" [checked]="item.check"
                    >선택 하기</mat-button-toggle
                  >
                </mat-card-actions>
              </mat-card>
            </mat-button-toggle-group>
          </div>
        </div>
      </section>
    </section>

    <!-----------***사이드 메뉴 ---------------->

    <aside class="side-area">
      <section class="estimate-box">
        <h2>
          예상 견적
          <button
            class="toggle-btn"
            [ngClass]="{ 'close-menu': toggleSwitch }"
            (click)="toggleManu()"
          ></button>
        </h2>
        <div class="push-area" [ngClass]="{ 'area-active': toggleSwitch }">
          <div>
            <p>
              [기본 정보]
              <span class="blue-text" *ngIf="!activeChoose()"
                >기본 정보 선택은 필수입니다.</span
              >
            </p>

            <ul class="essential_info">
              <li *ngIf="pickOs.length == 0">
                <span class="placeholder"> 개발 OS를 선택해주세요.</span
                ><span class="add-btn">
                  <!-- 0원  -->
                  <button (click)="goOsBox()">추가 하기</button></span
                >
              </li>
              <li *ngFor="let item of pickOs">
                <span
                  class="placeholder"
                  [ngClass]="{ 'on-text': pickOs.length > 0 }"
                  >{{ item ? item.title : "개발 OS를 선택해주세요." }}</span
                >
                <span class="add-btn">
                  <!-- {{ item.price | number }}원 -->
                  <button
                    [ngClass]="{ 'btn-active': pickOs.length > 0 }"
                    (click)="chooseOs(item)"
                  >
                    추가 하기
                  </button></span
                >
              </li>
              <li>
                <span
                  class="placeholder"
                  [ngClass]="{
                    'on-text': pageNum ? pageNum.check : false
                  }"
                >
                  {{
                    pageNum?.check == true
                      ? pageNum.title
                      : "페이지 수를 선택해주세요."
                  }} </span
                ><span class="add-btn">
                  <!-- {{ pagePrice ? (pagePrice | number) : 0 }}원 -->
                  <button
                    (click)="pageChoice('button')"
                    [ngClass]="{ 'btn-active': pageNum?.check }"
                  >
                    추가 하기
                  </button></span
                >
              </li>
              <li>
                <span
                  class="placeholder"
                  [ngClass]="{
                    'on-text': planPick ? planPick.check : false
                  }"
                  >{{
                    planPick?.check == true
                      ? planPick.title
                      : "기획을 선택해주세요."
                  }} </span
                ><span class="add-btn">
                  <!-- {{ planPrice ? (planPrice | number) : 0 }}원 -->
                  <button
                    (click)="planChoice('button')"
                    [ngClass]="{ 'btn-active': planPick?.check }"
                  >
                    추가 하기
                  </button></span
                >
              </li>
              <li>
                <span
                  class="placeholder"
                  [ngClass]="{
                    'on-text': designPick ? designPick.check : false
                  }"
                  >{{
                    designPick?.check == true
                      ? designPick.title
                      : "디자인을 선택해주세요"
                  }} </span
                ><span class="add-btn">
                  <!-- {{ designPrice ? (designPrice | number) : 0 }}원 -->
                  <button
                    (click)="designChoice('button')"
                    [ngClass]="{ 'btn-active': designPick?.check }"
                  >
                    추가 하기
                  </button></span
                >
              </li>
            </ul>
          </div>

          <div *ngIf="showSub()">
            <p>[기능 정보]</p>

            <ul class="choose_info" class="cklast">
              <div *ngFor="let info of additional">
                <div *ngFor="let item of info.detail">
                  <li *ngIf="item.check == true">
                    <span class="placeholder on-text">{{ item.title }}</span>
                    <span class="add-btn">
                      <!-- {{ item.price | number }}원 -->
                      <button class="btn-active" (click)="removeItem(item)">
                        추가 하기
                      </button></span
                    >
                  </li>
                </div>
              </div>
            </ul>
          </div>
        </div>
        <div class="price-box">
          <!-- <p>{{ sumTotalPrice() | number }}원</p> -->
          <!-- <p>예상기간 : 3개월</p> -->
        </div>

        <button
          class="save"
          [disabled]="!activeChoose()"
          (click)="updateInfo('inquiry')"
        >
          견적과 함께 문의하기
        </button>
      </section>

      <button
        class="download"
        [disabled]="!activeChoose()"
        (click)="updateInfo('download')"
      >
        견적서 PDF 다운로드
      </button>
    </aside>
  </div>
  <!-- <span class="footer-line"></span> -->
</main>

<!-- <footer-share> </footer-share> -->

<div class="none-area" style="position: absolute; left: -9999px; top: -9999px">
  <div class="view-pdf" id="pdfBody">
    <div class="view-wrap">
      <p class="pdf-date">
        발행 일자&nbsp;&nbsp;&nbsp;{{ saveDate | date: "yy.MM.dd" }}
      </p>
      <h1>견적서</h1>

      <table class="top-table">
        <tr>
          <th rowspan="2" width="25%">
            이름 <span class="info">{{ userInfo?.username }}</span>
          </th>
          <th width="30%">사업자 번호</th>
          <td width="45%">609-86-25710</td>
        </tr>
        <tr>
          <th width="30%">상호</th>
          <td width="45%">케이엔큐레이션 주식회사</td>
        </tr>
        <tr>
          <th rowspan="2" class="border-none" width="25%">
            회사 명
            <span class="info">{{ userInfo?.company }}</span>
          </th>
          <th width="30%">대표</th>
          <td width="45%">강민구, 고현</td>
        </tr>
        <tr>
          <th width="30%">주소</th>
          <td width="45%">서울특별시 성동구 아차산로 17길 48 SKV1센터 1동 1706호,1707호</td>
        </tr>
        <tr class="row-css">
          <th rowspan="2" width="25%">
            프로젝트 명
            <span class="info">{{ userInfo?.project }}</span>
          </th>
          <th width="30%">전화 번호</th>
          <td width="45%">02-1664-6786</td>
        </tr>
      </table>
    </div>
  </div>

  <table class="bottom-table" id="my-table">
    <thead>
      <tr>
        <th>항목명</th>
        <th>설명</th>
        <!-- <th>개발 비용</th> -->
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of pickOs">
        <td>{{ item.title }}</td>
        <td>{{ item.con }}</td>
        <!-- <td>{{ item.price }}</td> -->
      </tr>
      <tr *ngIf="pageNum">
        <td>{{ pageNum.title }}</td>
        <td>{{ pageNum.con }}</td>
        <!-- <td>{{ pageNum.price }}</td> -->
      </tr>
      <tr *ngIf="planPick">
        <td>{{ planPick.title }}</td>
        <td>{{ planPick.con }}</td>
        <!-- <td>{{ planPick.price }}</td> -->
      </tr>
      <tr *ngIf="designPick">
        <td>{{ designPick.title }}</td>
        <td>{{ designPick.con }}</td>
        <!-- <td>{{ designPick.price }}</td> -->
      </tr>
      <!-- <tr *ngIf="">
            <td>{{ designPick.title }}</td>
            <td>{{ designPick.con }}</td>
            <td>{{ designPick.price }}</td>
          </tr> -->
    </tbody>

    <tbody *ngFor="let info of additional">
      <tr *ngFor="let item of info.detail">
        <td *ngIf="item.check == true">{{ item.title }}</td>
        <td *ngIf="item.check == true">{{ item.con }}</td>
        <!-- <td *ngIf="item.check == true">{{ item.price }}</td> -->
      </tr>
    </tbody>

    <tfoot>
      <!-- <tr>
            <th>합계</th>
            <td colspan="2">₩ {{ sumTotalPrice() | number }}원</td>
          </tr> -->
    </tfoot>
  </table>

  <!-- <h1>견적서</h1>

      <table> -->
  <!--header-->
  <!-- <thead>
          <tr class="h-line">
            <th class="t-h1">주제</th>
            <th class="t-h2">항목</th>
            <th class="t-h3">설명 멘트</th>
            <th class="t-h4">예상 견적</th>
            <th class="t-h5">체크</th>
          </tr>
        </thead>

        <tbody>
          <tr
            *ngFor="let item of os.osDetail; let i = index"
            [ngStyle]="{
              borderBottom:
                os.osDetail[i] == os.osDetail[2] ? '2px solid #000' : none
            }"
          >
            <td
              [attr.rowspan]="os.osDetail.length"
              *ngIf="os.osDetail[i] == os.osDetail[0]"
            >
              {{ os.mainTitle }}
            </td>

            <td>{{ item.title }}</td>
            <td>{{ item.con }}</td>
            <td>{{ item.price | number }} 원</td>
            <td>{{ item.check ? "V" : "&nbsp;" }}</td>
          </tr>
          <tr
            *ngFor="let item of page.pageDetail; let i = index"
            [ngStyle]="{
              borderBottom:
                page.pageDetail[i] == page.pageDetail[4]
                  ? '2px solid #000'
                  : none
            }"
          >
            <td
              [attr.rowspan]="page.pageDetail.length"
              *ngIf="page.pageDetail[i] == page.pageDetail[0]"
            >
              {{ page.mainTitle }}
              <span class="td-sub">{{ page.sub }}</span>
            </td>
            <td>{{ item.title }}</td>
            <td>{{ item.con }}</td>
            <td>{{ item.price | number }} 원</td>
            <td>{{ item.check ? "V" : "&nbsp;" }}</td>
          </tr>
          <tr
            *ngFor="let item of plan.planDetail; let i = index"
            [ngStyle]="{
              borderBottom:
                plan.planDetail[i] == plan.planDetail[3]
                  ? '2px solid #000'
                  : none
            }"
          >
            <td
              [attr.rowspan]="plan.planDetail.length"
              *ngIf="plan.planDetail[i] == plan.planDetail[0]"
            >
              {{ plan.mainTitle }}
              <span class="td-sub">{{ plan.sub }}</span>
            </td>
            <td>{{ item.title }}</td>
            <td>{{ item.con }}</td>
            <td>{{ item.price | number }} 원</td>
            <td>{{ item.check ? "V" : "&nbsp;" }}</td>
          </tr>
          <tr
            *ngFor="let item of design.designDetail; let i = index"
            [ngStyle]="{
              borderBottom:
                design.designDetail[i] == design.designDetail[2]
                  ? '2px solid #000'
                  : none
            }"
          >
            <td
              [attr.rowspan]="design.designDetail.length"
              *ngIf="design.designDetail[i] == design.designDetail[0]"
            >
              {{ design.mainTitle }}
              <span class="td-sub">{{ design.sub }}</span>
            </td>
            <td>{{ item.title }}</td>
            <td>{{ item.con }}</td>
            <td>{{ item.price | number }} 원</td>
            <td>{{ item.check ? "V" : "&nbsp;" }}</td>
          </tr>
        </tbody>

        <tbody *ngFor="let info of additional" class="t-body">
          <tr *ngFor="let item of info.detail; let i = index">
            <td
              [attr.rowspan]="info.detail.length"
              *ngIf="info.detail[i] == info.detail[0]"
            >
              {{ info.mainTitle }}
              <span class="td-sub">{{ info.sub }}</span>
            </td>
            <td>{{ item.title }}</td>
            <td>{{ item.con }}</td>
            <td>{{ item.price | number }} 원</td>
            <td>{{ item.check ? "V" : "&nbsp;" }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            추후수정

            <td colspan="2">총 예상 개발 기간 : 3개월</td>
            <td colspan="3">
              총 예상 견적가 : {{ sumTotalPrice() | number }}원
            </td>
          </tr>
        </tfoot>
      </table> -->
</div>

<div class="load-wrap" [ngClass]="{ active: loading }">
  <p class="loading-icon">
    <img src="assets/imgs/loading.gif" alt="" />
  </p>

  <p class="logo-icon">
    <img src="assets/imgs/logo.png" alt="" />
  </p>
</div>
