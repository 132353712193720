import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { DbService } from 'src/service/db.service';

@Component({
  selector: 'app-terms-modal',
  templateUrl: './terms-modal.component.html',
  styleUrls: ['./terms-modal.component.scss'],
})
export class TermsModalComponent implements OnInit {
  type: string;
  terms: any;
  content: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private db: DbService,
    public dialogRef: MatDialogRef<TermsModalComponent>
  ) {
    this.type = data.type;
  }

  async ngOnInit(): Promise<void> {
    this.terms = await this.db
      .doc$('storeInfo/terms')
      .pipe(take(1))
      .toPromise();
    this.content =
      this.type == 'service' ? this.terms.service : this.terms.privacy;
  }

  dbUPdate() {
    this.db.updateAt('storeInfo/terms', {
      privacy: `스타트앱’은 (이하 ‘회사'는) 고객님의 개인정보를 중요시하며, "정보통신망 이용촉진 및 정보보호"에 관한 법률을 준수하고 있습니다.
      회사는 개인정보취급방침을 통하여 고객님께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며,개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.
      회사는 개인정보취급방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.

      ο 본 방침은 : 2022 년 12 월 07 일 부터 시행됩니다.

      ■ 수집하는 개인정보 항목 회사는 상담, 서비스 신청 등을 위해 아래와 같은 개인정보를 수집하고 있습니다.
      ο 수집항목 : 이름 , 연락처 , 이메일 , 회사명
      ο 개인정보 수집방법 : 홈페이지(문의 및 구매)

      ■ 개인정보의 수집 및 이용목적 회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.
      ο 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산 - 콘텐츠 제공

      ■ 개인정보의 보유 및 이용기간 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다.
      단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 아래와 같이 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.
      보존 항목 : 이름 , 연락처 , 이메일 , 회사명
      보존 근거 : 회원식별
      보존 기간 : 개인정보 수집일로부터 1년까지(계약 시 A/S 종료까지)

      ■ 개인정보의 파기절차 및 방법 회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.
      ο 파기절차 회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기되어집니다.
      별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되어지는 이외의 다른 목적으로 이용되지 않습니다.
      ο 파기방법
      - 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
      - DB삭제 방법으로 파기합니다.

      ■ 개인정보 제공 회사는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
      - 이용자들의 소유권분쟁 및 사전에 동의한 경우
      - 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우

      ■ 수집한 개인정보의 위탁 회사는 고객님의 동의없이 고객님의 정보를 외부 업체에 위탁하지 않습니다. 향후 그러한 필요가 생길 경우, 위탁 대상자와 위탁 업무 내용에 대해 고객님에게 통지하고 필요한 경우 사전 동의를 받도록 하겠습니다.

      ■ 이용자 및 법정대리인의 권리와 그 행사방법 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체없이 조치하겠습니다.
      귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다.
      또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체없이 통지하여 정정이 이루어지도록 하겠습니다.
      회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 “회사가 수집하는 개인정보의 보유 및 이용기간”에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.

      ■ 개인정보의 수집,이용,제공에 대한 동의철회
      ο 개인정보의 수집, 이용, 제공과 관련해 귀하는 동의하신 내용을 언제든지 철회 하실 수 있습니다. 개인정보보호책임자에게 서면, 전화, 이메일 등으로 연락하시면 회사는 즉시 필요한 조치를 취합니다.
      동의를 철회하고 개인정보를 파기하는 등의 조치가 있으면 그 사실을 귀하께 지체없이 통지하도록 하겠습니다.
      ο 회사는 개인정보의 수집에 대한 동의철회를 개인정보를 수집하는 방법보다 쉽게 할 수 있도록 필요한 조치를 취합니다.

      ■ 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항 쿠키 등 인터넷 서비스 이용 시 자동 생성되는 개인정보를 수집하는 장치를 운영하지 않습니다.

      ■ 개인정보에 관한 민원서비스 회사는 고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보관리책임자를 지정하고 있습니다.

      고객서비스담당 부서 : 상담팀
      전화번호 : 1644-5786
      이메일 : consult@kncuration.com
      개인정보관리책임자 성명 : 고현
      대표 전화번호 : 1644-5786
      이메일 : info@kncuration.com

      귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다.
      회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다. 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.

      1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)
      2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)
      3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)
      4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)`,
    });
  }

  cangeType(type) {
    this.type = type;
    this.content =
      this.type == 'service' ? this.terms.service : this.terms.privacy;
  }
  close() {
    this.dialogRef.close();
  }
}
