import { Component, OnDestroy, OnInit } from '@angular/core';
import Swiper from 'swiper';
import * as $ from 'jquery';
import _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';

import SwiperCore, { Navigation, Pagination } from 'swiper/core';
import { DbService } from 'src/service/db.service';
import { first } from 'rxjs/operators';

SwiperCore.use([Navigation, Pagination]);

@Component({
  selector: 'app-portfolio-detail',
  templateUrl: './portfolio-detail.component.html',
  styleUrls: ['./portfolio-detail.component.scss'],
})
export class PortfolioDetailComponent implements OnInit, OnDestroy {
  mockup = [
    { img: 'assets/sample/therapeople_service_img_01.png' },
    { img: 'assets/sample/therapeople_service_img_02.png' },
    { img: 'assets/sample/therapeople_service_img_03.png' },
    { img: 'assets/sample/therapeople_service_img_04.png' },
    { img: 'assets/sample/therapeople_service_img_05.png' },
  ];

  pfId;
  pickPortfolio;
  loadingSwitch = false;
  pfDetailID: string;
  portfolioList: Array<any>;
  mainArray;
  pfListArray;
  constructor(
    private activatedRouter: ActivatedRoute,
    private db: DbService,
    private router: Router
  ) {
    this.pfDetailID = this.activatedRouter.snapshot.params.params;

    this.getData()
      .then((data) => {
        this.mainArray = data
          .filter((data) => data && data.mainPick == true)
          .sort(function (a, b) {
            return a.mainIndex - b.mainIndex;
          });

        this.pfListArray = data
          .filter((data) => data && data.mainPick != true)
          .sort(function (a, b) {
            return b.startDate - a.startDate;
          });

        this.portfolioList = this.mainArray.concat(this.pfListArray);
      })
      .then(() => {
        let filterResult: any = this.portfolioList.filter(
          (data) => data.id == this.pfDetailID
        );

        if (filterResult?.length > 0) {
          this.viewInit(this.pfDetailID);
        } else {
          // window.location.href = '/#/notfound';

          this.router.navigateByUrl(`/notfound`).then(() => {
            window.location.reload();
          });
        }
      });
  }

  getData(): Promise<any> {
    return this.db.collection$(`portfolio`).pipe(first()).toPromise();
  }

  ngOnInit(): void {
    // this.footerOpen();
    // this.stickyBtnActive();
    // this.stickyBtnRelative();

    // 새로고침 시 스크롤 초기화
    window.onload = function () {
      setTimeout(function () {
        scrollTo(0, 0);
      }, 10);
    };

    $(function () {
      // 창크기 조절 시 자동 (새로고침)resize 발생
      // $(window).on(
      //   'resize',
      //   _.debounce(function () {
      //     location.reload();
      //     this.viewInit();
      //   }, 500)
      // );
      // scrollTop btn
      // $('footer-share')
      //   .find('.top_btn')
      //   .on('click', function (e) {
      //     $('html,body').animate({ scrollTop: 0 }, 500);
      //     return false;
      //   });
    });
  }

  ngOnDestroy(): void {
    $(window).scroll().off();
  }

  itemIndex;

  viewInit(id) {
    this.portfolioList.forEach((item: any, index) => {
      if (item.id == id) {
        this.pickPortfolio = item;
        this.itemIndex = index;

        setTimeout(() => {
          if (this.pickPortfolio) {
            const swiper = new Swiper('.swiper-container', {
              // loop: true,
              slidesPerView: 'auto',
              spaceBetween: 20,
              freeMode: true,
              navigation: {
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next',
              },
            });

            // let left = document.querySelector<HTMLElement>('.left');
            // let right = document.querySelector<HTMLElement>('.right');
            // left.style.backgroundImage = `url(../../../${item.bottomLeft})`;
            // right.style.backgroundImage = `url(../../../${item.bottomRight})`;
          }
        }, 400);
      }
    });
  }

  ngAfterContentInit(): void {
    //Called after ngOnInit when the component's or directive's content has been initialized.
    //Add 'implements AfterContentInit' to the class.
  }

  stickyBtnActive() {
    const stickyBtn = document.querySelector<HTMLElement>('sticky-button p');
    window.addEventListener('scroll', function () {
      let scrollY = window.scrollY;
      if (scrollY > 10) {
        stickyBtn.classList?.add('text-ac');
      }
    });
  }

  stickyBtnRelative() {
    $(window).scroll(function () {
      let winWidth = $(window).width();
      const stickyBtn = $('sticky-button');
      let btmLineTop = $('.project-wrap').offset()?.top;
      let btmLineHeight = $('.project-wrap').outerHeight();
      let trigger = btmLineTop + btmLineHeight;
      let docHeight = window.scrollY + window.innerHeight;
      if (winWidth >= 700) {
        if (trigger <= docHeight) {
          stickyBtn.css({
            position: 'absolute',
            zIndex: '100',
            bottom: '170px',
          });
        } else {
          stickyBtn.css({
            position: 'fixed',
            bottom: '40px',
          });
        }
      }
      if (winWidth <= 699) {
        if (trigger <= docHeight) {
          stickyBtn.css({
            position: 'absolute',
            zIndex: '100',
            bottom: '300px',
          });
        } else {
          stickyBtn.css({
            position: 'fixed',
            bottom: '40px',
          });
        }
      }
    });
  }

  // footerOpen() {
  //   const footer = document.querySelector<HTMLElement>('footer-share footer');
  //   const portfolio = document.querySelector<HTMLElement>('#pfdetail-footer');
  //   const footerInner = document.querySelector<HTMLElement>('.footer-inner');

  //   footer.classList.add('white');

  //   footer.style.position = 'static';
  //   let portfolioFooterHeight = portfolio.clientHeight;
  //   portfolio.style.marginTop = `${-portfolioFooterHeight}px`;

  //   window.addEventListener('scroll', () => {
  //     let $btmLine = $('.bottom-line');
  //     let $btmLineTop = $btmLine.offset().top;
  //     let $btmLineHeight = $btmLine.outerHeight();
  //     let point = $btmLineTop + $btmLineHeight;
  //     let trigger = window.scrollY + window.innerHeight;

  //     if (point <= trigger + 10) {
  //       portfolio.classList.add('active');
  //       portfolio.style.marginTop = '0';
  //       setTimeout(() => {
  //         footerInner.style.zIndex = '100';
  //       }, 900);
  //     }
  //   });
  // }

  prevBtn() {
    if (this.itemIndex != 0) {
      this.router
        .navigateByUrl(
          `/portfolio-detail/${this.portfolioList[this.itemIndex - 1].id}`
        )
        .then(() => {
          window.location.reload();
        });
    } else {
      this.router.navigateByUrl('/portfolio-hanra').then(() => {
        window.location.reload();
      });
    }
  }

  nextBtn() {
    if (this.itemIndex != this.portfolioList.length - 1) {
      this.router
        .navigateByUrl(
          `/portfolio-detail/${this.portfolioList[this.itemIndex + 1].id}`
        )
        .then(() => {
          window.location.reload();
        });
    } else {
      this.router.navigateByUrl('/portfolio-hanra').then(() => {
        window.location.reload();
      });
    }
  }
}
