import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { filter, first, take } from 'rxjs/operators';
import { DbService } from 'src/service/db.service';
import Swiper, { Autoplay } from 'swiper';
import SwiperCore, { Navigation, Pagination, Mousewheel } from 'swiper/core';

// configure Swiper to use modules
SwiperCore.use([Navigation, Pagination, Mousewheel, Autoplay]);

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  portfolio = [];
  appIcon = [{ img: 'assets/imgs/portfolio-imgs/pf-icon-1.png', time: -3 }];

  lodingSwitch = false;
  myDevice = navigator.platform;
  realMain = false;

  constructor(private db: DbService, private router: Router) {
    this.getData()
      .then((data) => {
        this.portfolio = data
          .filter((data) => data && data.mainPick == true)
          .sort(function (a, b) {
            return a.mainIndex - b.mainIndex;
          })
          .slice(0, 6);

        // this.appIcon = [];
        // this.appIcon.push({ img: this.portfolio[0].icon, time: -3 });
      })
      .then(() => {});
  }

  getData(): Promise<any> {
    return this.db.collection$(`portfolio`).pipe(first()).toPromise();
  }

  ngOnInit(): void {
    ///스와이퍼부터 작동
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

    $(function () {
      $('.back-img1').addClass('active');
      $('.back-img2').addClass('active');
      $('.back-img3').addClass('active');
      $('.back-img4').addClass('active');
      $('.back-img5').addClass('active');
      $('.back-img6').addClass('active');

      setTimeout(() => {
        $('.back-img1').removeClass('active');
        $('.back-img2').removeClass('active');
        $('.back-img3').removeClass('active');
        $('.back-img4').removeClass('active');
        $('.back-img5').removeClass('active');
        $('.back-img6').removeClass('active');
      }, 400);
    });

    setTimeout(() => {
      //로딩되자마자 메인 베너
      $('.real-main').animate({ opacity: 1 }, 1000);

      $('.back-img1').addClass('active');
      $('.back-img2').addClass('active');
      $('.back-img3').addClass('active');
      $('.back-img4').addClass('active');
      $('.back-img5').addClass('active');
      $('.back-img6').addClass('active');

      this.showAni();
    }, 1000);
  }

  ngOnDestroy(): void {
    $(window).scroll().off();
  }

  showAni() {
    const swiper = new Swiper('.swiper-container', {
      direction: 'horizontal',
      loop: false,
      slidesPerView: 'auto',
      mousewheel: false,
      touchStartPreventDefault: false,
      pagination: false,
      centeredSlides: false,
      navigation: false,
    });

    let windowWidth = $(window).width();
    $(function () {
      setTimeout(() => {
        /////제이쿼리

        if (windowWidth < 767) {
          const swiperM = new Swiper('.m-swiper-container', {
            direction: 'horizontal',
            mousewheel: false,
            pagination: {
              el: '.swiper-pagination',
              type: 'bullets',
            },
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
            centeredSlides: true,
            slidesPerView: 'auto',
            loop: true,
            slideActiveClass: 'slide_ac',
          });

          let that = this;
          let filter = 'win16|win32|win64|mac|macintel';

          if (that.myDevice == filter) {
            $(window).resize(function (e) {
              let windowWidth = $(window).width();
              //width 체크
              $('.width').text(windowWidth);

              if (windowWidth < 767) {
                //리사이즈 될때마다 사이즈 체크
                let timerId = setInterval(() => {
                  swiperM.update();
                  swiperM.updateSize();
                }, 150);
                setTimeout(() => {
                  clearInterval(timerId);
                }, 5000);
              } else {
                ////767보다 클때
                $('sticky-button')
                  .stop()
                  .animate({ bottom: 40 }, 800)
                  .queue(function () {
                    $('sticky-button > .sticky_wrap > p').addClass('text-ac');
                  });
                $('.img-box.img1 img').attr('src', 'assets/imgs/img_ux.gif');
                $('.txt-area .txt1')
                  .find('h3')
                  .animate({ opacity: 1, top: 0 }, 1000);

                for (var i = 0; i < txtBox2.length; i++) {
                  for (let index = 0; index < temp2.length; index++) {
                    const element = temp2[index];

                    $('.txt-area .txt-box')
                      .eq(0)
                      .find('div p')
                      .addClass('txt-ani-ac')
                      .removeClass('txt-ani')
                      .animate({ opacity: 1 })
                      .find($(element))
                      .delay(50 * index)
                      .animate(
                        {
                          top: 0,
                          opacity: 1,
                          left: 0,
                        },
                        600
                      )
                      .queue(function () {
                        $('.txt1').find('a').animate({ opacity: 1 }, 400);
                      });
                  }

                  attrSwitch1 = true;
                }

                $('.img-box.img1 img').attr('src', 'assets/imgs/img_ux.gif');
                $('.txt-area .txt1')
                  .find('h3')
                  .animate({ opacity: 1, top: 0 }, 1000);
                for (var i = 0; i < txtBox2.length; i++) {
                  for (let index = 0; index < temp2.length; index++) {
                    const element = temp2[index];
                    $('.txt-area .txt-box')
                      .eq(0)
                      .find('div p')
                      .addClass('txt-ani-ac')
                      .removeClass('txt-ani')
                      .animate({ opacity: 1 })
                      .find($(element))
                      .delay(50 * index)
                      .animate(
                        {
                          top: 0,
                          opacity: 1,
                          left: 0,
                        },
                        600
                      )
                      .queue(function () {
                        $('.txt1').find('a').animate({ opacity: 1 }, 400);
                      });
                  }
                }

                $('.img-box.img2 img').attr('src', 'assets/imgs/img_ui.gif');
                $('.txt-area .txt2')
                  .find('h3')
                  .animate({ opacity: 1, top: 0 }, 1000);
                for (var i = 0; i < txtBox2.length; i++) {
                  for (let index = 0; index < temp2.length; index++) {
                    const element = temp2[index];
                    $('.txt-area .txt-box')
                      .eq(1)
                      .find('div p')
                      .addClass('txt-ani-ac')
                      .removeClass('txt-ani')
                      .animate({ opacity: 1 })
                      .find($(element))
                      .delay(50 * index)
                      .animate(
                        {
                          top: 0,
                          opacity: 1,
                          left: 0,
                        },
                        600
                      )
                      .queue(function () {
                        $('.txt2').find('a').animate({ opacity: 1 }, 400);
                      });
                  }
                  attrSwitch2 = true;
                  attrSwitch1 = true;
                }
                $('.img-box.img1 img').attr('src', 'assets/imgs/img_ux.gif');
                $('.txt-area .txt1')
                  .find('h3')
                  .animate({ opacity: 1, top: 0 }, 1000);

                for (var i = 0; i < txtBox2.length; i++) {
                  for (let index = 0; index < temp2.length; index++) {
                    const element = temp2[index];
                    $('.txt-area .txt-box')
                      .eq(0)
                      .find('div p')
                      .addClass('txt-ani-ac')
                      .removeClass('txt-ani')
                      .animate({ opacity: 1 })
                      .find($(element))
                      .delay(50 * index)
                      .animate(
                        {
                          top: 0,
                          opacity: 1,
                          left: 0,
                        },
                        600
                      )
                      .queue(function () {
                        $('.txt1').find('a').animate({ opacity: 1 }, 400);
                      });
                  }
                }

                $('.img-box.img2 img').attr('src', 'assets/imgs/img_ui.gif');
                $('.txt-area .txt2')
                  .find('h3')
                  .animate({ opacity: 1, top: 0 }, 1000);
                for (var i = 0; i < txtBox2.length; i++) {
                  for (let index = 0; index < temp2.length; index++) {
                    const element = temp2[index];
                    $('.txt-area .txt-box')
                      .eq(1)
                      .find('div p')
                      .addClass('txt-ani-ac')
                      .removeClass('txt-ani')
                      .animate({ opacity: 1 })
                      .find($(element))
                      .delay(50 * index)
                      .animate(
                        {
                          top: 0,
                          opacity: 1,
                          left: 0,
                        },
                        600
                      )
                      .queue(function () {
                        $('.txt2').find('a').animate({ opacity: 1 }, 400);
                      });
                  }
                }
                $('.img-box.img3 img').attr(
                  'src',
                  'assets/imgs/img_develop.gif'
                );
                $('.txt-area .txt3')
                  .find('h3')
                  .animate({ opacity: 1, top: 0 }, 1000);
                for (var i = 0; i < txtBox2.length; i++) {
                  for (let index = 0; index < temp2.length; index++) {
                    const element = temp2[index];
                    $('.txt-area .txt-box')
                      .eq(2)
                      .find('div p')
                      .addClass('txt-ani-ac')
                      .removeClass('txt-ani')
                      .animate({ opacity: 1 })
                      .find($(element))
                      .delay(50 * index)
                      .animate(
                        {
                          top: 0,
                          opacity: 1,
                          left: 0,
                        },
                        600
                      )
                      .queue(function () {
                        $('.txt3').find('a').animate({ opacity: 1 }, 400);
                      });
                  }
                  attrSwitch3 = true;
                  attrSwitch2 = true;
                  attrSwitch1 = true;
                }
                $('.img-box.img1 img').attr('src', 'assets/imgs/img_ux.gif');
                $('.txt-area .txt1')
                  .find('h3')
                  .animate({ opacity: 1, top: 0 }, 1000);

                for (var i = 0; i < txtBox2.length; i++) {
                  for (let index = 0; index < temp2.length; index++) {
                    const element = temp2[index];
                    $('.txt-area .txt-box')
                      .eq(0)
                      .find('div p')
                      .addClass('txt-ani-ac')
                      .removeClass('txt-ani')
                      .animate({ opacity: 1 })
                      .find($(element))
                      .delay(50 * index)
                      .animate(
                        {
                          top: 0,
                          opacity: 1,
                          left: 0,
                        },
                        600
                      )
                      .queue(function () {
                        $('.txt1').find('a').animate({ opacity: 1 }, 400);
                      });
                  }
                }

                $('.img-box.img2 img').attr('src', 'assets/imgs/img_ui.gif');
                $('.txt-area .txt2')
                  .find('h3')
                  .animate({ opacity: 1, top: 0 }, 1000);
                for (var i = 0; i < txtBox2.length; i++) {
                  for (let index = 0; index < temp2.length; index++) {
                    const element = temp2[index];
                    $('.txt-area .txt-box')
                      .eq(1)
                      .find('div p')
                      .addClass('txt-ani-ac')
                      .removeClass('txt-ani')
                      .animate({ opacity: 1 })
                      .find($(element))
                      .delay(50 * index)
                      .animate(
                        {
                          top: 0,
                          opacity: 1,
                          left: 0,
                        },
                        600
                      )
                      .queue(function () {
                        $('.txt2').find('a').animate({ opacity: 1 }, 400);
                      });
                  }
                }
                $('.img-box.img3 img').attr(
                  'src',
                  'assets/imgs/img_develop.gif'
                );
                $('.txt-area .txt3')
                  .find('h3')
                  .animate({ opacity: 1, top: 0 }, 1000);
                for (var i = 0; i < txtBox2.length; i++) {
                  for (let index = 0; index < temp2.length; index++) {
                    const element = temp2[index];
                    $('.txt-area .txt-box')
                      .eq(2)
                      .find('div p')
                      .addClass('txt-ani-ac')
                      .removeClass('txt-ani')
                      .animate({ opacity: 1 })
                      .find($(element))
                      .delay(50 * index)
                      .animate(
                        {
                          top: 0,
                          opacity: 1,
                          left: 0,
                        },
                        600
                      )
                      .queue(function () {
                        $('.txt3').find('a').animate({ opacity: 1 }, 400);
                      });
                  }
                }
                $('.img-box.img4 img').attr(
                  'src',
                  'assets/imgs/img_marketing.gif'
                );
                $('.txt-area .txt4')
                  .find('h3')
                  .animate({ opacity: 1, top: 0 }, 1000);
                for (var i = 0; i < txtBox2.length; i++) {
                  for (let index = 0; index < temp2.length; index++) {
                    const element = temp2[index];
                    $('.txt-area .txt-box')
                      .eq(3)
                      .find('div p')
                      .addClass('txt-ani-ac')
                      .removeClass('txt-ani')
                      .animate({ opacity: 1 })
                      .find($(element))
                      .delay(50 * index)
                      .animate(
                        {
                          top: 0,
                          opacity: 1,
                          left: 0,
                        },
                        600
                      )
                      .queue(function () {
                        $('.txt4').find('a').animate({ opacity: 1 }, 400);
                      });
                  }
                  attrSwitch4 = true;
                  attrSwitch3 = true;
                  attrSwitch2 = true;
                  attrSwitch1 = true;
                  // $(this).off(e);
                }
              }
            });
          }
        }

        //텍스트 애니메이션 함수
        let txtBox = [];
        let temp = [];
        function textAni() {
          let txtLine = [];
          //p 태그에 있는 문장을 한 문장씩 저장
          $('#artiText p').each(function (index, item) {
            let txt = $(item).text();
            let tmp = { index: index, txt: txt };
            txtLine.push(tmp);
          });

          //문장별로 단어를 끊어서 저장
          let splitTxt = [];
          txtLine.forEach((txt) => {
            splitTxt.push(txt.txt.split(' '));
          });

          //단어별로 스펜태그로 감싸서 저장

          $(splitTxt).each(function (index, item) {
            let txt = [];
            item.forEach((e) => {
              txt.push('<span>' + e + '</span>&nbsp;');
            });
            txtBox.push({ index: index, txt: txt });
          });

          //문장별 끊어서 포문을 돌리고
          for (var i = 0; i < txtBox.length; i++) {
            //태그에 span태그로 감싼 단어 다시 넣기
            $('#artiText p').eq(i).html(txtBox[i].txt);
            //만들어진 span 태그 css
            $('#artiText p span').css({
              opacity: 0,
              position: 'relative',
              top: '10px',
              left: '5px',
              lineHeight: '30px',
              display: 'inline-block',
              '-webkit-font-smoothing': 'antialiased',
              '-moz-osx-font-smoothing': 'grayscale',
            });

            //문장별 단어 배열 다시받기
            $('#artiText p')
              .eq(i)
              .find('span')
              .each(function (index, item) {
                temp.push(item);
              });
          }
        }
        //reset
        textAni();

        setTimeout(() => {
          let ani1 = false;
          if (windowWidth > 767) {
            $('#main').on('mousewheel', function (e) {
              let wheel = e.originalEvent.wheelDelta;
              if (wheel > 0) {
                //스크롤 올릴때
              } else {
                //스크롤  내릴때
                if (ani1 == false) {
                  $('.front-title').animate({ top: '-400px' }, 1000, 'linear');
                  for (var i = 0; i < txtBox.length; i++) {
                    //단어별로 포문 돌리기
                    for (let index = 0; index < temp.length; index++) {
                      const element = temp[index];
                      //이걸 어떻게 단축시킬수 있을까..
                      //각 문단별로 애니메이션 실행
                      if (i == 0) {
                        //특정단어 css
                        if (String($(element).text()) == '시간과') {
                          $(element).css({ fontWeight: '800' });
                        } else if (String($(element).text()) == '돈의') {
                          $(element).css({ fontWeight: '800' });
                        } else if (String($(element).text()) == '가치를') {
                          $(element).css({ fontWeight: '800' });
                        }

                        $('#artiText p')
                          .eq(0)
                          .addClass('txt-ani-ac')
                          .removeClass('txt-ani')
                          .animate({ opacity: 1 })
                          .find($(element))
                          .delay(50 * index)
                          .animate(
                            {
                              top: 0,
                              opacity: 1,
                              left: 0,
                            },
                            500
                          );
                      } else if (i == 1) {
                        $('#artiText p')
                          .eq(1)
                          .addClass('txt-ani-ac')
                          .removeClass('txt-ani')
                          .animate({ opacity: 1 })
                          .find($(element))
                          .delay(30 * txtBox[0].txt.length)
                          .delay(50 * index)
                          .animate(
                            {
                              top: 0,
                              opacity: 1,
                              left: 0,
                            },
                            500
                          );
                      } else if (i == 2) {
                        if (String($(element).text()) == '기획,') {
                          $(element).css({ fontWeight: '800' });
                        } else if (String($(element).text()) == '디자인,') {
                          $(element).css({ fontWeight: '800' });
                        } else if (String($(element).text()) == '개발,') {
                          $(element).css({ fontWeight: '800' });
                        } else if (String($(element).text()) == '마케팅') {
                          $(element).css({ fontWeight: '800' });
                        }

                        $('#artiText p')
                          .eq(2)
                          .addClass('txt-ani-ac')
                          .removeClass('txt-ani')
                          .animate({ opacity: 1 })
                          .find($(element))
                          .delay(
                            30 * (txtBox[0].txt.length + txtBox[1].txt.length)
                          )
                          .delay(50 * index)
                          .animate(
                            {
                              top: 0,
                              opacity: 1,
                              left: 0,
                            },
                            500
                          );
                      }
                    }
                  }

                  setTimeout(() => {
                    ani1 = true;
                  }, 900);
                } else {
                  $('#main').css({ overflowY: 'auto', height: 'auto' });
                  let windowWidth = $(window).width();
                  if (windowWidth > 767) {
                    setTimeout(() => {
                      $('sticky-button > .sticky_wrap > p').addClass('text-ac');
                    }, 300);
                  }
                  $(this).off(e); ///////첫번째 스크롤이벤트 끝
                }
              }
              e.stopPropagation();
              e.preventDefault();
            });
          }
          $(function () {
            ////모바일인지 웹인지 알아보는 코드
            var filter = 'win16|win32|win64|mac|macintel';

            if (navigator.platform) {
              if (filter.indexOf(navigator.platform.toLowerCase()) < 0) {
                //mobile
                if (windowWidth < 767) {
                  $('.front-title').animate({ top: '-400px' }, 1000, 'linear');
                  for (var i = 0; i < txtBox.length; i++) {
                    //단어별로 포문 돌리기
                    for (let index = 0; index < temp.length; index++) {
                      const element = temp[index];
                      //이걸 어떻게 단축시킬수 있을까..
                      //각 문단별로 애니메이션 실행
                      if (i == 0) {
                        //특정단어 css
                        if (String($(element).text()) == '시간과') {
                          $(element).css({ fontWeight: '800' });
                        } else if (String($(element).text()) == '돈의') {
                          $(element).css({ fontWeight: '800' });
                        } else if (String($(element).text()) == '가치를') {
                          $(element).css({ fontWeight: '800' });
                        }

                        $('#artiText p')
                          .eq(0)
                          .addClass('txt-ani-ac')
                          .removeClass('txt-ani')
                          .animate({ opacity: 1 })
                          .find($(element))
                          .delay(50 * index)
                          .animate(
                            {
                              top: 0,
                              opacity: 1,
                              left: 0,
                            },
                            500
                          );
                      } else if (i == 1) {
                        $('#artiText p')
                          .eq(1)
                          .addClass('txt-ani-ac')
                          .removeClass('txt-ani')
                          .animate({ opacity: 1 })
                          .find($(element))
                          .delay(30 * txtBox[0].txt.length)
                          .delay(50 * index)
                          .animate(
                            {
                              top: 0,
                              opacity: 1,
                              left: 0,
                            },
                            500
                          );
                      } else if (i == 2) {
                        if (String($(element).text()) == '기획,') {
                          $(element).css({ fontWeight: '800' });
                        } else if (String($(element).text()) == '디자인,') {
                          $(element).css({ fontWeight: '800' });
                        } else if (String($(element).text()) == '개발,') {
                          $(element).css({ fontWeight: '800' });
                        } else if (String($(element).text()) == '마케팅') {
                          $(element).css({ fontWeight: '800' });
                        }

                        $('#artiText p')
                          .eq(2)
                          .addClass('txt-ani-ac')
                          .removeClass('txt-ani')
                          .animate({ opacity: 1 })
                          .find($(element))
                          .delay(
                            30 * (txtBox[0].txt.length + txtBox[1].txt.length)
                          )
                          .delay(50 * index)
                          .animate(
                            {
                              top: 0,
                              opacity: 1,
                              left: 0,
                            },
                            500
                          );
                      }
                    }
                  }

                  setTimeout(() => {
                    $('#main').css({ overflowY: 'auto', height: 'auto' });
                  }, 900);
                }
              } else {
                $('#main').on('mousewheel', function (e) {
                  let wheel = e.originalEvent.wheelDelta;
                  if (wheel > 0) {
                    //스크롤 올릴때
                  } else {
                    //스크롤  내릴때
                    if (ani1 == false) {
                      $('.front-title').animate(
                        { top: '-400px' },
                        1000,
                        'linear'
                      );
                      for (var i = 0; i < txtBox.length; i++) {
                        //단어별로 포문 돌리기
                        for (let index = 0; index < temp.length; index++) {
                          const element = temp[index];
                          //이걸 어떻게 단축시킬수 있을까..
                          //각 문단별로 애니메이션 실행
                          if (i == 0) {
                            //특정단어 css
                            if (String($(element).text()) == '시간과') {
                              $(element).css({ fontWeight: '800' });
                            } else if (String($(element).text()) == '돈의') {
                              $(element).css({ fontWeight: '800' });
                            } else if (String($(element).text()) == '가치를') {
                              $(element).css({ fontWeight: '800' });
                            }

                            $('#artiText p')
                              .eq(0)
                              .addClass('txt-ani-ac')
                              .removeClass('txt-ani')
                              .animate({ opacity: 1 })
                              .find($(element))
                              .delay(50 * index)
                              .animate(
                                {
                                  top: 0,
                                  opacity: 1,
                                  left: 0,
                                },
                                500
                              );
                          } else if (i == 1) {
                            $('#artiText p')
                              .eq(1)
                              .addClass('txt-ani-ac')
                              .removeClass('txt-ani')
                              .animate({ opacity: 1 })
                              .find($(element))
                              .delay(30 * txtBox[0].txt.length)
                              .delay(50 * index)
                              .animate(
                                {
                                  top: 0,
                                  opacity: 1,
                                  left: 0,
                                },
                                500
                              );
                          } else if (i == 2) {
                            if (String($(element).text()) == '기획,') {
                              $(element).css({ fontWeight: '800' });
                            } else if (String($(element).text()) == '디자인,') {
                              $(element).css({ fontWeight: '800' });
                            } else if (String($(element).text()) == '개발,') {
                              $(element).css({ fontWeight: '800' });
                            } else if (String($(element).text()) == '마케팅') {
                              $(element).css({ fontWeight: '800' });
                            }

                            $('#artiText p')
                              .eq(2)
                              .addClass('txt-ani-ac')
                              .removeClass('txt-ani')
                              .animate({ opacity: 1 })
                              .find($(element))
                              .delay(
                                30 *
                                  (txtBox[0].txt.length + txtBox[1].txt.length)
                              )
                              .delay(50 * index)
                              .animate(
                                {
                                  top: 0,
                                  opacity: 1,
                                  left: 0,
                                },
                                500
                              );
                          }
                        }
                      }

                      setTimeout(() => {
                        ani1 = true;
                      }, 900);
                    } else {
                      $('#main').css({ overflowY: 'auto', height: 'auto' });
                      let windowWidth = $(window).width();
                      if (windowWidth > 767) {
                        setTimeout(() => {
                          $('sticky-button > .sticky_wrap > p').addClass(
                            'text-ac'
                          );
                        }, 300);
                      }
                      $(this).off(e);
                    }
                  }
                });
              }
            }
          });
        }, 2100);
      });

      ////메인배너 끝

      let txtBox2 = [];
      let temp2 = [];
      function textAni2() {
        let txtLine2 = [];
        //p 태그에 있는 문장을 한 문장씩 저장
        $('.txt-area .txt-box')
          .find('p')
          .each(function (index, item) {
            let txt = $(item).text();
            let tmp = { index: index, txt: txt };
            txtLine2.push(tmp);
          });

        //문장별로 단어를 끊어서 저장
        let splitTxt2 = [];
        txtLine2.forEach((txt) => {
          splitTxt2.push(txt.txt.split(' '));
        });

        //단어별로 스펜태그로 감싸서 저장

        $(splitTxt2).each(function (index, item) {
          let txt2 = [];
          item.forEach((e) => {
            txt2.push('<span>' + e + '</span>&nbsp;');
          });
          txtBox2.push({ index: index, txt: txt2 });
        });

        //문장별 끊어서 포문을 돌리고
        for (var i = 0; i < txtBox2.length; i++) {
          //태그에 span태그로 감싼 단어 다시 넣기
          $('.txt-area .txt-box div p').eq(i).html(txtBox2[i].txt);
          //만들어진 span 태그 css
          $('.txt-area .txt-box div p span').css({
            opacity: 0,
            position: 'relative',
            top: '10px',
            left: '5px',
            lineHeight: '30px',
            display: 'inline-block',
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale',
          });

          //문장별 단어 배열 다시받기
          $('.txt-area .txt-box div p')
            .eq(i)
            .find('span')
            .each(function (index, item) {
              temp2.push(item);
            });
        }
      }

      //텍스트 애니메이션 reset
      textAni2();
      let attrSwitch1 = false;
      let attrSwitch2 = false;
      let attrSwitch3 = false;
      let attrSwitch4 = false;

      let mTxtSwitch1 = false;
      let mTxtSwitch2 = false;
      let mTxtSwitch3 = false;
      let mTxtSwitch4 = false;

      let guideSwitch = false;
      // let footerOpen = false;
      let stickyAc = false;

      //두번째 섹션 시작 --

      setTimeout(() => {
        $(window).scroll(function (e) {
          let windowWidth = $(window).width();

          let scroll_bottom =
            $(window).scrollTop() + $(window).height() + 56 - 400;

          //모든게 가능한 이유 섹션

          ///스크롤시 해당 영역에서 잡히게
          if (windowWidth > 767) {
            let img1_bottom =
              $('.txt-area .txt1 div h3').offset().top +
              $('.txt-area .txt1').outerHeight() +
              56;
            let img2_bottom =
              $('.txt-area .txt2 div h3').offset().top +
              $('.txt-area .txt2').outerHeight() +
              56;
            let img3_bottom =
              $('.txt-area .txt3 div h3').offset().top +
              $('.txt-area .txt3').outerHeight() +
              56;
            let img4_bottom =
              $('.txt-area .txt4 div h3').offset().top +
              $('.txt-area .txt4').outerHeight() +
              56;

            if (scroll_bottom > img1_bottom / 2) {
              if (!attrSwitch1) {
                $('.img-box.img1 img').attr('src', 'assets/imgs/img_ux.gif');
                $('.txt-area .txt1')
                  .find('h3')
                  .animate({ opacity: 1, top: 0 }, 1000);

                for (var i = 0; i < txtBox2.length; i++) {
                  //단어별로 포문 돌리기
                  for (let index = 0; index < temp2.length; index++) {
                    const element = temp2[index];
                    //이걸 어떻게 단축시킬수 있을까..
                    //각 문단별로 애니메이션 실행

                    $('.txt-area .txt-box')
                      .eq(0)
                      .find('div p')
                      .addClass('txt-ani-ac')
                      .removeClass('txt-ani')
                      .animate({ opacity: 1 })
                      .find($(element))
                      .delay(50 * index)
                      .animate(
                        {
                          top: 0,
                          opacity: 1,
                          left: 0,
                        },
                        600
                      )
                      .queue(function () {
                        $('.txt1').find('a').animate({ opacity: 1 }, 400);
                      });
                  }
                }
              }

              // setTimeout(() => {

              // }, 600);

              attrSwitch1 = true;
            }

            if (
              img1_bottom - $(window).height() / 2 < scroll_bottom &&
              scroll_bottom > img2_bottom / 2
            ) {
              if (!attrSwitch2) {
                $('.img-box.img2 img').attr('src', 'assets/imgs/img_ui.gif');
                $('.txt-area .txt2')
                  .find('h3')
                  .animate({ opacity: 1, top: 0 }, 1000);
                for (var i = 0; i < txtBox2.length; i++) {
                  //단어별로 포문 돌리기
                  for (let index = 0; index < temp2.length; index++) {
                    const element = temp2[index];
                    //이걸 어떻게 단축시킬수 있을까..
                    //각 문단별로 애니메이션 실행

                    $('.txt-area .txt-box')
                      .eq(1)
                      .find('div p')
                      .addClass('txt-ani-ac')
                      .removeClass('txt-ani')
                      .animate({ opacity: 1 })
                      .find($(element))
                      .delay(50 * index)
                      .animate(
                        {
                          top: 0,
                          opacity: 1,
                          left: 0,
                        },
                        600
                      )
                      .queue(function () {
                        $('.txt2').find('a').animate({ opacity: 1 }, 400);
                      });
                  }
                }
              }
              attrSwitch2 = true;
            }
            if (
              img2_bottom - $(window).height() / 2 < scroll_bottom &&
              scroll_bottom > img3_bottom / 2
            ) {
              if (!attrSwitch3) {
                $('.img-box.img3 img').attr(
                  'src',
                  'assets/imgs/img_develop.gif'
                );
                $('.txt-area .txt3')
                  .find('h3')
                  .animate({ opacity: 1, top: 0 }, 1000);
                for (var i = 0; i < txtBox2.length; i++) {
                  //단어별로 포문 돌리기
                  for (let index = 0; index < temp2.length; index++) {
                    const element = temp2[index];
                    //이걸 어떻게 단축시킬수 있을까..
                    //각 문단별로 애니메이션 실행

                    $('.txt-area .txt-box')
                      .eq(2)
                      .find('div p')
                      .addClass('txt-ani-ac')
                      .removeClass('txt-ani')
                      .animate({ opacity: 1 })
                      .find($(element))
                      .delay(50 * index)
                      .animate(
                        {
                          top: 0,
                          opacity: 1,
                          left: 0,
                        },
                        600
                      )
                      .queue(function () {
                        $('.txt3').find('a').animate({ opacity: 1 }, 400);
                      });
                  }
                }
              }
              attrSwitch3 = true;
            }
            if (
              img3_bottom - $(window).height() / 2 < scroll_bottom &&
              scroll_bottom > img4_bottom / 2
            ) {
              if (!attrSwitch4) {
                $('.img-box.img4 img').attr(
                  'src',
                  'assets/imgs/img_marketing.gif'
                );
                $('.txt-area .txt4')
                  .find('h3')
                  .animate({ opacity: 1, top: 0 }, 1000);
                for (var i = 0; i < txtBox2.length; i++) {
                  //단어별로 포문 돌리기
                  for (let index = 0; index < temp2.length; index++) {
                    const element = temp2[index];
                    //이걸 어떻게 단축시킬수 있을까..
                    //각 문단별로 애니메이션 실행

                    $('.txt-area .txt-box')
                      .eq(3)
                      .find('div p')
                      .addClass('txt-ani-ac')
                      .removeClass('txt-ani')
                      .animate({ opacity: 1 })
                      .find($(element))
                      .delay(50 * index)
                      .animate(
                        {
                          top: 0,
                          opacity: 1,
                          left: 0,
                        },
                        600
                      )
                      .queue(function () {
                        $('.txt4').find('a').animate({ opacity: 1 }, 400);
                      });
                  }
                }
              }
              attrSwitch4 = true;
              // $(this).off(e);
            }
          } else {
            ///////////////////////////////////모바일일때
            ///////////////////////////////////모바일일때
            ///////////////////////////////////모바일일때
            ///////////////////////////////////모바일일때
            let img1_bottom =
              $('.img-box.img1').offset().top +
              $('.img-box.img1').height() / 2 +
              100;
            let img2_bottom =
              $('.img-box.img2').offset().top +
              +$('.img-box.img2').height() / 2 +
              100;
            let img3_bottom =
              $('.img-box.img3').offset().top +
              +$('.img-box.img3').height() / 2 +
              100;
            let img4_bottom =
              $('.img-box.img4').offset().top +
              +$('.img-box.img4').height() / 2 +
              100;

            let scroll_bottom = $(window).scrollTop() + $(window).height();

            if (scroll_bottom > img1_bottom) {
              if (!attrSwitch1) {
                $('.img-box.img1 img').attr('src', 'assets/imgs/img_ux.gif');
              }

              attrSwitch1 = true;
              if (!mTxtSwitch1) {
                $('.m-txt-area').addClass('txt-area-active');
                $('sticky-button').animate(
                  { bottom: $('.txt-area-active').height() + 30 },
                  500
                );

                // if (scroll_bottom < img1_bottom + 300) {
                //   $('html,body').animate(
                //     {
                //       scrollTop: 1165,
                //     },
                //     500
                //   );
                // }
              }
              mTxtSwitch1 = true;
            } else {
              if (mTxtSwitch1) {
                $('.m-txt-area').removeClass('txt-area-active');
                $('sticky-button').stop().animate({ bottom: 40 }, 500);
                // $('sticky-button > .sticky_wrap > p').removeClass('text-ac');
                // $('.img-box.img1 img').attr('src', 'assets/imgs/img_ux.gif');
              }

              mTxtSwitch1 = false;
            }

            if (img1_bottom < scroll_bottom && scroll_bottom > img2_bottom) {
              if (!attrSwitch2) {
                $('.img-box.img2 img').attr('src', 'assets/imgs/img_ui.gif');
              }
              attrSwitch2 = true;
              if (!mTxtSwitch2) {
                $('.txt1').addClass('m-txt-off');
                $('.txt2').addClass('m-txt-on');
              }
              mTxtSwitch2 = true;
            } else {
              if (mTxtSwitch2) {
                // $('.img-box.img2 img').attr('src', 'assets/imgs/img_ui.gif');
                $('.txt1').removeClass('m-txt-off');
                $('.txt1').addClass('m-txt-on');
                $('.txt2').removeClass('m-txt-on');
              }
              mTxtSwitch2 = false;
            }
            if (img2_bottom < scroll_bottom && scroll_bottom > img3_bottom) {
              if (!attrSwitch3) {
                $('.img-box.img3 img').attr(
                  'src',
                  'assets/imgs/img_develop.gif'
                );
              }
              attrSwitch3 = true;
              if (!mTxtSwitch3) {
                $('.txt2').removeClass('m-txt-on');
                $('.txt2').addClass('m-txt-off');
                $('.txt3').addClass('m-txt-on');
              }
              mTxtSwitch3 = true;
            } else {
              if (mTxtSwitch3) {
                $('.txt2').addClass('m-txt-on');
                $('.txt2').removeClass('m-txt-off');
                $('.txt3').removeClass('m-txt-on');
              }
              mTxtSwitch3 = false;
            }
            if (img3_bottom < scroll_bottom && scroll_bottom > img4_bottom) {
              if (!attrSwitch4) {
                $('.img-box.img4 img').attr(
                  'src',
                  'assets/imgs/img_marketing.gif'
                );
              }
              attrSwitch4 = true;

              if (!mTxtSwitch4) {
                $('.txt3').removeClass('m-txt-on');
                $('.txt3').addClass('m-txt-off');
                $('.txt4').addClass('m-txt-on');
                $('sticky-button')
                  .stop()
                  .animate({ bottom: 40 }, 500)
                  .queue(function () {
                    $('sticky-button > .sticky_wrap > p').addClass('text-ac');
                  });
              }

              if (
                scroll_bottom >
                $('.reason-section').offset().top +
                  $('.reason-section').height()
              ) {
                $('.reason-section').css({
                  position: 'relative',
                });
                $('.m-txt-area').css({
                  position: 'absolute',
                  bottom: 0,
                });
              }

              mTxtSwitch4 = true;
              // $(this).off(e);
            } else {
              if (mTxtSwitch4) {
                // $('.img-box.img4 img').attr('src', 'assets/imgs/img_marketing.gif');
                $('.txt3').addClass('m-txt-on');
                $('.txt3').removeClass('m-txt-off');
                $('.txt4').removeClass('m-txt-on');

                $('.reason-section').css({
                  position: 'unset',
                });
                $('.m-txt-area').css({
                  position: 'fixed',
                  bottom: 0,
                });

                // if ($('.txt3').hasClass('m-txt-on')) {

                // $('sticky-button').animate(
                //   { bottom: $('.txt-area-active').height() + 30 },
                //   800
                // );

                $('sticky-button').stop().animate({ bottom: 300 }, 800);

                $('sticky-button > .sticky_wrap > p').removeClass('text-ac');
                // }
              }
              mTxtSwitch4 = false;
              // $(this).off(e);
            }
          }
          ////두번째 섹션 끝

          ///////두번째 섹션 스크롤 오류
          ///////두번째 섹션 스크롤 오류
          ///////두번째 섹션 스크롤 오류

          ///// 포트폴리오 백영역
          if (scroll_bottom + 400 >= $('.portfolio-box').offset().top + 300) {
            $('#main').addClass('back-ac');
            // $('footer-share').css({ display: 'block' });
          } else {
            $('#main').removeClass('back-ac');
          }

          ///성공을 위한 가이드 스크롤 XXXXXXXX
          let guideTop =
            $('.reason-section').offset().top +
            $('.reason-section').innerHeight();

          let slideAc1 = false;
          let scrollAc = false;

          if (windowWidth > 767) {
            if ($(window).scrollTop() >= guideTop - 300) {
              let windowWidth = $(window).width();
              if (!slideAc1) {
                $('.swiper-container').css({ width: windowWidth });
                for (let index = 0; index < 6; index++) {
                  setTimeout(() => {
                    if (windowWidth > 1199) {
                      if (
                        !$('.swiper-slide').eq(3).hasClass('slide_ac') &&
                        index == 3
                      ) {
                        swiper.slideTo(3, 4000);

                        // });
                      }
                    } else if (windowWidth < 1199 && windowWidth > 1023) {
                      if (
                        !$('.swiper-slide').eq(3).hasClass('slide_ac') &&
                        index == 3
                      ) {
                        swiper.slideTo(4, 4000);

                        // });
                      }
                    } else if (windowWidth <= 1023) {
                      if (
                        !$('.swiper-slide').eq(3).hasClass('slide_ac') &&
                        index == 3
                      ) {
                        swiper.slideTo(5, 4000);
                      }
                    }
                    $('.swiper-slide').eq(index).addClass('slide_ac');
                  }, 1000 * index);
                }
              }
              slideAc1 = true;
            } else {
              slideAc1 = true;
            }
          }

          // if (scroll_bottom + 400 > $('.footer-line').offset().top + 50) {
          //   $('sticky-button').css({ position: 'absolute' });
          // } else {
          //   $('sticky-button').css({ position: 'fixed' });
          // }

          e.preventDefault();
          e.stopPropagation();
        });
      }, 100);

      if (windowWidth > 767) {
        let mouseAc = false;
        $('.swiper-wrapper').mouseover(function (e) {
          var $cursor = $('.js-cursor-el');
          var $body = $('.guide-section');

          var mouseIsDown = false,
            lastMousePosition = { x: 0, y: 0 };
          lastMousePosition.x = e.clientX;
          lastMousePosition.y = e.clientY;

          var hoveredEl = document.elementFromPoint(
            lastMousePosition.x,
            lastMousePosition.y
          );

          if (
            $(hoveredEl).parents('.js-cursor').length ||
            $(hoveredEl).hasClass('js-cursor')
          ) {
            var $whichCursor =
              $(hoveredEl).parents('.js-cursor').attr('data-cursor') ||
              $(hoveredEl).attr('data-cursor');
            $body.addClass('is-cursor-active');
            $cursor.addClass('is-active');
            $cursor.attr('data-cursor', $whichCursor);
          } else {
            $body.removeClass('is-cursor-active');
            $cursor.removeClass('is-active');
          }

          // now draw object at lastMousePosition
          $cursor.css({
            transform: `translate3d(${lastMousePosition.x}px, ${lastMousePosition.y}px, 0)`,
          });

          function onMouseDown() {
            $cursor.addClass('is-mouse-down');
            $('.js-cursor').attr('data-cursor', 'cursor__click');
            mouseIsDown = true;
            requestAnimationFrame(update);
          }

          function onMouseUp() {
            $cursor.removeClass('is-mouse-down');
            $('.js-cursor').attr('data-cursor', 'cursor__grab');
            mouseIsDown = false;
            requestAnimationFrame(update);
          }

          function onMouseMove(evt) {
            // $('.fake_grab').css({ display: 'none' });
            lastMousePosition.x = evt.clientX;
            lastMousePosition.y = evt.clientY;
            requestAnimationFrame(update);
          }

          function update() {
            // Get the element we're hovered on
            var hoveredEl = document.elementFromPoint(
              lastMousePosition.x,
              lastMousePosition.y
            );

            // Check if the element or any of its parents have a .js-cursor class
            if (
              $(hoveredEl).parents('.js-cursor').length ||
              $(hoveredEl).hasClass('js-cursor')
            ) {
              var $whichCursor =
                $(hoveredEl).parents('.js-cursor').attr('data-cursor') ||
                $(hoveredEl).attr('data-cursor');
              $body.addClass('is-cursor-active');
              $cursor.addClass('is-active');
              $cursor.attr('data-cursor', $whichCursor);
            } else {
              $body.removeClass('is-cursor-active');
              $cursor.removeClass('is-active');
            }

            // now draw object at lastMousePosition
            $cursor.css({
              transform: `translate3d(${lastMousePosition.x}px, ${lastMousePosition.y}px, 0)`,
            });
          }

          document.addEventListener('mousedown', onMouseDown, false);
          document.addEventListener('mouseup', onMouseUp, false);
          document.addEventListener('mousemove', onMouseMove, false);
          e.stopPropagation();
        });

        $('.swiper-wrapper').mouseleave(function (e) {
          var $cursor = $('.js-cursor-el');
          var $body = $('.guide-section');

          var mouseIsDown = false,
            lastMousePosition = { x: 0, y: 0 };
          lastMousePosition.x = e.clientX;
          lastMousePosition.y = e.clientY;

          var hoveredEl = document.elementFromPoint(
            lastMousePosition.x,
            lastMousePosition.y
          );

          if (
            $(hoveredEl).parents('.js-cursor').length ||
            $(hoveredEl).hasClass('js-cursor')
          ) {
            var $whichCursor =
              $(hoveredEl).parents('.js-cursor').attr('data-cursor') ||
              $(hoveredEl).attr('data-cursor');
            $body.removeClass('is-cursor-active');
            $cursor.removeClass('is-active');
            $cursor.attr('data-cursor', $whichCursor);
          } else {
            $body.addClass('is-cursor-active');
            $cursor.addClass('is-active');
          }

          // now draw object at lastMousePosition
          $cursor.css({
            transform: `translate3d(${lastMousePosition.x}px, ${lastMousePosition.y}px, 0)`,
          });
          e.stopPropagation();
        });
      }

      let that = this;
      let filter = 'win16|win32|win64|mac|macintel';

      if (that.myDevice == filter) {
        $(window).resize(function (e) {
          let scroll_bottom =
            $(window).scrollTop() + $(window).height() + 56 - 400;

          if (windowWidth > 767) {
            let img1_bottom =
              $('.txt-area .txt1 div h3').offset().top +
              $('.txt-area .txt1').outerHeight() +
              56;
            let img2_bottom =
              $('.txt-area .txt2 div h3').offset().top +
              $('.txt-area .txt2').outerHeight() +
              56;
            let img3_bottom =
              $('.txt-area .txt3 div h3').offset().top +
              $('.txt-area .txt3').outerHeight() +
              56;
            let img4_bottom =
              $('.txt-area .txt4 div h3').offset().top +
              $('.txt-area .txt4').outerHeight() +
              56;
            let attrSwitch4 = false;
            let attrSwitch3 = false;
            let attrSwitch2 = false;
            let attrSwitch1 = false;

            $('.img-box.img1 img').attr('src', 'assets/imgs/img_ux.gif');
            $('.txt-area .txt1')
              .find('h3')
              .animate({ opacity: 1, top: 0 }, 1000);

            for (var i = 0; i < txtBox2.length; i++) {
              for (let index = 0; index < temp2.length; index++) {
                const element = temp2[index];
                $('.txt-area .txt-box')
                  .eq(0)
                  .find('div p')
                  .addClass('txt-ani-ac')
                  .removeClass('txt-ani')
                  .animate({ opacity: 1 })
                  .find($(element))
                  .delay(50 * index)
                  .animate(
                    {
                      top: 0,
                      opacity: 1,
                      left: 0,
                    },
                    600
                  )
                  .queue(function () {
                    $('.txt1').find('a').animate({ opacity: 1 }, 400);
                  });
              }
              attrSwitch1 = true;
            }

            $('.img-box.img1 img').attr('src', 'assets/imgs/img_ux.gif');
            $('.txt-area .txt1')
              .find('h3')
              .animate({ opacity: 1, top: 0 }, 1000);

            for (var i = 0; i < txtBox2.length; i++) {
              for (let index = 0; index < temp2.length; index++) {
                const element = temp2[index];
                $('.txt-area .txt-box')
                  .eq(0)
                  .find('div p')
                  .addClass('txt-ani-ac')
                  .removeClass('txt-ani')
                  .animate({ opacity: 1 })
                  .find($(element))
                  .delay(50 * index)
                  .animate(
                    {
                      top: 0,
                      opacity: 1,
                      left: 0,
                    },
                    600
                  )
                  .queue(function () {
                    $('.txt1').find('a').animate({ opacity: 1 }, 400);
                  });
              }
            }

            $('.img-box.img2 img').attr('src', 'assets/imgs/img_ui.gif');
            $('.txt-area .txt2')
              .find('h3')
              .animate({ opacity: 1, top: 0 }, 1000);
            for (var i = 0; i < txtBox2.length; i++) {
              for (let index = 0; index < temp2.length; index++) {
                const element = temp2[index];
                $('.txt-area .txt-box')
                  .eq(1)
                  .find('div p')
                  .addClass('txt-ani-ac')
                  .removeClass('txt-ani')
                  .animate({ opacity: 1 })
                  .find($(element))
                  .delay(50 * index)
                  .animate(
                    {
                      top: 0,
                      opacity: 1,
                      left: 0,
                    },
                    600
                  )
                  .queue(function () {
                    $('.txt2').find('a').animate({ opacity: 1 }, 400);
                  });
              }
              attrSwitch2 = true;
              attrSwitch1 = true;
            }
            $('.img-box.img1 img').attr('src', 'assets/imgs/img_ux.gif');
            $('.txt-area .txt1')
              .find('h3')
              .animate({ opacity: 1, top: 0 }, 1000);
            for (var i = 0; i < txtBox2.length; i++) {
              for (let index = 0; index < temp2.length; index++) {
                const element = temp2[index];
                $('.txt-area .txt-box')
                  .eq(0)
                  .find('div p')
                  .addClass('txt-ani-ac')
                  .removeClass('txt-ani')
                  .animate({ opacity: 1 })
                  .find($(element))
                  .delay(50 * index)
                  .animate(
                    {
                      top: 0,
                      opacity: 1,
                      left: 0,
                    },
                    600
                  )
                  .queue(function () {
                    $('.txt1').find('a').animate({ opacity: 1 }, 400);
                  });
              }
            }

            $('.img-box.img2 img').attr('src', 'assets/imgs/img_ui.gif');
            $('.txt-area .txt2')
              .find('h3')
              .animate({ opacity: 1, top: 0 }, 1000);
            for (var i = 0; i < txtBox2.length; i++) {
              for (let index = 0; index < temp2.length; index++) {
                const element = temp2[index];
                $('.txt-area .txt-box')
                  .eq(1)
                  .find('div p')
                  .addClass('txt-ani-ac')
                  .removeClass('txt-ani')
                  .animate({ opacity: 1 })
                  .find($(element))
                  .delay(50 * index)
                  .animate(
                    {
                      top: 0,
                      opacity: 1,
                      left: 0,
                    },
                    600
                  )
                  .queue(function () {
                    $('.txt2').find('a').animate({ opacity: 1 }, 400);
                  });
              }
            }
            $('.img-box.img3 img').attr('src', 'assets/imgs/img_develop.gif');
            $('.txt-area .txt3')
              .find('h3')
              .animate({ opacity: 1, top: 0 }, 1000);
            for (var i = 0; i < txtBox2.length; i++) {
              for (let index = 0; index < temp2.length; index++) {
                const element = temp2[index];
                $('.txt-area .txt-box')
                  .eq(2)
                  .find('div p')
                  .addClass('txt-ani-ac')
                  .removeClass('txt-ani')
                  .animate({ opacity: 1 })
                  .find($(element))
                  .delay(50 * index)
                  .animate(
                    {
                      top: 0,
                      opacity: 1,
                      left: 0,
                    },
                    600
                  )
                  .queue(function () {
                    $('.txt3').find('a').animate({ opacity: 1 }, 400);
                  });
              }
              attrSwitch3 = true;
              attrSwitch2 = true;
              attrSwitch1 = true;
            }
            $('.img-box.img1 img').attr('src', 'assets/imgs/img_ux.gif');
            $('.txt-area .txt1')
              .find('h3')
              .animate({ opacity: 1, top: 0 }, 1000);

            for (var i = 0; i < txtBox2.length; i++) {
              for (let index = 0; index < temp2.length; index++) {
                const element = temp2[index];
                $('.txt-area .txt-box')
                  .eq(0)
                  .find('div p')
                  .addClass('txt-ani-ac')
                  .removeClass('txt-ani')
                  .animate({ opacity: 1 })
                  .find($(element))
                  .delay(50 * index)
                  .animate(
                    {
                      top: 0,
                      opacity: 1,
                      left: 0,
                    },
                    600
                  )
                  .queue(function () {
                    $('.txt1').find('a').animate({ opacity: 1 }, 400);
                  });
              }
            }

            $('.img-box.img2 img').attr('src', 'assets/imgs/img_ui.gif');
            $('.txt-area .txt2')
              .find('h3')
              .animate({ opacity: 1, top: 0 }, 1000);
            for (var i = 0; i < txtBox2.length; i++) {
              for (let index = 0; index < temp2.length; index++) {
                const element = temp2[index];
                $('.txt-area .txt-box')
                  .eq(1)
                  .find('div p')
                  .addClass('txt-ani-ac')
                  .removeClass('txt-ani')
                  .animate({ opacity: 1 })
                  .find($(element))
                  .delay(50 * index)
                  .animate(
                    {
                      top: 0,
                      opacity: 1,
                      left: 0,
                    },
                    600
                  )
                  .queue(function () {
                    $('.txt2').find('a').animate({ opacity: 1 }, 400);
                  });
              }
            }
            $('.img-box.img3 img').attr('src', 'assets/imgs/img_develop.gif');
            $('.txt-area .txt3')
              .find('h3')
              .animate({ opacity: 1, top: 0 }, 1000);
            for (var i = 0; i < txtBox2.length; i++) {
              for (let index = 0; index < temp2.length; index++) {
                const element = temp2[index];
                $('.txt-area .txt-box')
                  .eq(2)
                  .find('div p')
                  .addClass('txt-ani-ac')
                  .removeClass('txt-ani')
                  .animate({ opacity: 1 })
                  .find($(element))
                  .delay(50 * index)
                  .animate(
                    {
                      top: 0,
                      opacity: 1,
                      left: 0,
                    },
                    600
                  )
                  .queue(function () {
                    $('.txt3').find('a').animate({ opacity: 1 }, 400);
                  });
              }
            }
            $('.img-box.img4 img').attr('src', 'assets/imgs/img_marketing.gif');
            $('.txt-area .txt4')
              .find('h3')
              .animate({ opacity: 1, top: 0 }, 1000);
            for (var i = 0; i < txtBox2.length; i++) {
              for (let index = 0; index < temp2.length; index++) {
                const element = temp2[index];
                $('.txt-area .txt-box')
                  .eq(3)
                  .find('div p')
                  .addClass('txt-ani-ac')
                  .removeClass('txt-ani')
                  .animate({ opacity: 1 })
                  .find($(element))
                  .delay(50 * index)
                  .animate(
                    {
                      top: 0,
                      opacity: 1,
                      left: 0,
                    },
                    600
                  )
                  .queue(function () {
                    $('.txt4').find('a').animate({ opacity: 1 }, 400);
                  });
              }
              attrSwitch4 = true;
              attrSwitch3 = true;
              attrSwitch2 = true;
              attrSwitch1 = true;
            }
          } else {
            let img1_bottom =
              $('.img-box.img1').offset().top +
              $('.img-box.img1').height() / 2 +
              100;
            let img2_bottom =
              $('.img-box.img2').offset().top +
              +$('.img-box.img2').height() / 2 +
              100;
            let img3_bottom =
              $('.img-box.img3').offset().top +
              +$('.img-box.img3').height() / 2 +
              100;
            let img4_bottom =
              $('.img-box.img4').offset().top +
              +$('.img-box.img4').height() / 2 +
              100;

            let scroll_bottom = $(window).scrollTop() + $(window).height();

            if (scroll_bottom > img1_bottom) {
              if (!attrSwitch1) {
                $('.img-box.img1 img').attr('src', 'assets/imgs/img_ux.gif');
              }

              attrSwitch1 = true;
              if (!mTxtSwitch1) {
                $('.m-txt-area').addClass('txt-area-active');
                $('sticky-button').animate(
                  { bottom: $('.txt-area-active').height() + 30 },
                  500
                );

                // if (scroll_bottom < img1_bottom + 300) {
                //   $('html,body').animate(
                //     {
                //       scrollTop: 1165,
                //     },
                //     500
                //   );
                // }
              }
              mTxtSwitch1 = true;
            } else {
              if (mTxtSwitch1) {
                $('.m-txt-area').removeClass('txt-area-active');
                $('sticky-button').stop().animate({ bottom: 40 }, 500);
                // $('sticky-button > .sticky_wrap > p').removeClass('text-ac');
                // $('.img-box.img1 img').attr('src', 'assets/imgs/img_ux.gif');
              }

              mTxtSwitch1 = false;
            }

            if (img1_bottom < scroll_bottom && scroll_bottom > img2_bottom) {
              if (!attrSwitch2) {
                $('.img-box.img2 img').attr('src', 'assets/imgs/img_ui.gif');
              }
              attrSwitch2 = true;
              if (!mTxtSwitch2) {
                $('.txt1').addClass('m-txt-off');
                $('.txt2').addClass('m-txt-on');
              }
              mTxtSwitch2 = true;
            } else {
              if (mTxtSwitch2) {
                // $('.img-box.img2 img').attr('src', 'assets/imgs/img_ui.gif');
                $('.txt1').removeClass('m-txt-off');
                $('.txt1').addClass('m-txt-on');
                $('.txt2').removeClass('m-txt-on');
              }
              mTxtSwitch2 = false;
            }
            if (img2_bottom < scroll_bottom && scroll_bottom > img3_bottom) {
              if (!attrSwitch3) {
                $('.img-box.img3 img').attr(
                  'src',
                  'assets/imgs/img_develop.gif'
                );
              }
              attrSwitch3 = true;
              if (!mTxtSwitch3) {
                $('.txt2').removeClass('m-txt-on');
                $('.txt2').addClass('m-txt-off');
                $('.txt3').addClass('m-txt-on');
              }
              mTxtSwitch3 = true;
            } else {
              if (mTxtSwitch3) {
                $('.txt2').addClass('m-txt-on');
                $('.txt2').removeClass('m-txt-off');
                $('.txt3').removeClass('m-txt-on');
              }
              mTxtSwitch3 = false;
            }
            if (img3_bottom < scroll_bottom && scroll_bottom > img4_bottom) {
              if (!attrSwitch4) {
                $('.img-box.img4 img').attr(
                  'src',
                  'assets/imgs/img_marketing.gif'
                );
              }
              attrSwitch4 = true;

              if (!mTxtSwitch4) {
                $('.txt3').removeClass('m-txt-on');
                $('.txt3').addClass('m-txt-off');
                $('.txt4').addClass('m-txt-on');
                $('sticky-button')
                  .stop()
                  .animate({ bottom: 40 }, 500)
                  .queue(function () {
                    $('sticky-button > .sticky_wrap > p').addClass('text-ac');
                  });
              }

              if (
                scroll_bottom >
                $('.reason-section').offset().top +
                  $('.reason-section').height()
              ) {
                $('.reason-section').css({
                  position: 'relative',
                });
                $('.m-txt-area').css({
                  position: 'absolute',
                  bottom: 0,
                });
              }

              mTxtSwitch4 = true;
              // $(this).off(e);
            } else {
              if (mTxtSwitch4) {
                // $('.img-box.img4 img').attr('src', 'assets/imgs/img_marketing.gif');
                $('.txt3').addClass('m-txt-on');
                $('.txt3').removeClass('m-txt-off');
                $('.txt4').removeClass('m-txt-on');

                $('.reason-section').css({
                  position: 'unset',
                });
                $('.m-txt-area').css({
                  position: 'fixed',
                  bottom: 0,
                });

                // if ($('.txt3').hasClass('m-txt-on')) {

                // $('sticky-button').animate(
                //   { bottom: $('.txt-area-active').height() + 30 },
                //   800
                // );

                $('sticky-button').stop().animate({ bottom: 300 }, 800);

                $('sticky-button > .sticky_wrap > p').removeClass('text-ac');
                // }
              }
              mTxtSwitch4 = false;

              // $(this).off(e);
            }
          }
        });
      }

      $(function () {
        // $('footer-share')
        //   .find('.top_btn')
        //   .on('click', function (e) {
        //     $('html, body').animate(
        //       {
        //         scrollTop: 0,
        //       },
        //       600
        //     );
        //     return false;
        //   });
        // $('footer-share')
        //   .find('.to-process')
        //   .on('click', function (e) {
        //     $('html, body').animate(
        //       {
        //         scrollTop: $('#process').offset().top,
        //       },
        //       600
        //     );
        //     return false;
        //   });
        // $('footer-share')
        //   .find('.to-portfolio')
        //   .on('click', function (e) {
        //     $('html, body').animate(
        //       {
        //         scrollTop: $('#portfolioWrap').offset().top,
        //       },
        //       600
        //     );
        //     return false;
        //   });
        // $('footer')
        //   .find($('#process'))
        //   .click(function () {
        //     $(window).animate({ scrollTop: $('#process').offset().top });
        //   });
      });
    }, 400);
  }

  goHome() {
    this.router.navigateByUrl('/').then(() => {
      window.location.reload();
    });
  }
  goService() {
    this.router.navigateByUrl('/service-menu').then(() => {
      window.location.reload();
    });
  }
  goPortfolio() {
    this.router.navigateByUrl('/portfolio').then(() => {
      window.location.reload();
    });
  }
  goInquiry() {
    this.router.navigateByUrl('/inquiry').then(() => {
      window.location.reload();
    });
  }
  goplan() {
    this.router.navigateByUrl('/plan').then(() => {
      window.location.reload();
    });
  }
  godesign() {
    this.router.navigateByUrl('/design').then(() => {
      window.location.reload();
    });
  }
  godevelopment() {
    this.router.navigateByUrl('/development').then(() => {
      window.location.reload();
    });
  }
  gomarketing() {
    this.router.navigateByUrl('/marketing').then(() => {
      window.location.reload();
    });
  }
  goHanra() {
    this.router.navigateByUrl('/portfolio-hanra').then(() => {
      window.location.reload();
    });
  }

  goPfDetail(id) {
    this.router.navigateByUrl(`/portfolio-detail/${id}`).then(() => {
      window.location.reload();
    });
  }
}

/*스크롤이 아랫방향일때 main의가 아니라 slide_inner의 offsetleft가 400 > ++  한 스크롤마다 면 되지않나*/
/*스크롤 스무더는 쓰면 좋겟다*/
