import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as $ from 'jquery';
@Component({
  selector: 'footer-share',
  templateUrl: './footer-share.component.html',
  styleUrls: ['./footer-share.component.scss'],
})
export class FooterShareComponent implements OnInit {
  @Input() urlPath = '';
  path;

  planSwitch = false;
  designSwitch = false;
  developSwitch = false;
  marketingSwitch = false;

  constructor(private router: Router) {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.planSwitch = false;
        this.designSwitch = false;
        this.developSwitch = false;
        this.marketingSwitch = false;

        let url = e.url;
        let splitUrl = url.split('/');
        this.path = '/' + splitUrl[1];

        if (this.path.includes('portfolio-detail')) {
          this.path = '/portfolio-detail';
        }

        let footer = document.getElementById('footer');
        switch (this.path) {
          case '/service-menu':
            footer.classList.add('opacity');
            break;
          case '/plan':
            footer.classList.remove('opacity');
            this.planSwitch = true;
            break;
          case '/design':
            footer.classList.remove('opacity');
            this.designSwitch = true;
            break;
          case '/development':
            footer.classList.remove('opacity');
            this.developSwitch = true;
            break;
          case '/marketing':
            footer.classList.remove('opacity');
            this.marketingSwitch = true;
            break;
          case '/portfolio':
            footer.classList.add('white');
            break;
          case '/portfolio-hanra':
            footer.classList.add('white');
            break;
          case '/portfolio-detail':
            footer.classList.add('white');
            break;

          case '/simple-estimate':
            footer.classList.add('white');
            break;
          case '/inquiry':
            footer.classList.add('white');

            break;
          case '/inquiry-complete':
            setTimeout(() => {
              footer.classList.add('white');
            }, 0);

            break;

          default:
            break;
        }
      }
    });
  }

  ngOnInit(): void {
    $(function () {
      $('.top_btn').on('click', function (e) {
        $('html,body').animate({ scrollTop: 0 }, 500);
        return false;
      });
    });
  }

  goHome() {
    this.router.navigateByUrl('/').then(() => {
      window.location.reload();
    });
  }
  goService() {
    this.router.navigateByUrl('/service-menu').then(() => {
      window.location.reload();
    });
  }
  goPortfolio() {
    this.router.navigateByUrl('/portfolio').then(() => {
      window.location.reload();
    });
  }
  goInquiry() {
    this.router.navigateByUrl('/inquiry').then(() => {
      window.location.reload();
    });
  }
  goplan() {
    this.router.navigateByUrl('/plan').then(() => {
      window.location.reload();
    });
  }
  godesign() {
    this.router.navigateByUrl('/design').then(() => {
      window.location.reload();
    });
  }
  godevelopment() {
    this.router.navigateByUrl('/development').then(() => {
      window.location.reload();
    });
  }
  gomarketing() {
    this.router.navigateByUrl('/marketing').then(() => {
      window.location.reload();
    });
  }
}
