/** @format */

import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection,
  DocumentChangeAction,
  Action,
  DocumentSnapshotDoesNotExist,
  DocumentSnapshotExists,
  QuerySnapshot,
} from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable, combineLatest, defer, of, pipe } from 'rxjs';
import { map, tap, take, switchMap } from 'rxjs/operators';
import * as firebase from 'firebase/app';

export const docJoin = (afs: AngularFirestore, paths: { [key: string]: string }) => {
  return (source) =>
    defer(() => {
      let parent;
      const keys = Object.keys(paths);

      return source.pipe(
        switchMap((data) => {
          // Save the parent data state
          parent = data;

          // Map each path to an Observable
          const docs$ = keys.map((k) => {
            const fullPath = `${paths[k]}/${parent[k]}`;
            return afs.doc(fullPath).valueChanges();
          });

          // return combineLatest, it waits for all reads to finish
          return combineLatest(docs$);
        }),
        map((arr) => {
          // We now have all the associated douments
          // Reduce them to a single object based on the parent's keys
          const joins = keys.reduce((acc, cur, idx) => {
            return { ...acc, [cur]: arr[idx] };
          }, {});

          // Return the parent doc with the joined objects
          return { ...parent, ...joins };
        })
      );
    });
};

export const leftJoin = (afs: AngularFirestore, field, collection, limit = 100) => {
  return (source) =>
    defer(() => {
      // Operator state
      let collectionData;

      // Track total num of joined doc reads
      let totalJoins = 0;

      return source.pipe(
        switchMap((data) => {
          // Clear mapping on each emitted val ;

          // Save the parent data state
          collectionData = data as any[];

          const reads$ = [];
          for (const doc of collectionData) {
            // Push doc read to Array

            if (doc[field]) {
              // Perform query on join key, with optional limit
              const q = (ref) => ref.where(field, '==', doc[field]).limit(limit);

              reads$.push(afs.collection(collection, q).valueChanges());
            } else {
              reads$.push(of([]));
            }
          }

          return combineLatest(reads$);
        }),
        map((joins) => {
          return collectionData.map((v, i) => {
            totalJoins += joins[i].length;
            return { ...v, [collection]: joins[i] || null };
          });
        }),
        tap((final) => {
          totalJoins = 0;
        })
      );
    });
};

export const leftJoinDocument = (afs: AngularFirestore, field, collection) => {
  return (source) =>
    defer(() => {
      // Operator state
      let collectionData;
      const cache = new Map();

      return source.pipe(
        switchMap((data) => {
          // Clear mapping on each emitted val ;
          cache.clear();

          // Save the parent data state
          collectionData = data as any[];

          const reads$ = [];
          let i = 0;
          for (const doc of collectionData) {
            // Skip if doc field does not exist or is already in cache
            if (!doc[field] || cache.get(doc[field])) {
              continue;
            }

            // Push doc read to Array
            reads$.push(afs.collection(collection).doc(doc[field]).valueChanges());
            cache.set(doc[field], i);
            i++;
          }

          return reads$.length ? combineLatest(reads$) : of([]);
        }),
        map((joins) => {
          return collectionData.map((v, i) => {
            const joinIdx = cache.get(v[field]);
            return { ...v, [field]: joins[joinIdx], user: joins[joinIdx] || null };
          });
        })
      );
    });
};

export const colletionJoin = (afs: AngularFirestore, category, collection, field, limit) => {
  return (source) =>
    defer(() => {
      // Operator state
      let array;

      // Track total num of joined doc reads
      let totalJoins = 0;

      return source.pipe(
        switchMap((select) => {
          // Clear mapping on each emitted val ;

          // Save the parent data state
          array = category as any[];

          const reads$ = [];
          for (const doc of array) {
            // Push doc read to Array
            if (doc) {
              // Perform query on join key, with optional limit
              const q = (ref) => ref.where('genre', '==', select).where(field, 'array-contains', doc).orderBy('totalLike', 'desc').limit(limit);
              const collectionMap = pipe(
                map((docs: QuerySnapshot<any>) => {
                  return docs.docs.map((e) => {
                    return {
                      id: e.id,
                      ...e.data(),
                    } as any;
                  });
                })
              );
              reads$.push(
                afs
                  .collection(collection, q)
                  .snapshotChanges()
                  .pipe(
                    map((actions) => {
                      return actions.map((a) => {
                        const data: any = a.payload.doc.data();
                        const id = a.payload.doc.id;
                        return { id, ...data };
                      });
                    })
                  )
              );
            } else {
              reads$.push(of([]));
            }
          }

          return combineLatest(reads$);
        }),
        map((joins) => {
          return array.map((v, i) => {
            totalJoins += joins[i].length;
            return { ['name']: v, ['photos']: joins[i] || null };
          });
        }),
        tap((final) => {
          totalJoins = 0;
        })
      );
    });
};

type CollectionPredicate<T> = string | AngularFirestoreCollection<T>;
type DocPredicate<T> = string | AngularFirestoreDocument<T>;

@Injectable({
  providedIn: 'root',
})
export class DbService {
  public masterRef;
  constructor(public afs: AngularFirestore, public angularDb: AngularFireDatabase) {
    this.masterRef = this.afs.doc(`master/D6HzZot2emcAXkb5qkpVet21gnn1`);
  }

  createdAt() {
    return firebase.default.firestore.FieldValue.serverTimestamp();
  }

  getUser(userId) {
    return this.angularDb.object('/users/' + userId);
  }

  list$(path) {
    return this.angularDb
      .list(path)
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a: any) => {
            const id = a.key;
            const data = a.payload.val();
            return { id, data };
          });
        })
      );
  }

  object$(path) {
    return this.angularDb.object(path);
  }

  collection$(path, query?) {
    return this.afs
      .collection(path, query)
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const data: any = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
  }

  doc$(path): Observable<any> {
    return this.afs
      .doc(path)
      .snapshotChanges()
      .pipe(
        map((doc) => {
          const data: any = doc.payload.data();
          const id = doc.payload.id;
          return { id: doc.payload.id, ...data };
        })
      );
  }

  doc2$<T>(ref: DocPredicate<T>): Observable<T> {
    return this.doc(ref)
      .snapshotChanges()
      .pipe(
        map((doc: Action<DocumentSnapshotDoesNotExist | DocumentSnapshotExists<T>>) => {
          return doc.payload.data() as T;
        })
      );
  }

  col$<T>(ref: CollectionPredicate<T>, queryFn?): Observable<T[]> {
    return this.col(ref, queryFn)
      .snapshotChanges()
      .pipe(
        map((docs: DocumentChangeAction<T>[]) => {
          return docs.map((a: DocumentChangeAction<T>) => a.payload.doc.data()) as T[];
        })
      );
  }

  col<T>(ref: CollectionPredicate<T>, queryFn?): AngularFirestoreCollection<T> {
    return typeof ref === 'string' ? this.afs.collection<T>(ref, queryFn) : ref;
  }

  doc<T>(ref: DocPredicate<T>): AngularFirestoreDocument<T> {
    return typeof ref === 'string' ? this.afs.doc<T>(ref) : ref;
  }

  /**
   * @param  {string} path 'collection' or 'collection/docID'
   * @param  {object} data new data
   *
   * Creates or updates data on a collection or document.
   **/

  // ** 기본적인 DB처리 **//

  updateAt(path: string, data: Object): Promise<any> {
    const segments = path.split('/').filter((v) => v);
    if (segments.length % 2) {
      // Odd is always a collection
      return this.afs.collection(path).add(data);
    } else {
      // Even is always document
      return this.afs.doc(path).set(data, { merge: true });
    }
  }

  /**
   * @param  {string} path path to document
   *
   * Deletes document from Firestore
   **/

  delete(path) {
    return this.afs.doc(path).delete();
  }

  master(): Observable<any> {
    return this.collection$(`master`).pipe(
      map((collection) => {
        return collection[0];
      })
    );
  }

  // Helper to format the docId for relationships
  public concatIds(a: string, b: string) {
    return `${a}_${b}`;
  }

  checkUsername(nickName: string) {
    // nickName = nickName.toLowerCase();
    return this.collection$(`users`, (ref) => ref.where('nickName', '==', nickName));
  }

  checkEmailClosed(email: string) {
    email = email.toLowerCase();
    return this.collection$('EmailClosed', (ref) => ref.where('email', '==', email).orderBy('dateClosed', 'desc').limit(1));
  }

  // ///// masterId and infoId /////
  // masterId: string = 'La7NiW7QLJaFJTe5oXPLVBWdNbZ2';
  // infoId: string = 'BsrsX8GahU3doASSzfhp';

  // returnMaster() {
  //   return this.doc$(`master/${this.masterId}`);
  // }

  // returnMasterInfo() {
  //   return this.doc$(`master/${this.masterId}/info/${this.infoId}`);
  // }

  createId() {
    return this.afs.createId();
  }
}
