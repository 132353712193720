<main id="main">
  <section class="top-box">
    <h2>문의하기</h2>
    <p>
      우리는 스타트업에게 가장 필요한 것이 무엇인지 잘 알고 있습니다.<br />
      지금 바로 문의하세요.
    </p>
  </section>

  <div class="all-wrap">
    <section id="form-section"
      class="w1180">
      <h2 class="title">
        현명한 선택, 보장된 결과 <br />
        최고의 파트너 스타트앱과 함께 하세요.
      </h2>
      <div class="form-inner">
        <div class="default-wrap">
          <h3 class="form-title">01. 기본정보를 입력해주세요.</h3>

          <div class="input-wrap">
            <label for="name">이름</label>
            <input type="text"
              id="name"
              name="name"
              placeholder="이름을 입력해주세요."
              [(ngModel)]="inquiry.username" />
            <div *ngIf="isSubmitted && !inquiry.username">
              <p class="validation">
                이름은 필수사항입니다. 이름을 입력해주세요.
              </p>
            </div>
          </div>

          <div class="input-wrap">
            <label for="contactInfo">연락처</label>
            <input type="text"
              id="contactInfo"
              name="contactInfo"
              [(ngModel)]="inquiry.phone"
              placeholder="연락처에 '-'를 포함해 입력해주세요." />
            <div *ngIf="isSubmitted && !inquiry.phone">
              <p class="validation">
                연락처는 필수사항입니다. 연락처를 입력해주세요.
              </p>
            </div>
          </div>

          <div class="input-wrap email">
            <label for="email">이메일</label>
            <div class="relative">
              <input type="text"
                id="email"
                name="email"
                placeholder="이메일을 입력해주세요."
                [(ngModel)]="email" />
              <span class="mark">@</span>
              <div class="email-address">
                <button type="button"
                  id="addressBtn">
                  <span class="text"
                    id="addressText">선택하기</span>
                  <span class="icon"><img src="assets/imgs/inquiry_select_icon.png"
                      alt="셀렉트아이콘" /></span>
                </button>
                <ul>
                  <li (click)="checkEmail('naver.com')">naver.com</li>
                  <li (click)="checkEmail('gmail.com')">gmail.com</li>
                  <li (click)="checkEmail('daum.net')">daum.net</li>
                  <li (click)="checkEmail('hanmail.net')">hanmail.net</li>
                  <li (click)="checkEmail('nate.com')">nate.com</li>
                  <li (click)="checkEmail('직접입력')">직접입력</li>
                </ul>
                <!--
                <select name="searchYear" id="searchYear">
                  <option value="naver.com">naver.com</option>
                  <option value="gmail.com">gmail.com</option>
                  <option value="daum.net">daum.net</option>
                  <option value="hanmail.net">hanmail.net</option>
                  <option value="nate.com">nate.com</option>
                  <option value="직접입력">직접입력</option>
                </select> -->
              </div>
              <div class="direct">
                <label for="direct"
                  class="away">이메일 주소</label>
                <input type="text"
                  name="direct"
                  id="direct"
                  placeholder="직접입력"
                  [(ngModel)]="emailAdd" />
              </div>
            </div>
            <div *ngIf="isSubmitted && (!this.email || !this.emailAdd)">
              <p class="validation email"
                *ngIf="!this.email || !this.emailAdd">
                이메일 주소는 필수사항입니다. 이메일 주소를 입력해주세요.
              </p>
            </div>
          </div>
          <!-- email end -->
          <!-- 02. 서비스 정보 입력 -->
          <div class="service-wrap service-left">
            <h3 class="form-title">02. 서비스 정보를 입력해주세요.</h3>
            <div class="input-wrap"
              *ngIf="selectWeb">
              <label for="selectWeb">선택 웹페이지</label>
              <input type="text"
                name="selectWeb"
                id="selectWeb"
                disabled
                placeholder="회사명을 입력해주세요."
                [(ngModel)]="selectWeb.title" />
            </div>
            <div class="input-wrap">
              <label for="company">회사명</label>
              <input type="text"
                name="company"
                id="company"
                placeholder="회사명을 입력해주세요."
                [(ngModel)]="inquiry.company" />
              <div *ngIf="isSubmitted && !inquiry.company">
                <p class="validation">
                  회사명은 필수사항입니다. 회사명을 입력해주세요.
                </p>
              </div>
            </div>
            <div class="input-wrap">
              <label for="project">프로젝트명</label>
              <input type="text"
                name="project"
                id="project"
                placeholder="프로젝트명을 입력해주세요."
                [(ngModel)]="inquiry.project" />
              <div *ngIf="isSubmitted && !inquiry.project">
                <p class="validation">
                  프로젝트명은 필수사항입니다. 프로젝트명은 입력해주세요.
                </p>
              </div>
            </div>
            <!--  -->
            <div class="input-wrap">
              <div class="service-type cf">
                <p class="label">서비스 종류 (중복선택 가능)</p>
                <label for="{{item.title}}"
                  *ngFor="let item of serviceList"
                  class="radio">
                  <input type="checkbox"
                    id="consulting"
                    (change)="chagneServieList()"
                    [(ngModel)]="item.checked" />
                  {{item.title}}
                </label>

              </div>
              <div *ngIf="isSubmitted && !inquiry.service.length">
                <p class="validation">
                  서비스 종류는 필수사항입니다. 서비스 종류를 선택해주세요.
                </p>
              </div>
            </div>
            <!--  -->
          </div>
        </div>
        <!-- default-wrap end -->
        <div class="right-wrap">
          <div class="service-wrap">
            <!-- 03. 추가 정보 입력 -->
            <h3 class="form-title">03. 추가 정보를 입력해주세요.</h3>
            <div class="input-wrap">
              <div class="service-type cf">
                <p class="label">방문 경로</p>
                <div class="checkgap">
                  <label for="{{item.title}}"
                    *ngFor="let item of routeList"
                    class="radio">
                    <input type="radio"
                      [(ngModel)]="inquiry.visitRoute"
                      value="{{item.title}}" />
                    {{item.title}}
                  </label>
                </div>
              </div>
              <div *ngIf="isSubmitted && !inquiry.visitRoute">
                <p class="validation">
                  방문경로를 선택해주세요.
                </p>
              </div>
            </div>
            <!--  -->
            <div class="input-wrap budget">
              <label for="budget">예산</label>
              <div class="budget-input">
                <input type="number"
                  name="budget"
                  id="budget"
                  placeholder="예산 금액을 입력해주세요."
                  [(ngModel)]="inquiry.budget" />
                <span class="unit">만원</span>
              </div>
              <div *ngIf="isSubmitted && !inquiry.budget">
                <p class="validation">
                  예산은 필수사항입니다. 예산을 입력해주세요.
                </p>
              </div>
            </div>
            <div class="input-wrap detail-info">
              <label for="detail">상세 정보<span class="blue">[ 선택 ]</span></label>
              <textarea name="detail"
                id="detail"
                rows="10"
                placeholder="상세정보를 입력해주세요."
                [(ngModel)]="inquiry.content"></textarea>
            </div>

            <div class="input-wrap reference">
              <p class="label">참고 문서<span class="blue">[ 선택 ]</span></p>

              <!-- 파일 업로드 시 나타나는 div -->
              <div class="upload-view"
                *ngIf="fileArr.length != 0">
                <div class="upload-file"
                  *ngFor="let item of fileArr">
                  <p class="file-name fl">{{ item.name }}</p>
                  <button type="button"
                    class="file-del fr"
                    (click)="delete(item)"></button>
                </div>
              </div>

              <!-- 파일 업로드 버튼 div -->
              <div class="file-upload">
                <label for="reference"
                  class="cf">
                  <p class="placeholder fl">
                    파일을 선택해주세요. [최대 10MB]
                  </p>
                  <p type="button"
                    class="reference-icon fr"></p>
                </label>
                <input type="file"
                  name="reference"
                  id="reference"
                  [(ngModel)]="reference"
                  style="display: none"
                  (change)="selectFile($event)"
                  (click)="reference = ''" />
              </div>
            </div>
          </div>
          <!-- ///**211124 삭제 파트 service-wrap end -->
          <!-- <div class="simple-estimate">
              <h3 class="form-title">
                03. 견적과 함께 문의를 진행해보세요.
                <span class="blue">[ 선택 ]</span>
              </h3>
              <button type="button" class="estimate-btn" *ngIf="!getEstimeate">
                <a (click)="goSimpleEstimate()"> 간편견적 내보기</a>
              </button>
              <button
                type="button"
                class="estimate-btn complete"
                *ngIf="getEstimeate"
                [disabled]="getEstimeate"
              >
                간편견적 완료
              </button>
            </div> -->
          <!-- simple-estimate end -->
        </div>
        <!-- right-wrap end -->
      </div>
      <!-- form-inner end -->

      <div class="form-bot">
        <div class="check-privacy-wrap">
          <div class="privacy-check">
            <label for="privacy">
              상담 서비스를 제공하기 위한
              <span class="blue">개인정보 처리방침</span>에 동의합니다.
            </label>
            <input type="checkbox"
              name="privacy"
              id="privacy"
              [(ngModel)]="privacy" />
            <button type="button"
              (click)="goDetailPrivacy()"
              class="privacy-detail">전문보기</button>
            <p>
              모든 정보는 안전하게 처리되며,
              <span class="block">상담 목적으로만 사용됩니다.</span>
            </p>
            <div *ngIf="isSubmitted && !privacy">
              <p class="validation">개인정보 처리방침에 동의해주세요.</p>
            </div>
          </div>
          <!-- <div class="privacy-check">
              <label for="privacy">
                (선택) 마케팅 활용을 위한
                <span class="blue">개인정보 처리방침</span>에 동의합니다.
              </label>
              <input type="checkbox"
                name="privacy"
                id="privacy"
                [(ngModel)]="marketing" />
              <button type="button"
                class="privacy-detail">전문보기</button>
              <p>
                모든 정보는 안전하게 처리되며,
                <span class="block">상담 목적으로만 사용됩니다.</span>
              </p>
              <div *ngIf="isSubmitted && !privacy">
                <p class="validation">개인정보 처리방침에 동의해주세요.</p>
              </div>
            </div> -->
        </div>
        <!-- privacy-check end -->

        <!-- disabled 인 경우 css 있음 -->
        <!-- <button
          type="submit"
          class="inquire"
          [routerLink]="['/inquiry-complete']"
        > -->
        <button type="button"
          class="inquire"
          *ngIf="!check()"
          (click)="submit()">
          문의하기
        </button>

        <button type="button"
          class="inquire"
          (mouseover)="checkValidation()"
          *ngIf="check()">
          문의하기
        </button>
      </div>
      <!-- form-bot end -->
    </section>
  </div>
</main>
<footer-share id="footerWrap"></footer-share>
<footer-share class="mobile-footer"></footer-share>

<!-- <div id="inquiry-footer">
  <div class="footer-inner">
    <footer-share></footer-share>
  </div>
</div> -->

<!-----------------//pdf 영역------------------------>

<div class="none-area"
  style="position: absolute; left: -9999px; top: -9999px">
  <div class="view-pdf"
    id="pdfBody">
    <div class="view-wrap">
      <p class="pdf-date">
        발행 일자&nbsp;&nbsp;&nbsp;{{ saveDate | date : "yy.MM.dd" }}
      </p>
      <h1>견적서</h1>

      <table class="top-table">
        <tr>
          <th rowspan="2"
            width="25%">
            이름 <span class="info">{{ inquiry?.username }}</span>
          </th>
          <th width="30%">사업자 번호</th>
          <td width="45%">609-86-25710</td>
        </tr>
        <tr>
          <th width="30%">상호</th>
          <td width="45%">케이엔큐레이션 주식회사</td>
        </tr>
        <tr>
          <th rowspan="2"
            class="border-none"
            width="25%">
            회사 명
            <span class="info">{{ inquiry?.company }}</span>
          </th>
          <th width="30%">대표</th>
          <td width="45%">강민구, 고현</td>
        </tr>
        <tr>
          <th width="30%">주소</th>
          <td width="45%">
            서울특별시 성동구 아차산로 17길 48 SKV1센터 1동 1706호,1707호
          </td>
        </tr>
        <tr class="row-css">
          <th rowspan="2"
            width="25%">
            프로젝트 명
            <span class="info">{{ inquiry?.project }}</span>
          </th>
          <th width="30%">전화 번호</th>
          <td width="45%">02-1664-6786</td>
        </tr>
      </table>
    </div>
  </div>

  <table class="bottom-table"
    id="my-table">
    <thead>
      <tr>
        <th>항목명</th>
        <th>설명</th>
        <!-- <th>개발 비용</th> -->
      </tr>
    </thead>
    <tbody *ngFor="let required of requiredArr">
      <tr *ngFor="let item of required.osDetail">
        <td *ngIf="item.check == true">{{ item.title }}</td>
        <td *ngIf="item.check == true">{{ item.con }}</td>
        <!-- <td>{{ item.price }}</td> -->
      </tr>
      <tr *ngFor="let item of required.pageDetail">
        <td *ngIf="item.check == true">{{ item.title }}</td>
        <td *ngIf="item.check == true">{{ item.con }}</td>
        <!-- <td>{{ item.price }}</td> -->
      </tr>
      <tr *ngFor="let item of required.planDetail">
        <td *ngIf="item.check == true">{{ item.title }}</td>
        <td *ngIf="item.check == true">{{ item.con }}</td>
        <!-- <td>{{ item.price }}</td> -->
      </tr>
      <tr *ngFor="let item of required.designDetail">
        <td *ngIf="item.check == true">{{ item.title }}</td>
        <td *ngIf="item.check == true">{{ item.con }}</td>
        <!-- <td>{{ item.price }}</td> -->
      </tr>
      <!-- <tr *ngIf="pageNum">
        <td>{{ pageNum.title }}</td>
        <td>{{ pageNum.con }}</td>
      </tr>
      <tr *ngIf="planPick">
        <td>{{ planPick.title }}</td>
        <td>{{ planPick.con }}</td>
      </tr>
      <tr *ngIf="designPick">
        <td>{{ designPick.title }}</td>
        <td>{{ designPick.con }}</td>
      </tr> -->
      <!-- <tr *ngIf="">
            <td>{{ designPick.title }}</td>
            <td>{{ designPick.con }}</td>
            <td>{{ designPick.price }}</td>
          </tr> -->
    </tbody>

    <tbody *ngFor="let info of additional">
      <tr *ngFor="let item of info.detail">
        <td *ngIf="item.check == true">{{ item.title }}</td>
        <td *ngIf="item.check == true">{{ item.con }}</td>
        <!-- <td *ngIf="item.check == true">{{ item.price }}</td> -->
      </tr>
    </tbody>

    <tfoot>
      <!-- <tr>
            <th>합계</th>
            <td colspan="2">₩ {{ sumTotalPrice() | number }}원</td>
          </tr> -->
    </tfoot>
  </table>
</div>

<div class="load-wrap"
  [ngClass]="{ active: loading }">
  <p class="loading-icon">
    <img src="assets/imgs/loading.gif"
      alt="" />
  </p>

  <p class="logo-icon">
    <img src="assets/imgs/logo.png"
      alt="" />
  </p>
</div>
