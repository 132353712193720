<main id="main" class="main-wrap">
  <header-share id="serviceHeader"></header-share>
  <div class="menu-wrap">
    <div class="img-wrap">
      <div class="img-box img-box1">
        <img src="assets/imgs/service-m01.png" alt="기획 컨셉 이미지" />
        <video id="videoBox" src="assets/video/header_bg_plan.mp4"></video>
      </div>
      <div class="img-box img-box2">
        <img src="assets/imgs/service-m02.png" alt="디자인 컨셉 이미지" />
        <video id="videoBox" src="assets/video/header_bg_design.mp4"></video>
      </div>
      <div class="img-box img-box3">
        <img src="assets/imgs/service-m03.png" alt="개발 컨셉 이미지" />
        <video id="videoBox" src="assets/video/bg_develop.mp4"></video>
      </div>
      <div class="img-box img-box4">
        <img src="assets/imgs/service-m04.png" alt="마케팅 컨셉 이미지" />
        <video id="videoBox" src="assets/video/bg_marketing.mp4"></video>
      </div>
    </div>
    <div class="text-wrap">
      <article class="s-menu1" (click)="goplan()">
        <div class="front-text">
          <div class="top_txt">
            <h2>
              <span>01</span>
              <p>UX기획 & 전략</p>
            </h2>
            <p class="info-text">
              우리는 사용자에 대한 이해와<br />
              공감으로 부터 시작합니다.
            </p>
          </div>
          <button>더 알아보기</button>
        </div>
      </article>
      <article class="s-menu2" (click)="godesign()">
        <div class="front-text">
          <div class="top_txt">
            <h2>
              <span>02</span>
              <p>UI 디자인</p>
            </h2>
            <p class="info-text">
              완벽한 디자인으로 디지털 솔루션에<br />
              의미와 무게를 더합니다.
            </p>
          </div>
          <button>더 알아보기</button>
        </div>
      </article>
      <article class="s-menu3" (click)="godevelopment()">
        <div class="front-text front-text3">
          <div class="top_txt">
            <h2>
              <span>03</span>
              <p>Android<span class="break"></span> / iOS 개발</p>
            </h2>
            <p class="info-text">
              우리에게 불가능한 개발이란<br />
              없습니다.
            </p>
          </div>
          <button>더 알아보기</button>
        </div>
      </article>
      <article class="s-menu4" (click)="gomarketing()">
        <div class="front-text">
          <div class="top_txt">
            <h2>
              <span>04</span>
              <p>마케팅</p>
            </h2>
            <p class="info-text">
              시작과 마무리 단계까지<br />
              최고의 효과를 만듭니다.
            </p>
          </div>
          <button>더 알아보기</button>
        </div>
      </article>
    </div>
  </div>
</main>
