<!--스킵네비-->

<!-- <div id="skipNav">
     <a  [routerLink]="#content">본문 바로가기</a>
     <a  [routerLink]="#gnb">글로벌 네비게이션 바로가기</a>
   </div> -->

<header class="header_wrap"
  id="header">
  <h1><a (click)="goHome()">케이엔큐레이션</a></h1>
  <nav class="gnb_box"
    id="gnb">
    <ul>
      <li>
        <button>
          <a (click)="goHome()">홈</a>
        </button>
      </li>
      <li>
        <button>
          <a (click)="goService()"> 업무 서비스 </a>
        </button>
      </li>
      <li>
        <button>
          <a (click)="goPortfolio()">포트폴리오</a>
        </button>
      </li>
      <!-- <li>
        <button>
          <a  [routerLink]="/simple-estimate"> 간편 견적 내기</a>
        </button>
      </li> -->
      <li>
        <button class="easy-quot">
          <a (click)="goInquiry()">문의하기</a>
        </button>
      </li>
    </ul>
  </nav>
</header>

<header class="m_header_wrap"
  id="mHeader">
  <h1><a (click)="goHome()">케이엔큐레이션</a></h1>
  <nav class="gnb_box"
    id="gnb">
    <div>
      <ul class="m-menu">
        <li><a (click)="goHome()">홈</a></li>
        <li class="service-list">
          <a class="m-service">업무 서비스</a>
          <ul class="work-list">
            <li>
              <a (click)="goplan()">UX 기획 & 전략</a>
            </li>
            <li>
              <a (click)="godesign()">UI 디자인</a>
            </li>
            <li>
              <a (click)="godevelopment()">Android/iOS 개발</a>
            </li>
            <li>
              <a (click)="gomarketing()">마케팅</a>
            </li>
          </ul>
        </li>
        <li><a (click)="goInquiry()">문의하기</a></li>
        <li>
          <a (click)="goPortfolio()">포트폴리오</a>
        </li>
        <!-- <li><a class="easy-quot"  [routerLink]="/simple-estimate">간편 견적 내기</a></li> -->
      </ul>
    </div>
  </nav>
</header>
