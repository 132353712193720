<!-- <header-share></header-share> -->
<main id="main">
  <!-- <section class="main-bg">
    <div class="main-text">
      <h2>문의하기</h2>
      <p>우리는 스타트업에게 가장 필요한 것이 무엇인지 잘 알고 있습니다.</p>
      <p>지금 바로 문의하세요.</p>
    </div>
    <div class="scroll-icon">
      <p>SCROLL</p>
    </div>
  </section> -->
  <div class="wrap">
    <section class="complete w1180 cf" *ngIf="checkFooter()">
      <h2 class="title">
        문의하기
        <p>
          문의 주셔서 감사합니다.
          <span class="block"
            >담당자가 확인 후 바로
            <span class="bold">메일 및 전화로 연락</span> 드리도록
            하겠습니다.</span
          >
        </p>
      </h2>
      <div class="next-process">
        <p class="how">다음은 어떻게 진행 되나요?</p>
        <ul>
          <li class="text">
            <h3>목표에 대해 논의</h3>
            <p>
              문의 확인 후 목표와 계획을 명확히 이해하기 위해 통화를 준비합니다.
            </p>
          </li>
          <li class="icon">
            <img src="assets/imgs/inquiry_next_icon.png" alt="화살표아이콘" />
          </li>
          <li class="text deep-bg">
            <h3>미팅 진행</h3>
            <p>일정 및 예산 견적과 함께 솔루션이 포함된 견적서를 작성합니다.</p>
          </li>
          <li class="icon">
            <img src="assets/imgs/inquiry_next_icon.png" alt="화살표아이콘" />
          </li>
          <li class="text">
            <h3>프로젝트 시작</h3>
            <p>
              계약이 성사되면,
              <span class="block">본격적인 앱 설계를 시작합니다.</span>
            </p>
          </li>
        </ul>
      </div>
      <button class="go-home">
        <a [routerLink]="['/home']">홈으로 돌아가기</a>
      </button>
    </section>
  </div>
  <footer-share [ngClass]="{ 'not-active': !checkFooter() }"></footer-share>
</main>
