import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-design-test',
  templateUrl: './design-test.component.html',
  styleUrls: ['./design-test.component.scss']
})
export class DesignTestComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
