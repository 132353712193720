<!-- <header-share></header-share> -->
<main id="main">
  <section id="top-banner">
    <div class="video-inner">
      <video autoplay
        loop
        width="100%"
        height="100%"
        poster="assets/imgs/service-m01.png">
        <source src="assets/video/header_bg_plan.mp4"
          type="video/mp4" />
      </video>
    </div>
    <div class="top-text">
      <h2>UX 기획 & 전략</h2>
      <p>우리는 사용자에 대한 이해와 공감으로 부터 시작합니다.</p>
    </div>
    <div class="scroll-icon">
      <p>SCROLL</p>
    </div>
  </section>
  <!-- // #top-banner -->

  <section id="main-cont">
    <div class="main-container">
      <div class="banner-wrap">
        <article class="cont-banner">
          <div class="banner-inner">
            <div class="cont-wrap">
              <h3>모든 서비스의 뼈대, UX기획</h3>
              <p>
                사용자가 원하고 필요로 하는 부분을 찾아 내는 것이 바로 UX기획
                입니다. <span class="br"></span> 비즈니스 본연의 가치를 빠르게
                느끼고 사용 할 수 있도록 기획 한다면<span class="br tablet"></span>
                높은 성과를 만들어 낼 수 있습니다.
              </p>
            </div>
          </div>
        </article>
        <!-- // .cont-banner -->
      </div>

      <article class="plan-step">
        <h3>오직,<span class="br"></span> 사용자를 위한 기획</h3>
        <p class="sub-descript">
          우리는 비즈니스 모델을 바탕으로, 사용자가
          <span class="exbold">어떻게 받아들이고, 무엇을 느끼며, 왜 반응하는가를</span>
          우선적으로 고민 합니다.
        </p>
        <ul class="step-list">
          <li class="step-item">
            <p class="number">01</p>
            <h4>사용자 이해</h4>
            <p class="division-line"></p>
            <p class="step-text">
              사용자들에 대한 생각, 감정, 행동 전반을 생각 합니다.
            </p>
          </li>
          <li class="step-item">
            <p class="number">02</p>
            <h4>사용자 분석</h4>
            <p class="division-line"></p>
            <p class="step-text">
              사용자 요구와 선호도를 파악 후 서비스 시장에 적합성을 높이는
              절차를 시행 합니다.
            </p>
          </li>
          <li class="step-item">
            <p class="number">03</p>
            <h4>서비스 설계</h4>
            <p class="division-line"></p>
            <p class="step-text">
              사용자들에게 최적의 경험을 제공하는 시스템 개발 단계를 진행
              합니다.
            </p>
          </li>
          <li class="step-item">
            <p class="number">04</p>
            <h4>서비스 구현</h4>
            <p class="division-line"></p>
            <p class="step-text">
              환경과 기술 트렌드를 파악하여 클라이언트와 사용자가 만족하는
              서비스를 구현 합니다.
            </p>
          </li>
        </ul>
        <p class="sub-descript mobile">
          서비스와 사용자가 <span class="exbold">더 쉽게 연결</span> 되고,
          모두에게 <span class="exbold">긍정적인 경험</span>을 얻는 결과로
          <span class="br"></span>나타날 것 입니다.
        </p>
        <div class="list-two-wrap">
          <ul>
            <li>
              <p class="number">01</p>
              <h4>니즈 발견</h4>
              <p class="division-line"></p>
              <p class="step-text">
                필요로 하는 목적 달성,<br />서비스와 정보 제공
              </p>
            </li>
            <li>
              <p class="number">02</p>
              <h4>문제 해결</h4>
              <p class="division-line"></p>
              <p class="step-text">
                반복적인 불편함을 발견,<br />원활한 경험 제공
              </p>
            </li>
            <li>
              <p class="number">03</p>
              <h4>이익/가치 상승</h4>
              <p class="division-line"></p>
              <p class="step-text">서비스의 이익과<br />서비스의 가치 상승</p>
            </li>
          </ul>
        </div>
      </article>
      <!-- // .plan-step -->

      <article class="strategy-cont">
        <h3>
          쉽게, 편리하게, 차별화 되게<span class="br"></span>
          성공하는 앱 서비스를 위한 우리의 전략
        </h3>
        <ul class="strategy-list">
          <li class="list-item plan-item">
            <p class="item-icon"></p>
            <h4>일정 계획</h4>
            <p class="item-text">
              속도와 완성도를 고려한<span class="br"></span>체계적인 일정을
              산출합니다.
            </p>
            <div class="detail-list">
              <p>- 예산 측정</p>
              <p>- 프로젝트 일정표</p>
            </div>
            <p class="plus-icon"></p>
            <p class="close-btn"></p>
          </li>
          <li class="list-item collection-item">
            <p class="item-icon"></p>
            <h4>자료 수집</h4>
            <p class="item-text">
              서비스의 필요한 모든 자료를<span class="br"></span>수집하여
              분석하고 파악합니다.
            </p>
            <div class="detail-list">
              <p>- 사전 질의서</p>
              <p>- 요구사항 정의서</p>
              <p>- 기능 정의서</p>
            </div>
            <p class="plus-icon"></p>
            <p class="close-btn"></p>
          </li>
          <li class="list-item research-item">
            <p class="item-icon"></p>
            <h4>UX 리서치</h4>
            <p class="item-text">
              인터뷰, 시장/ 경쟁사 분석을 통한<span class="br"></span>리서치로
              차별화된 전략을 제시합니다.
            </p>
            <div class="detail-list">
              <p>- 환경 분석</p>
              <p>- 벤치 마킹</p>
              <p>- 페르소나 기법</p>
              <p>- 스토리 보드</p>
            </div>
            <p class="plus-icon"></p>
            <p class="close-btn"></p>
          </li>
          <li class="list-item evaluation-item">
            <p class="item-icon"></p>
            <h4>사용성 평가</h4>
            <p class="item-text">
              구현 되기 전 초기 상태에서<span class="br"></span>끊임없이
              사용성을 테스트합니다.
            </p>
            <div class="detail-list">
              <p>- 프로토 타이핑</p>
            </div>
            <p class="plus-icon"></p>
            <p class="close-btn"></p>
          </li>
          <li class="list-item design-item">
            <p class="item-icon"></p>
            <h4>픽셀 최적화 UI디자인</h4>
            <p class="item-text">
              불필요 하거나 상관없는 정보는<span class="br"></span>제거하고
              심플한 디자인을 구현합니다.
            </p>
            <div class="detail-list">
              <p>- 무드 보드</p>
              <p>- 디자인 템플릿</p>
            </div>
            <p class="plus-icon"></p>
            <p class="close-btn"></p>
          </li>
          <li class="list-item ux-item">
            <p class="item-icon"></p>
            <h4>UX화면 설계</h4>
            <p class="item-text">
              확정된 UX화면을 바탕으로<span class="br"></span>구체적인 기능
              정의에 들어갑니다.
            </p>
            <div class="detail-list">
              <p>- 정보 구조도</p>
              <p>- 플로우 차트</p>
              <p>- 화면 설계서</p>
            </div>
            <p class="plus-icon"></p>
            <p class="close-btn"></p>
          </li>
          <li class="list-item avatar-item">
            <p class="item-icon"></p>
            <h4>기능/기술 구현</h4>
            <p class="item-text">
              아이디어를 중심으로 서비스에<span class="br"></span>맞춤화된
              기능과 기술을 제안합니다.
            </p>
            <div class="detail-list">
              <p>- DB설계</p>
            </div>
            <p class="plus-icon"></p>
            <p class="close-btn"></p>
          </li>
          <li class="list-item test-item">
            <p class="item-icon"></p>
            <h4>작업 테스트</h4>
            <p class="item-text">
              기획에 맞게 잘 구현이 되었는지 질문지를<span class="br"></span>작성하여 내부 테스트를 진행합니다.
            </p>
            <div class="detail-list">
              <p>- QA문서</p>
            </div>
            <p class="plus-icon"></p>
            <p class="close-btn"></p>
          </li>
        </ul>
        <!-- // .strategy-list -->

        <div class="sub-cont">
          <div class="cont-item">
            <div class="cont-title">원활한 소통</div>
            <div class="cont-text">
              클라이언트와 지속적으로 피드백을 요청하며 적극적으로 소통하며,
              함께 고민하고, 함께 협력합니다.
            </div>
          </div>
          <div class="cont-item">
            <div class="cont-title">통합 관리 시스템</div>
            <div class="cont-text">
              데이터 통합관리 시스템을 통해 개별로 들어가는 리소스 비용을
              절약하고 고객의 데이터를 한번에 관리할 수 있습니다.
            </div>
          </div>
          <div class="cont-item">
            <div class="cont-title">개인 정보 보호</div>
            <div class="cont-text">
              깊이 있는 아이디어 이기에, 개인 정보 보호를 최우선으로 생각합니다.
            </div>
          </div>
        </div>
        <!-- // .sub-cont -->
      </article>
      <!-- // .strategy-cont -->

      <article class="last-cont">
        <h3>
          우리의 전략을 통한 결과는<span class="br"></span>성공적일 것 입니다.
        </h3>
        <div class="last-cont-list">
          <div class="cont-item -service active">
            <div class="cont-inner">
              <div class="text-wrap">
                <p class="cont-title">
                  사용자를 위한, <span class="br tablet"></span>사용자가 중심인
                  서비스
                </p>
                <p class="cont-text">
                  서비스에 적용하는 선순환 구조를 이어가며
                  <span class="br tablet"></span>지속적으로
                  <span class="br pc"></span>
                  <span class="exbold">앱의 품질과 사용자가 동시에 증가하는
                    <span class="br tablet"></span>서비스를 만들 수
                    있습니다.</span>
                </p>
              </div>
            </div>
            <div class="img-box"></div>
          </div>
          <div class="cont-item -design">
            <div class="cont-inner">
              <div class="text-wrap">
                <p class="cont-title">
                  세련된 디자인, <span class="br tablet"></span>높은 호감도
                </p>
                <p class="cont-text">
                  “성공한 앱은 세련된 디자인을 가지고 있다”
                  <span class="br pc"></span>
                  앱의
                  <span class="exbold">사용성에 대해 고민한 흔적은 UX뿐만 아니라
                    <span class="br tablet"></span>UI에서도 세련 되게
                    나타납니다.</span>
                </p>
              </div>
            </div>
            <div class="img-box"></div>
          </div>
          <div class="cont-item -function">
            <div class="cont-inner">
              <div class="text-wrap">
                <p class="cont-title">
                  앱을 돋보이게 하는 <span class="br tablet"></span>대표 기능
                </p>
                <p class="cont-text">
                  자신의 서비스의 맞춘 앱에서만 제공하는, 우리의
                  <span class="br tablet"></span>기술력이 더해진
                  <span class="br pc"></span>
                  <span class="exbold">사용자들의 관심이나 만족도가 높은
                    <span class="br tablet"></span>특별한 기능 및 콘텐츠를
                    제공합니다.</span>
                </p>
              </div>
            </div>
            <div class="img-box"></div>
          </div>
          <div class="cont-item -support">
            <div class="cont-inner">
              <div class="text-wrap">
                <p class="cont-title">다수의 OS, 단말 지원</p>
                <p class="cont-text">
                  데이터 통합관리 시스템을 통해 개별로 들어가는
                  <span class="br tablet"></span>리소스 비용을 절약하고
                  <span class="br pc"></span>
                  <span class="exbold">고객이 무엇을 원하는지 더
                    <span class="br tablet"></span>빠르고 정확하게 알 수
                    있습니다.
                  </span>
                </p>
              </div>
            </div>
            <div class="img-box"></div>
            <div class="img-box back-img"></div>
          </div>
        </div>
      </article>
      <!-- // .last-cont -->
    </div>
  </section>

  <section class="bottom-line">
    <div class="bottom-box">
      <h2>케이엔큐레이션</h2>
      <p>우리의 단계는 목표에 더 가까이 다가갑니다.</p>
    </div>
  </section>
  <!-- // .bottom-line -->
  <!-- <sticky-button></sticky-button> -->
</main>
<footer-share class="mobile-footer"></footer-share>

<!-- <div id="footer-plan">
  <div class="footer-inner">
    <div class="btns-wrap">
      <div class="btns-inner">
        <button class="prev-btn"><a href="/marketing"></a>마케팅</button>
        <p>더 많은 프로세스를 만나보세요.</p>
        <button class="next-btn"><a href="/design"></a>UI 디자인</button>
      </div>
    </div>

    <footer-share></footer-share>
  </div>
</div> -->
<img src="assets/imgs/plan_01.gif"
  alt=""
  class="load" />
<img src="assets/imgs/plan_02.gif"
  alt=""
  class="load" />
<img src="assets/imgs/plan_03.gif"
  alt=""
  class="load" />
<img src="assets/imgs/plan_04.gif"
  alt=""
  class="load" />
