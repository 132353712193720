import { Component, OnDestroy, OnInit } from '@angular/core';
import * as $ from 'jquery';
import _ from 'lodash';
@Component({
  selector: 'app-development',
  templateUrl: './development.component.html',
  styleUrls: ['./development.component.scss'],
})
export class DevelopmentComponent implements OnInit, OnDestroy {
  myDevice = navigator.platform;

  constructor() {}

  ngOnInit(): void {
    $(function () {
      let windowWidth = $(window).width();
      const mainFooter = document.getElementById('mainFooter');

      $('header-share').addClass('main-scroll');

      //////창크기마다 바뀌는 이벤트
      function windowSize() {
        windowWidth = $(window).width();

        if (windowWidth > 1024) {
          $('.back-img-wrap .move-back-img1').attr(
            'src',
            'assets/imgs/dev-back-1.png'
          );
          $('.back-img-wrap .move-back-img2').attr(
            'src',
            'assets/imgs/dev-back-2.png'
          );
          $('.back-img-wrap .move-back-img3').attr(
            'src',
            'assets/imgs/dev-back-3.png'
          );
          $('.back-img-wrap .move-back-img4').attr(
            'src',
            'assets/imgs/dev-back-4.png'
          );
        }

        if (windowWidth <= 1023 && windowWidth > 479) {
          $('.back-img-wrap .move-back-img1').attr(
            'src',
            'assets/imgs/dev-back-t-1.png'
          );
          $('.back-img-wrap .move-back-img2').attr(
            'src',
            'assets/imgs/dev-back-t-2.png'
          );
          $('.back-img-wrap .move-back-img3').attr(
            'src',
            'assets/imgs/dev-back-t-3.png'
          );
          $('.back-img-wrap .move-back-img4').attr(
            'src',
            'assets/imgs/dev-back-t-4.png'
          );
        }

        if (windowWidth <= 479) {
          $('.back-img-wrap .move-back-img1').attr(
            'src',
            'assets/imgs/dev-back-m-1.png'
          );
          $('.back-img-wrap .move-back-img2').attr(
            'src',
            'assets/imgs/dev-back-m-2.png'
          );
          $('.back-img-wrap .move-back-img3').attr(
            'src',
            'assets/imgs/dev-back-m-3.png'
          );
          $('.back-img-wrap .move-back-img4').attr(
            'src',
            'assets/imgs/dev-back-m-4.png'
          );
        }
      }

      windowSize();

      let that = this;
      let filter = 'win16|win32|win64|mac|macintel';

      // main height(100%) resize
      let winHeight = $(window).height();

      if (that.myDevice == filter) {
        $(window).on('resize', _.debounce(windowSize));
      }

      setTimeout(() => {
        $('sticky-button > .sticky_wrap > p').addClass('text-ac');
      }, 500);

      $(mainFooter).css({ display: 'none' });

      //새로 고침 될때마다 영상 로드
      setTimeout(() => {
        let video = $('video').get(0);
        video.muted = true;
        video.play();
        video.loop = true;
      }, 150);

      let txtBox = [];
      let temp = [];
      function textAni() {
        let txtLine = [];
        $('.main-text p').each(function (index, item) {
          let txt = $(item).text();
          let tmp = { index: index, txt: txt };
          txtLine.push(tmp);
        });
        let splitTxt = [];
        txtLine.forEach((txt) => {
          splitTxt.push(txt.txt.split(' '));
        });
        $(splitTxt).each(function (index, item) {
          let txt = [];
          item.forEach((e) => {
            txt.push('<span>' + e + '</span>&nbsp;');
          });
          txtBox.push({ index: index, txt: txt });
        });
        for (var i = 0; i < txtBox.length; i++) {
          $('.main-text p').eq(i).html(txtBox[i].txt);
          $('.main-text p span').css({
            display: 'inline-block',
            opacity: 0,
            position: 'relative',
            top: '10px',
            left: '5px',
            fontSize: '1.17rem',
            lineHeight: '1.722rem',
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale',
          });
          $('.main-text p')
            .eq(i)
            .find('span')
            .each(function (i, item) {
              temp.push(item);
            });
        }
      }
      textAni();

      let txtBox2 = [];
      let temp2 = [];
      function textAni2() {
        let txtLine = [];
        let txt = $('.visual-wrap p').text();
        txtLine = [{ index: 0, txt: txt }];
        let splitTxt = [];

        splitTxt.push(txt.split(' '));

        $(splitTxt).each(function (index, item) {
          let txt = [];
          item.forEach((e) => {
            txt.push('<span>' + e + '</span>&nbsp;');
          });
          txtBox2.push({ index: 1, txt: txt });
        });
        for (var i = 0; i < txtBox2.length; i++) {
          $('.visual-wrap p').eq(i).html(txtBox2[i].txt);
          $('.visual-wrap p span').css({
            display: 'inline-block',
            opacity: 0,
            position: 'relative',
            top: '10px',
            left: '5px',
            fontSize: '1rem',
            lineHeight: '1.667rem',
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale',
          });
          $('.visual-wrap p')
            .eq(i)
            .find('span')
            .each(function (i, item) {
              temp2.push(item);
            });
        }
      }
      textAni2();

      let txtBox3 = [];
      let temp3 = [];
      function textAni3() {
        let txtLine = [];
        // $('.visual-wrap p').each(function (index, item) {
        //   let txt = $(item).text();
        //   let tmp = { index: index, txt: txt };
        //   txtLine.push(tmp);
        // });
        let txt = $('.quality-area .title-area p').text();
        txtLine = [{ index: 0, txt: txt }];
        let splitTxt = [];

        splitTxt.push(txt.split(' '));
        // txtLine.forEach((txt) => {
        //   splitTxt.push(txt.txt.split(' '));
        // });

        $(splitTxt).each(function (index, item) {
          let txt = [];
          item.forEach((e) => {
            txt.push('<span>' + e + '</span>&nbsp;');
          });
          txtBox3.push({ index: 1, txt: txt });
        });
        for (var i = 0; i < txtBox3.length; i++) {
          $('.quality-area .title-area p').eq(i).html(txtBox3[i].txt);
          $('.quality-area .title-area p span').css({
            display: 'inline-block',
            opacity: 0,
            position: 'relative',
            top: '10px',
            left: '5px',
            fontSize: '1rem',
            lineHeight: '1.667rem',
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale',
          });
          $('.quality-area .title-area p')
            .eq(i)
            .find('span')
            .each(function (i, item) {
              temp3.push(item);
            });
        }
      }
      textAni3();
      // 텍스트애니메이션01 실행
      setTimeout(() => {
        for (let index = 0; index < temp.length; index++) {
          const ele = temp[index];
          $('.main-text p')
            .eq(0)
            .animate({ opacity: 1 })
            .find($(ele))
            .delay(50 * index)
            .animate({ top: 0, opacity: 1, left: 0 }, 500);
        }
      }, 200);

      ///스크롤 이벤트

      let mainScrollPosition = $('#main').scrollTop() || 0;
      let visualTop = $('.visual-wrap').offset().top;
      let qualityTop = $('.quality-area').offset().top;
      let esingFn = 'all cubic-bezier(0, 0, 0.09, 1.18) 0.3s';

      //고정값
      let sec2Postion =
        $('.scroll-section2').offset().top +
        $('.scroll-section2').height() +
        450 -
        662;

      let footerLine =
        $('.bottom-line').offset().top + $('.bottom-line').height();
      let bottomLine =
        $('.bottom-line').offset().top + $('.bottom-line').height() - 10;

      let footerTop = $('.bottom-line').offset().top - 100;
      let footerOpen = false;
      let scrollSec2Top = $('.scroll-section2').offset().top;

      let stickySwitch = false;

      //스크롤 이벤트
      // $('#main').scroll(function () {

      // function scollEvt() {
      //   windowWidth = $(window).width();

      let changeGif1 = false;
      let changeGif2 = false;
      let changeGif3 = false;
      let changeGif4 = false;

      let mChangeGif1 = false;
      let mChangeGif2 = false;
      let mChangeGif3 = false;
      let mChangeGif4 = false;

      let windowHeight = $(window).height();

      function checkHeight(height) {
        if (windowWidth < 768) {
          if (windowHeight > 1020) {
            return height * 1;
          } else if (windowHeight <= 1020 && windowHeight > 850) {
            return height * 0.9;
          } else if (windowHeight <= 850) {
            return height * 0.8;
          }
        }
      }

      $('#main').scroll(function () {
        let scrollTop = $('#main').scrollTop();

        $(this).css({
          'scroll-behavior': 'smooth',
        });

        //스크롤 위아래 방향
        let mainDirection = scrollTop - mainScrollPosition >= 0 ? 1 : -1;
        mainScrollPosition = scrollTop; // Update scrollY

        let scrollBottom = scrollTop + $(window).height();
        //스크롤 되는동안의 비주얼탑 (-값)
        let scrollVisualTop = $('.visual-wrap').offset().top;
        //한 박스당 크기 + margin-bottom + stiky 영역인 title 값
        let boxHeight = $('.scroll-box').height() + 250 + 279;

        // let translateValue = visualTop > -700 ? 700 + visualTop : 0;
        let wrapScroll = Math.max(Math.min(400, 400 + scrollVisualTop), 0);

        let mainScrollDonw = Math.max(Math.min(visualTop, scrollVisualTop), 1);

        ///첫번째 섹션 스크롤 이벤트
        let firstScrollDonw = Math.max(
          Math.min(visualTop, scrollVisualTop + 100),
          1
        );
        let firstScrollDonwScale = Math.min(
          Math.max(0, scrollVisualTop * -0.001),
          1
        );

        let firstScroll = Math.max(
          Math.min(400, 400 + scrollVisualTop + 100),
          0
        );

        ///--첫번째 섹션 스크롤 이벤트 끝

        //스크롤 내려갈때
        // if (mainDirection == 1) {

        if (scrollBottom >= visualTop + $('.visual-wrap').height() + 100) {
          for (let index = 0; index < temp2.length; index++) {
            const ele = temp2[index];

            if (
              String($(ele).text()) == '구현해' ||
              String($(ele).text()) == '줄' ||
              String($(ele).text()) == '수' ||
              String($(ele).text()) == '있는' ||
              String($(ele).text()) == '준비가'
            ) {
              $(ele).css({ fontWeight: '800' });
            }
            $('.visual-wrap p')
              .find($(ele))
              .delay(50 * index)
              .animate({ top: 0, opacity: 1, left: 0 }, 500);
          }
        }

        if (windowWidth > 767) {
          if (scrollTop >= visualTop) {
            if (firstScrollDonwScale > 0.8) {
              if (!changeGif1) {
                $('.first-box .inner-area img').attr(
                  'src',
                  'assets/imgs/develop_01.gif'
                );
              }

              changeGif1 = true;
            }

            $('.main-text-area').css({
              transform: 'translateY(' + wrapScroll + 'px)',
              opacity: mainScrollDonw,
            });
          }

          // if (wrapScroll <= 0) {
          $('.scroll-box').css({
            transform: 'translateY(' + firstScroll + 'px)',
            opacity: firstScrollDonw,
            transition: esingFn,
          });

          $('.scroll-box .phone-img-area').css({
            opacity: firstScrollDonw,
            transform: 'scale(' + firstScrollDonwScale + ')',
            transition: esingFn,
          });
          $('.first-box .inner-area img').delay(50).css({
            opacity: firstScrollDonwScale,
            transition: esingFn,
          });
          $('.first-box .text-box')
            .delay(200)
            .css({
              transform: 'translateY(' + wrapScroll + 'px)',
              opacity: firstScrollDonwScale,
              transition: esingFn,
            });

          let secondUp = Math.min((scrollBottom - 4389) * 0.002, 1);
          let secondDown = Math.max((4389 - scrollBottom) * 0.002, 0);
          let secondTop = Math.min(
            Math.max((4389 - scrollBottom) * 0.4, 0),
            50
          );

          if (scrollBottom >= 4150) {
            //3200+950 - 시작점 + 1/4값

            if (!changeGif2) {
              $('.second-box .inner-area img').attr(
                'src',
                'assets/imgs/develop_02.gif'
              );
            }
            changeGif2 = true;

            $('.first-box').css({
              opacity: secondDown,
              transition: esingFn,
            });
            $('.second-box').css({
              transition: esingFn,
            });

            $('.second-box .text-box').css({
              opacity: secondUp,
              transform: 'translateY(' + secondTop + 'px)',
              transition: esingFn,
            });
            $('.second-box .inner-area img').css({
              opacity: secondUp,
              transition: esingFn,
            });
          }

          let thirdUp = Math.min((scrollBottom - 5862) * 0.002, 1);
          let thirdDown = Math.max((5862 - scrollBottom) * 0.002, 0);
          let thirdTop = Math.min(Math.max((5862 - scrollBottom) * 0.4, 0), 50);

          if (scrollBottom >= 5623) {
            if (!changeGif3) {
              $('.third-box .inner-area img').attr(
                'src',
                'assets/imgs/develop_03.gif'
              );
            }

            changeGif3 = true;

            $('.second-box').css({
              opacity: thirdDown,
              transition: esingFn,
            });
            $('.third-box').css({
              transition: esingFn,
            });

            $('.third-box .text-box').css({
              opacity: thirdUp,
              transform: 'translateY(' + thirdTop + 'px)',
              transition: esingFn,
            });
            $('.third-box .code-area')
              .css({
                opacity: thirdUp,
                transform: 'translateY(' + thirdTop + 'px)',
                transition: esingFn,
              })
              .find(
                $(
                  '.code-css .code-content code .line-1, .code-css .code-content code .line-2, .code-css .code-content code .line-3, .code-css .code-content code .line-4, .code-css .code-content code .line-5, .code-css .code-content code .line-6'
                )
              )
              .addClass('ac');

            $('.third-box .inner-area img').css({
              opacity: thirdUp,
              transition: esingFn,
            });
          }

          let fourthUp = Math.min((scrollBottom - 7312) * 0.002, 1);
          let fourthDown = Math.max((7312 - scrollBottom) * 0.002, 0);
          let fourthTop = Math.min(
            Math.max((7312 - scrollBottom) * 0.4, 0),
            50
          );

          if (scrollBottom >= 7073) {
            if (!changeGif4) {
              $('.fourth-box .inner-area img').attr(
                'src',
                'assets/imgs/develop_04.gif'
              );
            }

            changeGif4 = true;

            $('.third-box').css({
              opacity: fourthDown,
              transition: esingFn,
            });
            $('.fourth-box').css({
              transition: esingFn,
            });

            $('.fourth-box .text-box').css({
              opacity: fourthUp,
              transform: 'translateY(' + fourthTop + 'px)',
              transition: esingFn,
            });
            $('.fourth-box .code-area')
              .css({
                opacity: fourthUp,
                transform: 'translateY(' + fourthTop + 'px)',
                transition: esingFn,
              })
              .find(
                $(
                  '.code-js .code-content code .line-js-1, .code-js .code-content code .line-js-2, .code-js .code-content code .line-js-3, .code-js .code-content code .line-js-4, .code-js .code-content code .line-js-5, .code-js .code-content code .line-js-6, .code-js .code-content code .line-js-7, .code-js .code-content code .line-js-8, .code-js .code-content code .line-js-9, .code-js .code-content code .line-js-10, .code-js .code-content code .line-js-11, .code-js .code-content code .line-js-12'
                )
              )
              .addClass('ac');

            $('.fourth-box .inner-area img').css({
              opacity: fourthUp,
              transition: esingFn,
            });
          }

          if (mainDirection == -1 && scrollBottom < 6973) {
            $(
              '.code-js .code-content code .line-js-1, .code-js .code-content code .line-js-2, .code-js .code-content code .line-js-3, .code-js .code-content code .line-js-4, .code-js .code-content code .line-js-5, .code-js .code-content code .line-js-6, .code-js .code-content code .line-js-7, .code-js .code-content code .line-js-8, .code-js .code-content code .line-js-9, .code-js .code-content code .line-js-10, .code-js .code-content code .line-js-11, .code-js .code-content code .line-js-12'
            ).removeClass('ac');
          }
          if (mainDirection == -1 && scrollBottom < 5823) {
            $(
              '.code-css .code-content code .line-1, .code-css .code-content code .line-2, .code-css .code-content code .line-3, .code-css .code-content code .line-4, .code-css .code-content code .line-5, .code-css .code-content code .line-6'
            ).removeClass('ac');
          }
        } else if (windowWidth <= 767 && windowWidth > 479) {
          ///////첫번째 스크롤 영역 모바일 버전
          ///////첫번째 스크롤 영역 모바일 버전

          if (scrollTop >= visualTop && scrollBottom < 6517) {
            $('.scroll-box .text-box').eq(2).css({ zIndex: 0 });
            $('.scroll-box .text-box').eq(3).css({ zIndex: 0 });
            $('.scroll-box .text-box').eq(4).css({ zIndex: 0 });
            $(
              '.second-box .text-box h3, .second-box .text-box p, .third-box .text-box h3, .third-box .text-box p, .fourth-box .text-box h3, .fourth-box .text-box p'
            ).removeClass('on');

            if (!mChangeGif1) {
              $('.first-box .inner-area img').attr(
                'src',
                'assets/imgs/develop_01.gif'
              );
            }

            mChangeGif1 = true;

            $('.scroll-box .text-box')
              .addClass('acBox')
              .removeClass('off-box')
              .eq(1)
              .css({ zIndex: 9999 });

            $('.first-box .text-box h3, .first-box .text-box p').addClass('on');

            if (scrollBottom > visualTop && scrollBottom >= 3637) {
              $('.scroll-box .text-box').eq(1).css({ zIndex: 0 });
              $('.scroll-box .text-box').eq(3).css({ zIndex: 0 });
              $('.scroll-box .text-box').eq(4).css({ zIndex: 0 });
              $(
                '.first-box .text-box h3, .first-box .text-box p, .third-box .text-box h3, .third-box .text-box p, .fourth-box .text-box h3, .fourth-box .text-box p'
              ).removeClass('on');

              if (!mChangeGif2) {
                $('.second-box .inner-area img').attr(
                  'src',
                  'assets/imgs/develop_02.gif'
                );
              }

              mChangeGif2 = true;

              // $('.scroll-box .text-box').eq(1).css({ zIndex: 0 });
              // $('.first-box .text-box h3, .first-box .text-box p').removeClass(
              //   'on'
              // );

              $('.scroll-box .text-box')
                .eq(2)
                .css({ zIndex: 9999 })
                .find('h3, p')
                .addClass('on');

              $('.second-box .text-box h3, .second-box .text-box p').addClass(
                'on'
              );
            }
            if (scrollBottom > 3995 && scrollBottom >= 4743) {
              $('.scroll-box .text-box').eq(2).css({ zIndex: 0 });
              $('.scroll-box .text-box').eq(1).css({ zIndex: 0 });
              $('.scroll-box .text-box').eq(4).css({ zIndex: 0 });
              $(
                '.first-box .text-box h3, .first-box .text-box p, .second-box .text-box h3, .second-box .text-box p, .fourth-box .text-box h3, .fourth-box .text-box p'
              ).removeClass('on');

              if (!mChangeGif3) {
                $('.third-box .inner-area img').attr(
                  'src',
                  'assets/imgs/develop_03.gif'
                );
              }

              mChangeGif3 = true;

              // $('.scroll-box .text-box').eq(2).css({ zIndex: 0 });
              // $(
              //   '.second-box .text-box h3, .second-box .text-box p'
              // ).removeClass('on');

              $('.scroll-box .text-box')
                .eq(3)
                .css({ zIndex: 9999 })
                .find('h3, p')
                .addClass('on');

              $('.third-box .text-box h3, .third-box .text-box p').addClass(
                'on'
              );
            }

            if (scrollBottom > 5065 && scrollBottom >= 5813) {
              $('.scroll-box .text-box').eq(2).css({ zIndex: 0 });
              $('.scroll-box .text-box').eq(3).css({ zIndex: 0 });
              $('.scroll-box .text-box').eq(1).css({ zIndex: 0 });
              $(
                '.first-box .text-box h3, .first-box .text-box p, .second-box .text-box h3, .second-box .text-box p, .third-box .text-box h3, .third-box .text-box p'
              ).removeClass('on');

              // $('.scroll-box .text-box').eq(3).css({ zIndex: 0 });
              // $('.third-box .text-box h3, .third-box .text-box p').removeClass(
              //   'on'
              // );

              if (!mChangeGif4) {
                $('.fourth-box .inner-area img').attr(
                  'src',
                  'assets/imgs/develop_04.gif'
                );
              }

              mChangeGif4 = true;

              $('.scroll-box .text-box')
                .eq(4)
                .css({ zIndex: 9999 })
                .find('h3, p')
                .addClass('on');

              $('.fourth-box .text-box h3, .fourth-box .text-box p').addClass(
                'on'
              );
            }
          } else if (scrollTop >= visualTop && scrollBottom >= 6517) {
            $('.scroll-box .text-box').addClass('off-box').removeClass('acBox');
          } else if (scrollTop < visualTop && mainDirection == -1) {
            $('.scroll-box .text-box')
              .removeClass('acBox')
              .removeClass('off-box');
          }
        } else if (windowWidth <= 479) {
          ///////첫번째 스크롤 영역 모바일 버전
          ///////첫번째 스크롤 영역 모바일 버전

          if (
            scrollTop >= visualTop &&
            scrollBottom > checkHeight(2900) &&
            scrollBottom < checkHeight(7073)
          ) {
            $('.scroll-box .text-box').eq(2).css({ zIndex: 0 });
            $('.scroll-box .text-box').eq(3).css({ zIndex: 0 });
            $('.scroll-box .text-box').eq(4).css({ zIndex: 0 });
            $(
              '.second-box .text-box h3, .second-box .text-box p, .third-box .text-box h3, .third-box .text-box p, .fourth-box .text-box h3, .fourth-box .text-box p'
            ).removeClass('on');

            if (!mChangeGif1) {
              $('.first-box .inner-area img').attr(
                'src',
                'assets/imgs/develop_01.gif'
              );
            }

            mChangeGif1 = true;

            $('.scroll-box .text-box')
              .addClass('acBox')
              .removeClass('off-box')
              .eq(1)
              .css({ zIndex: 9999 });

            $('.first-box .text-box h3, .first-box .text-box p').addClass('on');

            if (
              scrollBottom > checkHeight(2900) &&
              scrollBottom >= checkHeight(4200)
            ) {
              $('.scroll-box .text-box').eq(1).css({ zIndex: 0 });
              $('.scroll-box .text-box').eq(3).css({ zIndex: 0 });
              $('.scroll-box .text-box').eq(4).css({ zIndex: 0 });
              $(
                '.first-box .text-box h3, .first-box .text-box p, .third-box .text-box h3, .third-box .text-box p, .fourth-box .text-box h3, .fourth-box .text-box p'
              ).removeClass('on');

              if (!mChangeGif2) {
                $('.second-box .inner-area img').attr(
                  'src',
                  'assets/imgs/develop_02.gif'
                );
              }

              mChangeGif2 = true;

              // $('.scroll-box .text-box').eq(1).css({ zIndex: 0 });
              // $('.first-box .text-box h3, .first-box .text-box p').removeClass(
              //   'on'
              // );

              $('.scroll-box .text-box')
                .eq(2)
                .css({ zIndex: 9999 })
                .find('h3, p')
                .addClass('on');

              $('.second-box .text-box h3, .second-box .text-box p').addClass(
                'on'
              );
            }
            if (
              scrollBottom > checkHeight(4200) &&
              scrollBottom >= checkHeight(5200)
            ) {
              $('.scroll-box .text-box').eq(2).css({ zIndex: 0 });
              $('.scroll-box .text-box').eq(1).css({ zIndex: 0 });
              $('.scroll-box .text-box').eq(4).css({ zIndex: 0 });
              $(
                '.first-box .text-box h3, .first-box .text-box p, .second-box .text-box h3, .second-box .text-box p, .fourth-box .text-box h3, .fourth-box .text-box p'
              ).removeClass('on');

              if (!mChangeGif3) {
                $('.third-box .inner-area img').attr(
                  'src',
                  'assets/imgs/develop_03.gif'
                );
              }

              mChangeGif3 = true;

              // $('.scroll-box .text-box').eq(2).css({ zIndex: 0 });
              // $(
              //   '.second-box .text-box h3, .second-box .text-box p'
              // ).removeClass('on');

              $('.scroll-box .text-box')
                .eq(3)
                .css({ zIndex: 9999 })
                .find('h3, p')
                .addClass('on');

              $('.third-box .text-box h3, .third-box .text-box p').addClass(
                'on'
              );
            }

            if (
              scrollBottom > checkHeight(5200) &&
              scrollBottom >= checkHeight(6200)
            ) {
              $('.scroll-box .text-box').eq(2).css({ zIndex: 0 });
              $('.scroll-box .text-box').eq(3).css({ zIndex: 0 });
              $('.scroll-box .text-box').eq(1).css({ zIndex: 0 });
              $(
                '.first-box .text-box h3, .first-box .text-box p, .second-box .text-box h3, .second-box .text-box p, .third-box .text-box h3, .third-box .text-box p'
              ).removeClass('on');

              // $('.scroll-box .text-box').eq(3).css({ zIndex: 0 });
              // $('.third-box .text-box h3, .third-box .text-box p').removeClass(
              //   'on'
              // );

              if (!mChangeGif4) {
                $('.fourth-box .inner-area img').attr(
                  'src',
                  'assets/imgs/develop_04.gif'
                );
              }

              mChangeGif4 = true;

              $('.scroll-box .text-box')
                .eq(4)
                .css({ zIndex: 9999 })
                .find('h3, p')
                .addClass('on');

              $('.fourth-box .text-box h3, .fourth-box .text-box p').addClass(
                'on'
              );
            }
          } else if (
            scrollBottom >= checkHeight(6200) &&
            scrollBottom >= checkHeight(6700)
          ) {
            $('.scroll-box .text-box').addClass('off-box').removeClass('acBox');
          } else if (scrollTop < visualTop && mainDirection == -1) {
            $('.scroll-box .text-box')
              .removeClass('acBox')
              .removeClass('off-box');
          }
        }
        // let secTransformDown = Math.min(
        //   Math.max(sec2Postion - scrollBottom, 0),
        //   686
        // );

        function resizeTransformDown() {
          if (windowWidth > 1024) {
            let secTransformDown = Math.min(
              Math.max(sec2Postion - scrollBottom, 0),
              636
            );

            return secTransformDown;
          } else if (windowWidth <= 1023 && windowWidth > 767) {
            let secTransformDown = Math.min(
              Math.max(sec2Postion - scrollBottom, 0),
              885
            );

            return secTransformDown;
          } else if (windowWidth <= 767 && windowWidth > 479) {
            let secTransformDown = Math.min(
              Math.max(sec2Postion - scrollBottom, 0),
              1055
            );

            return secTransformDown;
          } else {
            let sec2Postion =
              $('.scroll-section2').offset().top +
              $('.scroll-section2').height() +
              6000;
            let secTransformDown = Math.min(
              Math.max(sec2Postion - checkHeight(scrollBottom), 0),
              1125
            );

            return secTransformDown;
          }
        }

        let secTransformDown = resizeTransformDown();

        let sec2Top = Math.min(Math.max(10175 - scrollBottom, 0), 175);

        let sec2Top1 = Math.max(Math.min((10700 - scrollBottom) * 0.2, 0), -20);
        let sec2Top2 = Math.max(Math.min((10500 - scrollBottom) * 0.2, 0), -60);

        let sec2Down1 = Math.max(
          Math.min((12050 - scrollBottom) * 0.02, 20),
          0.4
        );
        let sec2Down2 = Math.max(
          Math.min((13150 - scrollBottom) * 0.01, 60),
          11
        );
        let sec2Down3 = Math.max(
          Math.min((10950 - scrollBottom) * 0.05, 0),
          -54
        );
        let sec2Down4 = Math.max(
          Math.min((11350 - scrollBottom) * 0.1, 0),
          -68
        );

        section2();
        function section2() {
          if (windowWidth > 479) {
            if (scrollBottom >= scrollSec2Top) {
              $('.scroll-section2 .sec2-title').css({
                willChange: 'transform',
                transform: 'matrix(1, 0, 0, 1, 0, -' + secTransformDown + ')',
                transition: 'transform cubic-bezier(0.24, 0.6, 1, 1)',
              });

              $('.scroll-section2').css({
                willChange: 'transform',
                // transform: 'translateY(-' + sec2Top + 'px)',
                transform: ' matrix(1, 0, 0, 1, 0, ' + sec2Top + ')',
                transition: 'transform cubic-bezier(0.23, 0.52, 1, 1) 0.2s',
              });

              $('.scroll-section2 .back-img-wrap .move-back-img1').css({
                willChange: 'transform',
                transform: 'matrix(1, 0, 0, 1, 0, ' + -sec2Top1 + ')',
                transition: 'transform cubic-bezier(0.07, 0.85, 1, 1) 0.2s',
                transformOrigin: 'center top',
              });
              $('.scroll-section2 .back-img-wrap .move-back-img2').css({
                willChange: 'transform',
                transform: 'matrix(1, 0, 0, 1, 0, ' + -sec2Top2 + ')',
                transition: 'transform cubic-bezier(0.07, 0.85, 1, 1) 0.2s',
                transformOrigin: 'center top',
              });

              if (secTransformDown < 160) {
                $('.table-area').addClass('ac');
              } else {
                $('.table-area').removeClass('ac');
              }
            }

            if (scrollBottom >= 10850) {
              $('.scroll-section2 .back-img-wrap .move-back-img1').css({
                willChange: 'transform',
                transform: 'matrix(1, 0, 0, 1, 0, ' + sec2Down1 + ')',
                transition: 'transform cubic-bezier(0.07, 0.85, 1, 1) 0.2s',
                transformOrigin: 'center top',
              });
              $('.scroll-section2 .back-img-wrap .move-back-img2').css({
                willChange: 'transform',
                transform: 'matrix(1, 0, 0, 1, 0, ' + sec2Down2 + ')',
                transition: 'transform cubic-bezier(0.07, 0.85, 1, 1) 0.2s',
                transformOrigin: 'center top',
              });
              $('.scroll-section2 .back-img-wrap .move-back-img3').css({
                willChange: 'transform',
                transform: 'matrix(1, 0, 0, 1, 0, ' + sec2Down3 + ')',
                transition: 'transform cubic-bezier(0.07, 0.85, 1, 1) 0.2s',
                transformOrigin: 'center top',
              });
              $('.scroll-section2 .back-img-wrap .move-back-img4').css({
                willChange: 'transform',
                transform: 'matrix(1, 0, 0, 1, 0, ' + sec2Down4 + ')',
                transition: 'transform cubic-bezier(0.07, 0.85, 1, 1) 0.2s',
                transformOrigin: 'center top',
              });

              $(mainFooter).css({ display: 'block' });
            }
          } else {
            const field = $('.field-box');

            console.log('scrollSec2Top', scrollSec2Top);
            console.log('scrollBottom', scrollBottom);

            if (scrollBottom >= scrollSec2Top) {
              console.log('????dtd??');

              $('.scroll-section2 .sec2-title').css({
                willChange: 'transform',
                transform: 'matrix(1, 0, 0, 1, 0, -' + secTransformDown + ')',
                transition: 'transform cubic-bezier(0.24, 0.6, 1, 1)',
              });

              $('.scroll-section2').css({
                willChange: 'transform',
                // transform: 'translateY(-' + sec2Top + 'px)',
                transform: ' matrix(1, 0, 0, 1, 0, ' + sec2Top + ')',
                transition: 'transform cubic-bezier(0.23, 0.52, 1, 1) 0.2s',
              });

              $('.scroll-section2 .back-img-wrap .move-back-img1').css({
                willChange: 'transform',
                transform: 'matrix(1, 0, 0, 1, 0, ' + -sec2Top1 + ')',
                transition: 'transform cubic-bezier(0.07, 0.85, 1, 1) 0.2s',
                transformOrigin: 'center top',
              });
              $('.scroll-section2 .back-img-wrap .move-back-img2').css({
                willChange: 'transform',
                transform: 'matrix(1, 0, 0, 1, 0, ' + -sec2Top2 + ')',
                transition: 'transform cubic-bezier(0.07, 0.85, 1, 1) 0.2s',
                transformOrigin: 'center top',
              });

              if (secTransformDown < 160) {
                $('.table-area').addClass('ac');
              } else {
                $('.table-area').removeClass('ac');
              }
            }

            if (scrollBottom >= 10850) {
              $('.scroll-section2 .back-img-wrap .move-back-img1').css({
                willChange: 'transform',
                transform: 'matrix(1, 0, 0, 1, 0, ' + sec2Down1 + ')',
                transition: 'transform cubic-bezier(0.07, 0.85, 1, 1) 0.2s',
                transformOrigin: 'center top',
              });
              $('.scroll-section2 .back-img-wrap .move-back-img2').css({
                willChange: 'transform',
                transform: 'matrix(1, 0, 0, 1, 0, ' + sec2Down2 + ')',
                transition: 'transform cubic-bezier(0.07, 0.85, 1, 1) 0.2s',
                transformOrigin: 'center top',
              });
              $('.scroll-section2 .back-img-wrap .move-back-img3').css({
                willChange: 'transform',
                transform: 'matrix(1, 0, 0, 1, 0, ' + sec2Down3 + ')',
                transition: 'transform cubic-bezier(0.07, 0.85, 1, 1) 0.2s',
                transformOrigin: 'center top',
              });
              $('.scroll-section2 .back-img-wrap .move-back-img4').css({
                willChange: 'transform',
                transform: 'matrix(1, 0, 0, 1, 0, ' + sec2Down4 + ')',
                transition: 'transform cubic-bezier(0.07, 0.85, 1, 1) 0.2s',
                transformOrigin: 'center top',
              });

              $(mainFooter).css({ display: 'block' });
            }
          }
        }

        // }
        // } else {
        //   $('.main-text-area').css({
        //     transform: 'translateY(' + wrapScroll + 'px)',
        //   });
        // }

        footer();
        function footer() {
          let windowWidth = $(window).width();
          if (windowWidth > 768) {
            if (scrollBottom >= bottomLine) {
              if (!footerOpen) {
                $('.inner-wrap').animate({ top: -$('footer').height() }, 1000);
              }
              footerOpen = true;
            } else {
              if (scrollBottom < footerTop - 300) {
                if (footerOpen) {
                  $('.inner-wrap').animate({ top: 0 }, 500);
                  $(mainFooter).css({ position: 'relative' });
                }
              }
            }
          } else {
            footerOpen = false;
            $(mainFooter).css({
              position: 'relative',
              bottom: 'unset',
              top: 0,
            });
          }
        }

        if (that.myDevice == filter) {
          $(window).on('resize', _.debounce(footer, checkHeight, scroll));
        }

        if (
          scrollBottom >=
          qualityTop + $('.quality-area').height() / 2 + 100
        ) {
          for (let index = 0; index < temp3.length; index++) {
            const ele = temp3[index];

            if (
              String($(ele).text()) == '끊임없이' ||
              String($(ele).text()) == '체크' ||
              String($(ele).text()) == '하며' ||
              String($(ele).text()) == '보완해' ||
              String($(ele).text()) == '완성도' ||
              (!temp3[6] && String($(ele).text()) == '있는') ||
              String($(ele).text()) == '서비스'
            ) {
              $(ele).css({ fontWeight: '800' });
            }
            $('.quality-area .title-area p')
              .find($(ele))
              .delay(50 * index)
              .animate({ top: 0, opacity: 1, left: 0 }, 500);
          }
        }

        if (scrollBottom >= footerLine && !stickySwitch) {
          // if () {
          // if (windowWidth > 1024) {
          $('sticky-button')
            .css({ position: 'absolute' })
            .stop()
            .animate({ bottom: $('footer').height() + 40 }, 200, 'linear');
          // }
          stickySwitch = true;
          // }
        } else if (scrollBottom < footerLine && stickySwitch) {
          // if (stickySwitch) {
          $('sticky-button')
            .css({ position: 'fixed' })
            .stop()
            .animate({ bottom: 40 }, 200);
          // }
          stickySwitch = false;
        }
      });
    });

    $(function () {
      $('#mainFooter')
        .find('.top_btn')
        .on('click', function (e) {
          $('#main').animate(
            {
              scrollTop: 0,
            },
            1000
          );

          //올라간 후에 리로드
          setTimeout(() => {
            location.reload();
          }, 950);

          return false;
        });
    });
  }

  ngOnDestroy(): void {
    $(window).scroll().off();
  }
}

//3189 - 7000
