import { Component, OnDestroy, OnInit } from '@angular/core';
import Swiper from 'swiper';
import * as $ from 'jquery';
import SwiperCore, { Navigation } from 'swiper/core';
import { DbService } from 'src/service/db.service';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import _ from 'lodash';

SwiperCore.use([Navigation]);

@Component({
  selector: 'app-portfolio-main',
  templateUrl: './portfolio-main.component.html',
  styleUrls: ['./portfolio-main.component.scss'],
})
export class PortfolioMainComponent implements OnInit, OnDestroy {
  leftItem;
  rightItem;

  constructor(private db: DbService, private router: Router) {
    this.getData().then((data) => {
      let main = data
        .filter((data) => data && data.mainPick == true)

        .sort(function (a, b) {
          return a.mainIndex - b.mainIndex;
        });

      let list = data
        .filter((data) => data && data.mainPick != true)

        .sort(function (a, b) {
          return b.startDate - a.startDate;
        });

      this.leftItem = main[0];
      this.rightItem = list[list?.length - 1];
    });
  }
  slideSwitch = false;

  ngOnInit(): void {
    this.slideCheck();

    const swiper02 = new Swiper('.study-desire .swiper-container', {
      slidesPerView: 'auto',
      freeMode: true,
      spaceBetween: 30,
    });

    // 새로고침 시 스크롤 초기화
    window.onload = function () {
      setTimeout(function () {
        scrollTo(0, 0);
      }, 10);
    };

    $(function () {
      $('header-share').addClass('main-scroll');

      let winWidth = $(window).width();
      let winHeight = $(window).height();
      // 창크기 조절 시 자동 (새로고침)resize 발생

      // 스크롤 이벤트 시작
      let flowSectionTop = $('.user-flow').offset().top,
        flowSectionBottom = flowSectionTop + $('.user-flow').height();
      let uxdesignTop = $('.ux-design').offset().top,
        uxBot = uxdesignTop + $('.ux-design').outerHeight();

      let footerOpen = false;

      $('#main').scroll(function (e) {
        let sTop = $('#main').scrollTop(),
          sBottom = sTop + $(window).height();

        // user-flow : 영상시청 플로우 차트
        if (sTop + 200 > flowSectionTop && sBottom > flowSectionBottom) {
          $('.user-flow').addClass('active');
        } else {
          $('.user-flow').removeClass('active');
        }

        // ux-design : 이탈없는 탄탄한 UX설계
        let uxDesign = $('.ux-design');
        let uxTop = Math.max(Math.min(4800 - sBottom, 500), 0);
        let uxOpacity = Math.min((sBottom - 4900) * 0.001, 1);
        let uxHideText = $('.title-wrap.hide'),
          uxShowText = $('.title-wrap.show');
        // 컨텐츠 top과 bottom 내부에서 모든 애니메이션이 이뤄져야 함
        // sTop >= uxdesignTop - 500 && sBottom < uxBot

        // 처음 스크롤 내리면서 컨텐츠 올라오도록
        // mobile(767px)부터는 이미지도 양옆에서 날아오기
        if (sTop >= uxdesignTop - 500 && sBottom < uxBot) {
          uxDesign.css({
            willChange: 'transform',
            transform: 'translateY(' + uxTop + 'px)',
            opacity: uxOpacity,
          });
          $('ul.off').addClass('move').css({ transition: '2s ease' });
        } else {
          $('ul.off').removeClass('move').css({ transition: '2s ease' });
        }

        // 애니메이션 실행
        let opacityUp = Math.min((sBottom - 5000) * 0.0003, 1);
        let opacityDown = Math.max((10000 - sBottom) * 0.001, 0);
        if (sTop >= uxdesignTop + 200) {
          if (opacityDown) {
            uxHideText.addClass('animation');
            uxShowText.css({ opacity: 1 }).addClass('animation');
            $('.imgs-wrap').addClass('active');
            $('ul.off').css({ opacity: 0, transition: '2.4s ease 2s' });
            $('ul.on').css({ opacity: 1, transition: '2.4s ease 2s' });
          }
        } else if (sTop < uxdesignTop - 200) {
          if (opacityUp) {
            uxShowText.css({ opacity: 1 }).removeClass('animation');
            uxHideText.removeClass('animation');
            $('.imgs-wrap').removeClass('active').addClass('transition');
            $('ul.off').css({
              opacity: 1,
              transitionDelay: '0s',
            });
            $('ul.on').css({
              opacity: 0,
              transutionDuration: '0.6s',
              transitionDelay: '0s',
            });
          }
        }

        // reliable-features : 서비스에 최적화된 안정적인 기능
        let $featureList = $('.reliable-features li'),
          $featureImg = $featureList.find('.img'),
          $featureText = $featureList.find('.txt-wrap');
        $('.reliable-features').each(function (e) {
          for (let i = 0; i < $featureList.length; i++) {
            let listTop = $featureList.eq(i).offset().top + sTop;
            if (winWidth > 767) {
              if (sTop > listTop - 700) {
                $featureImg.eq(i).addClass('active');
                $featureText.eq(i).addClass('active');
              } else {
                $featureImg.eq(i).removeClass('active');
                $featureText.eq(i).removeClass('active');
              }
            }
            if (winWidth < 768) {
              if (sTop > listTop - 1000) {
                $featureImg.eq(i).addClass('active');
                $featureText.eq(i).addClass('active');
              } else {
                $featureImg.eq(i).removeClass('active');
                $featureText.eq(i).removeClass('active');
              }
            }
          }
        });

        // effective-marketing
        let effectiveTop = $('.effective-marketing').offset().top + sTop;
        if (sTop > effectiveTop - 600) {
          $('.effective-marketing').addClass('active');
        } else {
          $('.effective-marketing').removeClass('active');
        }

        // footerOpen

        let portfolio = $('#portfolio-footer'),
          footerInner = $('.footer-inner'),
          footer = $('#mainFooter footer');
        let portfolioFooterHeight = portfolio.outerHeight();
        let btmBtnTop = $('.bottom-btn').offset().top + sTop,
          btmBtnBot = btmBtnTop + $('.bottom-btn').height();
        // footer.addClass('white').css({ position: 'static' });
        // portfolio.css({ marginTop: `${-portfolioFooterHeight}px` });

        footerAnim();
        function footerAnim() {
          let windowWidth = $(window).width();
          if (windowWidth > 768) {
            if (sBottom >= btmBtnBot) {
              if (!footerOpen) {
                $('.inner-all-wrap').animate(
                  { top: -$('footer').height() },
                  1000
                );
              }
              footerOpen = true;
            } else if (sBottom < btmBtnBot) {
              if (sBottom < btmBtnBot - 300) {
                if (footerOpen) {
                  $('.inner-all-wrap').animate({ top: 0 }, 500);
                  $('#mainFooter').css({ position: 'relative' });
                }
              }
            }
          } else {
            footerOpen = false;
            $('#mainFooter').css({ position: 'relative' });
          }
        }
        $(window).on('resize', _.debounce(footerAnim));

        // sticky
        let sticky = $('sticky-button'),
          stickyBtn = $('sticky-button p');
        if (sTop > 10) {
          stickyBtn.addClass('text-ac');
        }
        if (winWidth >= 768) {
          if (sBottom >= btmBtnTop) {
            sticky.css({
              position: 'absolute',
              zIndex: '100',
            });
          } else {
            sticky.css({
              position: 'fixed',
              bottom: '40px',
            });
          }
        } else if (winWidth > 479) {
          if (sBottom >= btmBtnTop) {
            sticky.css({
              position: 'absolute',
              zIndex: '100',
              bottom: '170px',
            });
          } else {
            sticky.css({
              position: 'fixed',
              bottom: '40px',
            });
          }
        } else {
          if (sBottom >= btmBtnTop) {
            sticky.css({
              position: 'absolute',
              zIndex: '100',
              bottom: '300px',
            });
          } else {
            sticky.css({
              position: 'fixed',
              bottom: '40px',
            });
          }
        }
      });
      // $('#main').trigger('scroll');
      $(window).trigger('resize');

      // scrollTop btn
      // $('footer-share')
      //   .find('.top_btn')
      //   .on('click', function (e) {
      //     $('#main').animate({ scrollTop: 0 }, 500);
      //     return false;
      //   });
    });
    // jQuery end
  }

  ngOnDestroy(): void {
    $(window).scroll().off();
  }

  getData(): Promise<any> {
    return this.db.collection$(`portfolio`).pipe(first()).toPromise();
  }

  slideCheck() {
    let that = this;
    let innerWidth = window.innerWidth;

    if (innerWidth < 1180 && innerWidth >= 1022) {
      that.slideSwitch = true;

      const swiper = new Swiper('.appintro-wrap .swiper-container', {
        slidesPerView: 'auto',
        spaceBetween: 20,
        freeMode: true,
        navigation: {
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
        },
      });
    } else {
      that.slideSwitch = false;
      const swiper = new Swiper('.appintro-wrap .swiper-container', {
        slidesPerView: 'auto',
        spaceBetween: 20,
        freeMode: true,
      });
    }

    window.onresize = function (event) {
      let innerWidth = window.innerWidth;

      if (innerWidth < 1180 && innerWidth >= 1022) {
        that.slideSwitch = true;

        const swiper = new Swiper('.appintro-wrap .swiper-container', {
          slidesPerView: 'auto',
          spaceBetween: 20,
          freeMode: true,
          navigation: {
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next',
          },
        });
      } else {
        that.slideSwitch = false;
        const swiper = new Swiper('.appintro-wrap .swiper-container', {
          slidesPerView: 'auto',
          spaceBetween: 20,
          freeMode: true,
        });
      }
      // let innerWidth = window.innerWidth;
      // innerWidth < 1180
      //   ? (this.slideSwitch = true)
      //   : (this.slideSwitch = false);
    };

    // if (windowWidth < 1180) {

    //   this.slideSwitch = true;
    // } else {

    //   this.slideSwitch = false;
    // }
  }

  goHome() {
    this.router.navigateByUrl('/').then(() => {
      window.location.reload();
    });
  }
  goService() {
    this.router.navigateByUrl('/service-menu').then(() => {
      window.location.reload();
    });
  }
  goPortfolio() {
    this.router.navigateByUrl('/portfolio').then(() => {
      window.location.reload();
    });
  }
  goInquiry() {
    this.router.navigateByUrl('/inquiry').then(() => {
      window.location.reload();
    });
  }
  goplan() {
    this.router.navigateByUrl('/plan').then(() => {
      window.location.reload();
    });
  }
  godesign() {
    this.router.navigateByUrl('/design').then(() => {
      window.location.reload();
    });
  }
  godevelopment() {
    this.router.navigateByUrl('/development').then(() => {
      window.location.reload();
    });
  }
  gomarketing() {
    this.router.navigateByUrl('/marketing').then(() => {
      window.location.reload();
    });
  }
  goHanra() {
    this.router.navigateByUrl('/portfolio-hanra').then(() => {
      window.location.reload();
    });
  }

  goPfDetail(id) {
    this.router.navigateByUrl(`/portfolio-detail/${id}`).then(() => {
      window.location.reload();
    });
  }
}
