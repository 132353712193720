import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface DialogData {
  username: string;
  company: string;
  project: string;
}

@Component({
  selector: 'app-estimate-pop',
  templateUrl: './estimate-pop.component.html',
  styleUrls: ['./estimate-pop.component.scss'],
})
export class EstimatePopComponent implements OnInit {
  userInfo = {
    username: '',
    company: '',
    project: '',
  };

  nameSwitch = false;
  companySwitch = false;
  projectSwitch = false;

  allComplete = true;

  // constructor(public dialogRef: MatDialogRef<EstimatePopComponent>) {}
  constructor(
    public dialogRef: MatDialogRef<EstimatePopComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    if (this.data) {
      this.userInfo = this.data;
    }
  }

  ngOnInit(): void {}

  // checkInput() {

  // }

  closePop() {
    this.dialogRef.close();
  }

  checkInfo() {
    if (this.userInfo.username) {
      this.nameSwitch = false;
    }
    if (this.userInfo.company) {
      this.companySwitch = false;
    }
    if (this.userInfo.project) {
      this.projectSwitch = false;
    }

    if (!this.nameSwitch && !this.companySwitch && !this.projectSwitch) {
      this.allComplete = true;
    }
  }

  savaUserInfo() {
    if (!this.userInfo.username) {
      this.nameSwitch = true;
      this.allComplete = false;
    }
    if (!this.userInfo.company) {
      this.companySwitch = true;
      this.allComplete = false;
    }
    if (!this.userInfo.project) {
      this.projectSwitch = true;
      this.allComplete = false;
    }

    if (
      this.userInfo.username &&
      this.userInfo.company &&
      this.userInfo.project
    ) {
      this.done(this.userInfo);
    }
  }

  done(user) {
    this.dialogRef.close(user);
  }
}
