import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-inquiry-complete',
  templateUrl: './inquiry-complete.component.html',
  styleUrls: ['./inquiry-complete.component.scss'],
})
export class InquiryCompleteComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    // this.footerOpen();

    // 새로고침 시 스크롤 초기화
    window.onload = function () {
      setTimeout(function () {
        scrollTo(0, 0);
      }, 10);
    };

    $(function () {
      let winHeight = $(window).height();
      // $(window).resize(function () {
      //   $('.main-bg').height(winHeight);
      // });
      // $(window).trigger('resize');

      // 창크기 조절 시 자동 (새로고침)resize 발생
      // window.onresize = function () {
      //   location.reload();
      // };

      // ******************** 텍스트애니메이션 ******************** //
      // let txtBox = [];
      // let temp = [];
      // function textAni() {
      //   let txtLine = [];
      //   $('.main-text p').each(function (index, item) {
      //     let txt = $(item).text();
      //     let tmp = { index: index, txt: txt };
      //     txtLine.push(tmp);
      //   });

      //   let splitTxt = [];
      //   txtLine.forEach((txt) => {
      //     splitTxt.push(txt.txt.split(' '));
      //   });

      //   $(splitTxt).each(function (index, item) {
      //     let txt = [];
      //     item.forEach((e) => {
      //       txt.push('<span>' + e + '</span>&nbsp;');
      //     });
      //     txtBox.push({ index: index, txt: txt });
      //   });
      //   for (var i = 0; i < txtBox.length; i++) {
      //     $('.main-text p').eq(i).html(txtBox[i].txt);
      //     $('.main-text p span').css({
      //       display: 'inline-block',
      //       opacity: 0,
      //       position: 'relative',
      //       top: '10px',
      //       left: '5px',
      //       fontSize: '1.17rem',
      //       lineHeight: '1.722rem',
      //       '-webkit-font-smoothing': 'antialiased',
      //       '-moz-osx-font-smoothing': 'grayscale',
      //     });
      //     $('.main-text p')
      //       .eq(i)
      //       .find('span')
      //       .each(function (i, item) {
      //         temp.push(item);
      //       });
      //   }
      // }
      // textAni();

      // 실행
      // setTimeout(() => {
      //   for (let i = 0; i < txtBox.length; i++) {
      //     for (let index = 0; index < temp.length; index++) {
      //       const ele = temp[index];
      //       $('.main-text p')
      //         .eq(i)
      //         .animate({ opacity: 1 })
      //         .find($(ele))
      //         .delay(50 * index)
      //         .animate({ top: 0, opacity: 1, left: 0 }, 500);
      //     }
      //   }
      // }, 200);
      // ******************** 텍스트애니메이션 끝 ******************** //

      // scrollTop btn
      // $('footer-share')
      //   .find('.top_btn')
      //   .on('click', function (e) {
      //     $('html,body').animate({ scrollTop: 0 }, 500);
      //     return false;
      //   });
    });
  }

  // footerOpen() {
  //   const footer = document.querySelector<HTMLElement>('footer-share footer');
  //   const inquiry = document.querySelector<HTMLElement>('#inquiry-footer');
  //   const footerInner = document.querySelector<HTMLElement>('.footer-inner');

  //   footer.classList.add('white');

  //   // footer.style.position = 'static';
  //   let inquiryFooterHeight = inquiry.clientHeight;
  //   inquiry.style.marginTop = `${-inquiryFooterHeight}px`;

  //   window.addEventListener('scroll', () => {
  //     let $btmLine = $('.bottom-line');
  //     let $btmLineTop = $btmLine.offset().top;
  //     let $btmLineHeight = $btmLine.outerHeight();
  //     let point = $btmLineTop + $btmLineHeight;
  //     let trigger = window.scrollY + window.innerHeight;

  //     if (point <= trigger + 10) {
  //       inquiry.classList.add('active');
  //       inquiry.style.marginTop = '0';
  //       setTimeout(() => {
  //         footerInner.style.zIndex = '100';
  //       }, 900);
  //     }
  //   });
  // }

  checkFooter() {
    let footer = document.getElementById('footer');
    if (footer.classList.contains('white')) {
      return true;
    } else {
      return false;
    }
  }
}
