import { BrowserModule, Meta } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { HeaderShareComponent } from './pages/share/header-share/header-share.component';
import { FooterShareComponent } from './pages/share/footer-share/footer-share.component';
import { SwiperModule } from 'swiper/angular';
import { StickyButtonComponent } from './pages/share/sticky-button/sticky-button.component';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import firebase from 'firebase';
firebase.initializeApp(environment.firebaseConfig);
import { PlanComponent } from './pages/plan/plan.component';
import { DesignComponent } from './pages/design/design.component';
import { MarketingComponent } from './pages/marketing/marketing.component';
import { ServiceMenuComponent } from './pages/service-menu/service-menu.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { SimpleEstimateComponent } from './pages/simple-estimate/simple-estimate.component';

import { NotFoundComponent } from './pages/not-found/not-found.component';
import { InquiryComponent } from './pages/inquiry/inquiry.component';
import { FormsModule } from '@angular/forms';
import { InquiryCompleteComponent } from './pages/inquiry-complete/inquiry-complete.component';
import { PortfolioDetailComponent } from './pages/portfolio-detail/portfolio-detail.component';
import { HttpClientModule } from '@angular/common/http';
import { PortfolioMainComponent } from './pages/portfolio-main/portfolio-main.component';
import { DevelopmentComponent } from './pages/development/development.component';
import { PortfolioComponent } from './pages/portfolio/portfolio.component';
import { EstimatePopComponent } from './pages/estimate-pop/estimate-pop.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DesignTestComponent } from './pages/design-test/design-test.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { TermsModalComponent } from './pages/terms-modal/terms-modal.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderShareComponent,
    FooterShareComponent,
    StickyButtonComponent,
    PlanComponent,
    DesignComponent,
    MarketingComponent,
    ServiceMenuComponent,
    SimpleEstimateComponent,
    NotFoundComponent,
    TermsModalComponent,
    InquiryComponent,
    InquiryCompleteComponent,
    PortfolioDetailComponent,
    PortfolioMainComponent,
    DevelopmentComponent,
    PortfolioComponent,
    EstimatePopComponent,
    DesignTestComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SwiperModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireStorageModule,
    AngularFirestoreModule,
    BrowserAnimationsModule,
    MatButtonToggleModule,
    MatCardModule,
    FormsModule,
    HttpClientModule,
    MatDialogModule,
    MatProgressSpinnerModule,
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    Meta,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
