/** @format */

import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';

import { DbService } from './db.service';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  webCategories = [];
  constructor(public db: DbService) {}

  //** 데이터 서비스 init **//
  async inIt() {}

  async getSolutionPrice(): Promise<any> {
    const SolutionPrice = this.db
      .collection$(`solutionPrice`, (ref) => ref.orderBy('order', 'asc'))
      .pipe(first())
      .toPromise();

    return SolutionPrice;
  }

  async getSolutionAppCategory(): Promise<any> {
    const SolutionAppCategories = this.db
      .collection$(`solutionAppCategories`, (ref) =>
        ref.orderBy('order', 'asc')
      )
      .pipe(first())
      .toPromise();

    return SolutionAppCategories;
  }

  async getWebCategory(): Promise<any> {
    const webCategories = this.db
      .collection$(`webCategories`, (ref) => ref.orderBy('order', 'asc'))
      .pipe(first())
      .toPromise();

    return webCategories;
  }

  async getAllSolutionOptionPrice(): Promise<any> {
    const SolutionPrice = this.db
      .collection$(`solutionOptionPrice`, (ref) => ref.orderBy('order', 'asc'))
      .pipe(first())
      .toPromise();

    return SolutionPrice;
  }

  async getSolutionQna(): Promise<any> {
    const SolutionQna = this.db
      .collection$(`solutionQna`, (ref) =>
        ref.where('displaySwitch', '==', true).orderBy('order', 'asc')
      )
      .pipe(first())
      .toPromise();

    return SolutionQna;
  }

  async getSolutionReview(): Promise<any> {
    const SolutionReview = this.db
      .collection$(`solutionReview`, (ref) =>
        ref.where('displaySwitch', '==', true).orderBy('order', 'asc')
      )
      .pipe(first())
      .toPromise();

    return SolutionReview;
  }

  async getSolutionAppById(appId: string): Promise<any> {
    const SolutionReview = this.db
      .doc$(`solutionApps/${appId}`)
      .pipe(first())
      .toPromise();

    return SolutionReview;
  }

  async getWebById(appId: string): Promise<any> {
    const solutionWeb = this.db
      .doc$(`solutionWebs/${appId}`)
      .pipe(first())
      .toPromise();
    return solutionWeb;
  }

  async getSolutionAppFunctionListByAppId(appId: string): Promise<any> {
    const SolutionReview = this.db
      .collection$(`solutionAppFunctionList`, (ref) =>
        ref.where('solutionAppId', '==', appId).orderBy('order', 'asc')
      )
      .pipe(first())
      .toPromise();

    return SolutionReview;
  }

  getWebCategoryTitleById(categoryId: string) {
    return this.webCategories.find((item) => item.id == categoryId)?.title;
  }
}
