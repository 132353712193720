<main id="main">
  <section class="main-video">
    <video
      autoplay
      muted
      loop
      width="100%"
      height="100%"
      poster="assets/imgs/service-m02.png"
    >
      <source src="assets/video/header_bg_design.mp4" type="video/mp4" />
    </video>
    <div class="main-text">
      <h2>UI 디자인</h2>
      <p>완벽한 디자인으로 디지털 솔루션에 의미와 무게를 더합니다.</p>
    </div>
    <div class="scroll-icon">
      <p>SCROLL</p>
    </div>
  </section>

  <section class="experience">
    <h2>가치 있는 경험을 디자인 하다.</h2>
    <p>
      사용자 습관, 경험, 체험으로 발전 하는 UX가 있다면
      <span class="bold"
        >사용자가 보다 쉽게 이해하고 사용할 수 있게 돕는 UI가 있습니다.</span
      >
    </p>

    <div class="bg-area">
      <p>UX: User Experience. 사용자 경험 안에</p>
      <p>UI: User Interface. 사용자 인터페이스가 속해있다.</p>
    </div>
  </section>

  <section class="business">
    <h2>좋은 UI란, 비즈니스 목적을 달성 하는 것</h2>
    <p>
      디자인은 단순히 예쁘다고만 해서 설득력을 가지는 것은 아닙니다. <br />
      <span class="bold"
        >비즈니스 목표를 정의하고, 사용자의 요구를 이해하고, 사용자가 수용할 수
        있는 의미있는 환경을</span
      >
      만드는 것부터 시작을 합니다.
    </p>

    <dl>
      <div>
        <dt>
          <span class="num">01</span>
          <p>직관성</p>
        </dt>

        <dd>
          누구나 쉽게 <br />
          이해할 수 있게
        </dd>
      </div>
      <div>
        <dt>
          <span class="num">02</span>
          <p>유효성</p>
        </dt>

        <dd>
          사용자의 목적을 정확하게<br />
          달성할 수 있게
        </dd>
      </div>
      <div>
        <dt>
          <span class="num">03</span>
          <p>학습성</p>
        </dt>

        <dd>
          누구나 쉽게 <br />
          배우고 익힐 수 있게
        </dd>
      </div>
      <div>
        <dt>
          <span class="num">04</span>
          <p>유연성</p>
        </dt>

        <dd>
          요구사항을 수용하며<br />
          오류가 최소화 될 수 있게
        </dd>
      </div>
    </dl>
  </section>

  <section class="system">
    <div class="top-area">
      <h2>일관된 경험을 제공하는 우리의 디자인 시스템 전략</h2>
      <p>
        우리의 시스템은 원칙, 지침, 표준 세 가지를 목표로 합니다. 크고 작은
        디자인 의사결정의 기준이 되며,<br />
        효율적으로 빠르게 디자인할 수 있도록 돕고, 일관성을 확보할 수 있도록
        합니다.
      </p>
    </div>

    <div class="system-wrap">
      <div class="system-txt">
        <div class="txt-box">
          <h3>
            <span>01</span>
            <p>시스템 정의</p>
          </h3>
          <p>
            사용자가 원하는 것이 무엇인지,<br />
            시스템의 목적이 무엇인지 명확히 정의 합니다.
          </p>
        </div>
        <div class="txt-box">
          <h3>
            <span>02</span>
            <p>시스템 설계</p>
          </h3>
          <p>
            흐름에 따른 정보 배치로 사용자가 쉽고,<br />
            편리하게 원하는 정보를 얻도록 설계 합니다.
          </p>
        </div>
        <div class="txt-box">
          <h3>
            <span>03</span>
            <p>시스템 테스트</p>
          </h3>
          <p>
            사용자가 경험하는 것을 테스트 하고,<br />
            반복적인 테스트를 진행 하며 검증 합니다.
          </p>
        </div>
      </div>
      <div class="system-view">
        <div class="view-box">
          <div class="view-area">
            <img src="../../../" alt="사용자 요구 중심 설명 이미지" />
          </div>

          <h3>사용자 요구 중심의 UI</h3>
        </div>

        <div class="view-box">
          <div class="view-area">
            <img src="../../../" alt="사용자 요구 중심 설명 이미지" />
          </div>
          <h3>무드 보드 만들기</h3>
          <p>
            목적에 맞는 시각적인 요소들을 모으는 작업을 진행 합니다.프로젝트에
            대한 디자인 컨셉을 확고히 알 수 있으며, 서비스의 따른 컨셉에
            다양함을 생각할 수 있습니다.
          </p>
        </div>
        <div class="view-box">
          <div class="view-area">
            <img src="../../../" alt="사용자 요구 중심 설명 이미지" />
          </div>
          <h3>UI 스타일 가이드 제작</h3>
          <p>
            실제 목업 설계 전 UI요소들을 정의 하고, 쉽게 이해할 수 있도록 컬러
            계획 타이포 그래피등 서비스와 어떻게 어울릴지에 대한 단계를 진행
            합니다.
          </p>
        </div>
        <div class="view-box">
          <div class="view-area">
            <img src="../../../" alt="사용자 요구 중심 설명 이미지" />
          </div>
          <h3>Android, iOS 기반 환경 디자인</h3>
          <p>
            <strong> “안드로이드는 자유롭고 , iOS 명확하다”</strong> 우리는
            사용자가 단말을 자연스럽게 사용하고, 중요한 콘텐츠에 집중하는 것을
            도와주기 위한 디자인 원칙을 따르고 있습니다. 가이드 라인에 따르게
            되면 새로운 앱도 친숙하게 사용할 수 있으며, 해당 디바이스에 최적화된
            경험을 제공 할 수 있습니다.
          </p>
        </div>
        <div class="view-box">
          <div class="view-area">
            <img src="../../../" alt="사용자 요구 중심 설명 이미지" />
          </div>
          <h3>
            구현 되기 전, 시스템에 맞게 테스트 시 전체의 성능을 향상 시키는
            역할을 할 수 있습니다.
          </h3>

          <ul>
            <li>사용자를 위한 다양한 접근 경로를 제공하고 있는가?</li>
            <li>
              사용자의 시선 및 작업의 흐름에 따라 진행 되도록 배치 되어 있는가?
            </li>
            <li>UX설계에 맞는 명확한 정보 구조를 이루고 있는가?</li>
            <li>자연스러운 페이지 이동을 유도하고 있는가?</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</main>
