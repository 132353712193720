import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DesignTestComponent } from './pages/design-test/design-test.component';

import { DesignComponent } from './pages/design/design.component';
import { DevelopmentComponent } from './pages/development/development.component';
import { HomeComponent } from './pages/home/home.component';
import { InquiryCompleteComponent } from './pages/inquiry-complete/inquiry-complete.component';
import { InquiryComponent } from './pages/inquiry/inquiry.component';
import { MarketingComponent } from './pages/marketing/marketing.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { PlanComponent } from './pages/plan/plan.component';
import { PortfolioDetailComponent } from './pages/portfolio-detail/portfolio-detail.component';
import { PortfolioMainComponent } from './pages/portfolio-main/portfolio-main.component';
import { PortfolioComponent } from './pages/portfolio/portfolio.component';
import { ServiceMenuComponent } from './pages/service-menu/service-menu.component';
import { SimpleEstimateComponent } from './pages/simple-estimate/simple-estimate.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },

  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'service-menu',
    component: ServiceMenuComponent,
  },
  {
    path: 'plan',
    component: PlanComponent,
  },
  {
    path: 'design',
    component: DesignComponent,
  },
  {
    path: 'marketing',
    component: MarketingComponent,
  },
  // {
  //   path: 'simple-estimate',
  //   component: SimpleEstimateComponent,
  // },
  // {
  //   path: 'simple-estimate/:id',
  //   component: SimpleEstimateComponent,
  // },

  {
    path: 'inquiry',
    component: InquiryComponent,
  },

  {
    path: 'inquiry-complete',
    component: InquiryCompleteComponent,
  },
  {
    path: 'portfolio',
    component: PortfolioComponent,
  },
  {
    path: 'portfolio-detail/:params',
    component: PortfolioDetailComponent,
  },
  {
    path: 'portfolio-hanra',
    component: PortfolioMainComponent,
  },
  {
    path: 'development',
    component: DevelopmentComponent,
  },
  {
    path: 'design-test',
    component: DesignTestComponent,
  },
  {
    //notfoundPage
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      useHash: true,
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
