<main id="main">
  <div class="wrap">
    <h1 class="logo"></h1>
    <div class="text-wrap">
      <p class="bold">
        요청하신 페이지를 <span class="block">찾을 수 없습니다.</span>
      </p>
      <p class="regular">
        페이지가 존재하지 않거나, 사용할 수 없는 페이지 입니다. <br />
        입력하신 주소가 정확한지 다시 한번 확인 해주시기 바랍니다.
      </p>
      <button class="go-home bolder">
        <a (click)="goHome()">홈으로 이동</a>
      </button>
    </div>
  </div>
</main>
