import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EstimateInfoService {
  requiredArr = [
    {
      mainTitle: '개발 OS',
      sub: '[중복 선택 가능]',
      osDetail: [
        {
          title: 'Android',
          price: 1000000,
          con: '안드로이드 운영체제의 앱',
          check: false,
        },
        {
          title: 'iOS',
          price: 1000000,
          con: '아이폰을 위한 아이오에스 운영 체제의 앱',
          check: false,
        },
        { title: 'Web', price: 1000000, con: '웹 페이지', check: false },
      ],
    },

    {
      mainTitle: '페이지 수',
      sub: '메인 페이지, 회원 가입, 이용 약관 등 모든 페이지를 계산해주세요.',
      pageDetail: [
        {
          title: '10 페이지 이내',
          price: 1000000,
          con: 'MVP 수준',
          check: false,
        },
        {
          title: '10 ~ 20 페이지',
          price: 1000000,
          con: '알파 버전 수준의 경우',
          check: false,
        },
        {
          title: '20 ~ 30 페이지',
          price: 1000000,
          con: '베타 버전 수준의 경우',
          check: false,
        },
        {
          title: '30 ~ 50 페이지',
          price: 1000000,
          con: '일반적인 정식 버전 수준의 경우',
          check: false,
        },
        {
          title: '50 페이지 이상',
          price: 1000000,
          con: '페이지가 많은 정식버전 및 복잡한 플랫폼의 경우',
          check: false,
        },
      ],
    },

    {
      mainTitle: '기획',
      sub: '화면설계서 없이 개발은 불가 합니다.',
      planDetail: [
        {
          title: '전체 기획 작업',
          price: 1000000,
          con: '프로젝트의 많은 의사결정을 지원해야 하는 경우',
          check: false,
        },
        {
          title: '부분적인 기획 지원',
          price: 1000000,
          con: '화면 설계서 제공 했지만 기획의 일부분이 확정되어 있지 않아 의사결정을 지원 및 대기해야 하는 경우',
          check: false,
        },
        {
          title: `고객사의 의사결정을 \n 대기하는 경우`,
          price: 1000000,
          con: '기획의 대부분이 확정되어 있지 않아 고객사에 의사 결정을 위해 개발을 중단하는 경우',
          check: false,
        },
        {
          title: `기능 / 정책 / UX / UI\n사전 정의 된 경우`,
          price: 1000000,
          con: '모든 기획이 확정되어 화면설계서 제공시 바로 개발 가능하며 비용 절감',
          check: false,
        },
      ],
    },

    {
      mainTitle: '디자인',
      sub: '메인 페이지, 회원 가입, 이용 약관 등 모든 페이지를 계산해주세요.',
      designDetail: [
        {
          title: '전체 디자인 작업',
          price: 1000000,
          con: '디자인 시안 작업 및 고객사 컨펌 후 퍼블리싱 / 서버개발 진행',
          check: false,
        },
        {
          title: '기존 개발 디자인 그대로 사용',
          price: 1000000,
          con: '디자인 작업 없이 기존에 개발된 앱의 디자인을 그대로 사용하는 경우',
          check: false,
        },
        {
          title: '고객사에서 디자인 제공',
          price: 1000000,
          con: '고객사측에서 디자인 파일을 제공하는 경우 개발을 위한 디자인 작업 비용 발생',
          check: false,
        },
      ],
    },
  ];

  additional = [
    {
      mainTitle: '관리자 기능 구현',
      sub: '어드민에 필요한 기능을 선택해주세요.',
      inputType: 'multiple',
      detail: [
        {
          title: '기본 조회/편집 기능',
          price: 1000000,
          con: '주요 데이터에 대한 조회 / 편집 / 삭제 / 검색 / 정렬 기능',
          check: false,
        },
        {
          title: '주문 / 배송 / 결제 관리 기능',
          price: 1000000,
          con: '쇼핑몰을 포함한 모든 상거래에서 주문 / 배송 / 결제 기능은 개발 및 연결되어야 하기 때문에 기본적으로 구현 추가',
          check: false,
        },
        {
          title: '통계 기능',
          price: 1000000,
          con: '월간 / 주간 / 일간 통계 (통계 종류가 많아지면 비용 추가)',
          check: false,
        },
        {
          title: '정산 기능',
          price: 1000000,
          con: '결제된 내역을 판매자들에게 각각 정산해야 하는 경우 월간으로 정산 금액을 계산 / 표시하는 기능',
          check: false,
        },
        {
          title: '엑셀 임포트 기능',
          price: 1000000,
          con: '한 번에 많은 데이터를 엑셀을 통해 어드민으로 업로드 하는 기능',
          check: false,
        },
        {
          title: '엑셀 익스포트 기능',
          price: 1000000,
          con: '데이터베이스에 있는 데이터를 열람하기 편한 형태로 보기 위해 엑셀로 다운로드 하는 기능',
          check: false,
        },
        {
          title: '어드민 권한 설정',
          price: 1000000,
          con: '어드민 이용자별로 메뉴 접근 / 편집 권한을 분리해야 하는 경우',
          check: false,
        },
      ],
    },

    {
      mainTitle: '편집/ 업로드 기능',
      sub: '위지윅에디터 / 이미지 / 문서 / 동영상 등의 첨부 기능',
      inputType: 'multiple',
      detail: [
        {
          title: '위지윅 에디터',
          price: 1000000,
          con: '어드민의 텍스트 입력창에 위지윅 에디터 추가',
          check: false,
        },
        {
          title: '이미지 첨부',
          price: 1000000,
          con: '이미지 파일 업로드 기능',
          check: false,
        },
        {
          title: '문서 첨부',
          price: 1000000,
          con: '문서 파일 업로드 기능',
          check: false,
        },
        {
          title: '유튜브 / 비메오 영상 임베드',
          price: 1000000,
          con: '기존 영상 솔루션의 비디오를 임베드만 하는 경우 비용 절감 가능',
          check: false,
        },
        {
          title: '음성 / 영상 업로드 및 스트리밍',
          price: 1000000,
          con: '음성 / 영상 업로드시 서버 설정, 스토리지 세팅 등이 필요하고 트래픽 비용 크게 증가',
          check: false,
        },
        {
          title: '음성 / 영상을 서버 인코딩',
          price: 1000000,
          con: '음성 / 영상의 압축 및 다양한 환경에서 재생하기 위해 서버 인코딩 과정을 거쳐야 하는 경우',
          check: false,
        },
        {
          title: '태그 자동완성',
          price: 1000000,
          con: '해시태그 등의 자동완성 기능, 자주 입력하는 순서대로 표시 등의 기능',
          check: false,
        },
        {
          title: '그래프 표시',
          price: 1000000,
          con: '바차트, 파이차트, 라인차트 등의 그래프 표시 기능 (그래프 종류가 많아지면 비용 추가)',
          check: false,
        },
      ],
    },
    {
      mainTitle: 'O2O 기능',
      sub: '온라인 견적 산출, 단계별 의뢰 요청, 전문가 비딩 등 복잡한 기능',
      inputType: 'multiple',
      detail: [
        {
          title: '온라인 견적 산출',
          price: 1000000,
          con: '사용자가 여러 항목을 선택해 견적을 산출하는 기능 (어드민에서 항목 편집 가능)',
          check: false,
        },
        {
          title: '단계별 의뢰 요청',
          price: 1000000,
          con: 'O2O 서비스에서 카테고리, 지역, 날짜 등 순차적으로 입력해 의뢰 요청하는 기능',
          check: false,
        },
        {
          title: '전문가 비딩 제안',
          price: 1000000,
          con: 'O2O 서비스에서 전문가가 의뢰 요청에 가격 / 조건으로 비딩 제안하는 기능',
          check: false,
        },
        {
          title: '질문 / 답변 기능',
          price: 1000000,
          con: '지식인, 스택오버플로우 같은 질문, 답변 기능 (포인트/등급이 들어가는 경우 비용 증가)',
          check: false,
        },
        {
          title: '전문가 대시보드',
          price: 1000000,
          con: 'O2O 서비스에서 전문 서비스 제공자가 상품을 등록하고, 구매내역을 확인하는 대시보드 (종류가 많아지면 비용 추가)',
          check: false,
        },
      ],
    },

    {
      mainTitle: '보안 기능',
      sub: 'SQL 인젝션 공격이나 XSS 해킹 등 주요 해킹 공격에 대해서는 기본적인 보안을 갖추고 있습니다.',
      inputType: 'multiple',
      detail: [
        {
          title: 'HTTPS 적용',
          price: 1000000,
          con: '개인정보를 입력받는 경우 필수적으로 HTTPS 셋업',
          check: false,
        },
        {
          title: '민감한 정보의 암호화',
          price: 1000000,
          con: '사용자의 민감한 개인정보를 암호화하여 저장하는 경우',
          check: false,
        },
      ],
    },

    {
      mainTitle: '캘린더 기능',
      sub: '화면을 채우는 캘린더 상에서 강의 등을 예약 / 스케쥴링 하는 기능',
      inputType: 'multiple',
      detail: [
        {
          title: '캘린더 상에 일정 단순 표시',
          price: 1000000,
          con: '일정 편집기능 없이 입력한 일정을 표시만 하는 경우 캘린더 UI 구현 필요',
          check: false,
        },
        {
          title: '캘린더 상에서 일정 등록하기',
          price: 1000000,
          con: '캘린더 상에서 날짜 / 시간대를 클릭해서 일정을 등록하는 경우 팝업 등의 복잡도에 따라 비용 추가 가능',
          check: false,
        },
        {
          title: '캘린더 상에서 드래그로 일정 변경',
          price: 1000000,
          con: '등록된 일정을 캘린더 상에서 드래그 / 드롭으로 이동이 가능해야 하는 경우',
          check: false,
        },
        {
          title: '예약 가능 / 불가능일을 관리자가 편집',
          price: 1000000,
          con: '특정 판매자의 예약 가능 시간을 관리자가 어드민에서 편집하는 경우',
          check: false,
        },
        {
          title: '예약 가능 / 불가능 시간을 판매자가 편집',
          price: 1000000,
          con: '판매자 자신이 구매자가 예약할 수 있는 시간을 직접 등록하고 편집하는 경우',
          check: false,
        },
      ],
    },

    {
      mainTitle: '회원가입 / 로그인',
      sub: '이메일 회원가입 /  로그인 구현이 가장 빠르게 구현이 가능합니다.',
      inputType: 'multiple',
      detail: [
        {
          title: '이메일 가입 / 로그인',
          price: 1000000,
          con: '빠르게 구현이 가능 이메일을 통한 비밀번호 재설정 등의 기능 포함',
          check: false,
        },
        {
          title: '이메일 인증',
          price: 1000000,
          con: '회원가입한 이메일로 확인 링크가 발송된 후 해당 링크를 클릭해야 로그인이 가능',
          check: false,
        },
        {
          title: '휴대폰 인증',
          price: 1000000,
          con: '회원가입 페이지에서 휴대폰 인증을 수행해야 가입이 진행되도록 하는 경우',
          check: false,
        },
        {
          title: '페이스북 로그인',
          price: 1000000,
          con: '앱에서 로그인 시 페이스북 계정을 입력하지 않아도 페이스북 앱을 통해 로그인 가능',
          check: false,
        },
        {
          title: '카카오톡 로그인',
          price: 1000000,
          con: '앱에서 로그인 시 카카오톡 계정을 입력하지 않아도 카카오톡 앱을 통해 로그인 가능',
          check: false,
        },
        {
          title: '네이버 로그인',
          price: 1000000,
          con: '앱에서 로그인 시 네이버 계정을 입력하지 않아도 네이버 앱을 통해 로그인 가능',
          check: false,
        },
        {
          title: '구글 로그인',
          price: 1000000,
          con: '앱에서 로그인 시 구글 계정을 입력하지 않아도 구글 앱을 통해 로그인 가능',
          check: false,
        },
        {
          title: '실명 인증',
          price: 1000000,
          con: '실제 본인인지 확인하기 위해 생년월일과 휴대폰 번호 등을 함께 인증 (가입자 입장에서는 번거로움)',
          check: false,
        },
        {
          title: '가입 / 로그인 권한 분리',
          price: 1000000,
          con: 'O2O 서비스의 경우 로그인했을 때 서비스 이용자와 제공자 권한이 분리 필요',
          check: false,
        },
      ],
    },

    {
      mainTitle: '알림 / 채팅 기능',
      sub: '페이스북이나 메신져에서 많이 보이는 기능이라 간단해 보이지만 사실은 구현하기 가장 까다로운 기능들입니다.',
      inputType: 'multiple',
      detail: [
        {
          title: '1:1 문의하기',
          price: 1000000,
          con: '일반적인 게시판이 아닌, 특정 사용자와 어드민만 공유하는 게시판 기능',
          check: false,
        },
        {
          title: '채팅 기능',
          price: 1000000,
          con: '회원간 채팅 기능이나 구매자 / 판매자 사이의 채팅은 복잡도에 따라서 개발 비용 차이 증가',
          check: false,
        },
        {
          title: 'SMS 알림 발송 기능',
          price: 1000000,
          con: 'SMS 알림 발송이 발생하는 상황이 다양한 경우 비용 추가',
          check: false,
        },
        {
          title: '이메일 알림 발송 기능',
          price: 1000000,
          con: '이메일 알림 발송의 경우의 수가 많거나 발송되는 이메일에 디자인이 필요한 경우 비용 추가',
          check: false,
        },
        {
          title: '카카오톡 알림 발송 기능',
          price: 1000000,
          con: '카카오톡의 경우 메시지 템플릿의 승인 절차와 테스트 과정을 진행 필요',
          check: false,
        },
        {
          title: '050 가상 전화번호 연결',
          price: 1000000,
          con: '050 가상 전화번호 연결',
          check: false,
        },
        {
          title: '나에게 온 알림 내역',
          price: 1000000,
          con: "친구 초대가 왔거나 내 글에 '좋아요'가 달렸다는 등의 알림 내역을 표시하는 경우",
          check: false,
        },
      ],
    },

    {
      mainTitle: '푸시 메시지 기능',
      sub: '푸시 메시지 발송 대상이 전체인지, 개별인지, 채널별인지에 따라 복잡도가 증가합니다.',
      inputType: 'radio',
      detail: [
        {
          title: '전체 푸시메시지 발송',
          price: 1000000,
          con: '모든 웹 / 앱 설치자를 대상으로 한 발송은 간단하게 구현 가능',
          check: false,
        },
        {
          title: '개별 사용자 푸시 발송',
          price: 1000000,
          con: '사용자별 디바이스 토큰을 서버에 저장해야 하며 발송 종류가 많을수록 비용 증가',
          check: false,
        },
        {
          title: '채널별 푸시 발송',
          price: 1000000,
          con: '첫 구매 대상 발송, 특정 채팅방 대상 발송 등 사용자를 그루핑해 발송하는 경우',
          check: false,
        },
      ],
    },

    {
      mainTitle: '모바일 네이티브 기능',
      sub: '하이브리드 앱에도 블루투스, QR코드, 백그라운드 위치 추적 등의 네이티브 기능을 구현할 수 있습니다.',
      inputType: 'multiple',
      detail: [
        {
          title: '블루투스 연동',
          price: 1000000,
          con: '블루투스 디바이스와 스마프톤 블루투스를 연결하는 기능',
          check: false,
        },
        {
          title: 'QR / 바코드 코드 인식',
          price: 1000000,
          con: '네이티브 QR / 바코드 인식 모듈을 이용해 코드를 인식하고 페이지 이동 또는 문자열 인식',
          check: false,
        },
        {
          title: '백그라운드 위치 추적',
          price: 1000000,
          con: '앱을 활성화하지 않은 상태에서도 현재 위치를 앱 내에서 저장하거나 서버로 전송하는 기능',
          check: false,
        },
        {
          title: '보상형 광고 설치 추적',
          price: 1000000,
          con: '보상형 광고의 리워드 제공을 위해 앱 설치, 광고 조회 등을 추적하기 위해 네이티브 구현이 필요',
          check: false,
        },
      ],
    },

    {
      mainTitle: '지도 기능',
      sub: '구글 지도, 네이버 지도, 카카오 지도 등을 탑재하되 지도 위의 UI 복잡도에 따라서 공수 차이가 발생합니다.',
      inputType: 'radio',
      detail: [
        {
          title: '지도에 핀을 단순 표시',
          price: 1000000,
          con: '특정 좌표의 지도를 표시만 하는 것',
          check: false,
        },
        {
          title: '지도 위에 UI 표시',
          price: 1000000,
          con: '지도상에 해당 위치에 대한 간략정보 등을 표시하는 팝업 등을 구현하는 경우',
          check: false,
        },
        {
          title: '지도와 검색 결과의 연동',
          price: 1000000,
          con: '검색 결과와 지도의 줌레벨, 중심점 이동 등이 자동으로 연동되어야 하는 경우는 상대적으로 구현하기 때문에 공수 증가',
          check: false,
        },
      ],
    },

    {
      mainTitle: '목록 / 상세 페이지',
      sub: '일반적인 서비스에 비해 상거래 서비스는 장바구니, 배송, 쿠폰 등 복잡도가 높습니다.',
      inputType: 'multiple',
      detail: [
        {
          title: '상품 목록 / 상세',
          price: 1000000,
          con: '상품 또는 O2O 서비스 목록 및 상세 페이지는 일반적인 서비스에 비해 복잡도가 높은 편',
          check: false,
        },
        {
          title: '상품 즐겨찾기 / 담기',
          price: 1000000,
          con: '상품을 즐겨찾기에 담았다가 마이페이지에서 확인하는 기능',
          check: false,
        },
        {
          title: '상품 리뷰 / 별점',
          price: 1000000,
          con: '상품에 별점 및 리뷰를 작성하는 기능이 있는 경우',
          check: false,
        },
        {
          title: '연관 상품',
          price: 1000000,
          con: '특정 상품과 연관된 상품을 표시하는 로직이 복잡한 경우 비용 추가',
          check: false,
        },
        {
          title: '상품 상세조건 필터링',
          price: 1000000,
          con: '상품 가격, 카테고리, 기타 부가 정보를 바탕으로 필터링하는 기능이 있는 경우',
          check: false,
        },
        {
          title: '상품 거리순 필터링',
          price: 1000000,
          con: '주소 / 좌표 기준으로 가까운 순으로 정렬 / 필터링하는 기능',
          check: false,
        },
        {
          title: '웹에서 SNS 공유 기능',
          price: 1000000,
          con: '웹에서 페이스북, 트위터 공유 기능은 상대적으로 간단',
          check: false,
        },
        {
          title: '앱에서 SNS 공유 기능',
          price: 1000000,
          con: '앱에서 페이스북, 카카오톡 공유 기능은 네이티브 SNS SDK를 연동해야 사용하기 편함',
          check: false,
        },
      ],
    },

    {
      mainTitle: '결제 기능',
      sub: '결제 방식을 어떻게 선택하느냐에 따라 구현 비용 차이가 발생합니다.',
      inputType: 'multiple',
      detail: [
        {
          title: '국내 PG사 카드 결제',
          price: 1000000,
          con: '이니시스, 나이스 등의 국내 PG사를 통해 카드 결제를 연동하는 방식',
          check: false,
        },
        {
          title: '가상계좌 결제',
          price: 1000000,
          con: '가상계좌의 경우 주문완료와 결제완료가 분리되어 있어 관리자 / 사용자 주문내역이 복잡',
          check: false,
        },
        {
          title: '페이팔 해외결제',
          price: 1000000,
          con: '페이팔을 연동하면 해외 신용카드와 페이팔 결제 가능 (국내 사용자는 결제가 불가능)',
          check: false,
        },
        {
          title: '실시간 계좌이체, 휴대폰 결제',
          price: 1000000,
          con: '국내 카드 결제와 별도로 휴대폰 결제와 실시간 계좌이체는 결제방식을 따로 저장',
          check: false,
        },
        {
          title: '쿠폰 결제',
          price: 1000000,
          con: '쿠폰을 이용해 가격에 대한 퍼센트 / 금액 할인, 배송비 할인 등 복잡도에 따라 비용 증가',
          check: false,
        },
        {
          title: '적립금 결제',
          price: 1000000,
          con: '적립금의 만료일 기준이 있거나 적립금 차감들을 모두 로깅해야 하는 경우 비용 증가',
          check: false,
        },
        {
          title: '매월 자동 정기결제',
          price: 1000000,
          con: '카드 번호를 입력한 뒤 매월 자동으로 결제해야 하는 자동 결제 정기구독 서비스의 경우 ',
          check: false,
        },
        {
          title: '카드 미리 등록 결제',
          price: 1000000,
          con: '결제시 카드정보 등을 매번 넣지 않고 저장된 카드로 자동 결제하는 경우',
          check: false,
        },
        {
          title: '장바구니 기능',
          price: 1000000,
          con: '상품을 개별적으로 바로 구매하는 것과 비교해 장바구니에 담는 경우 배송 / 쿠폰 등의 절차가 복잡',
          check: false,
        },
        {
          title: '결제 전체 취소',
          price: 1000000,
          con: '사용자 결제 취소 기능은 결제 취소 가능일 등의 조건이 복잡하기 때문에어드민에서의 결제 취소와 비교했을 경우 공수 증가',
          check: false,
        },
        {
          title: '결제 부분 취소',
          price: 1000000,
          con: '구매자가 부분 취소하는 경우 쿠폰 / 적립금 / 배송비 등의 취소금액 산정 기준 계산 필수',
          check: false,
        },
      ],
    },

    {
      mainTitle: '다국어 처리',
      sub: '다국어 처리하는 방식이 다양합니다.',
      inputType: 'radio',
      detail: [
        {
          title: '하나의 외국어로 처리',
          price: 1000000,
          con: '하나의 외국어로만 표시',
          check: false,
        },
        {
          title: '메뉴명만 다국어 처리',
          price: 1000000,
          con: '데이터베이스는 다국어를 고려하지 않고 메뉴명 및 사이트 문구만 다국어로 처리',
          check: false,
        },
        {
          title: '메뉴명과 데이터베이스 다국어 처리',
          price: 1000000,
          con: '메뉴명 / 문구 뿐만 아니라 공지사항이나 게시판 등을 언어별로 분리하여 관리',
          check: false,
        },
        {
          title: '언어별로 프로젝트 분리',
          price: 1000000,
          con: '각국 사용자가 섞이지 말아야 할 때 언어별로 프로젝트를 다른 서버 / 데이터베이스에 배포 및 관리',
          check: false,
        },
      ],
    },

    {
      mainTitle: '고급 기술',
      sub: '고객 서비스에 맞춰 R&D를 병행해야 하는 기능으로 난이도에 따라 개발 비용이 크게 달라질 수 있습니다.',
      inputType: 'multiple',
      detail: [
        {
          title: '딥러닝 연동',
          price: 1000000,
          con: '이미지를 바탕으로 카테고리 자동 추출, 광고글 판별, 매칭 등을 위해 딥러닝을 적용',
          check: false,
        },
        {
          title: '블록체인 스마트 컨트렉트',
          price: 1000000,
          con: '이더리움 / 이오스 스마트컨트랙트에 거래 정보를 기록하고 조회',
          check: false,
        },
        {
          title: '실시간 화상채팅',
          price: 1000000,
          con: 'WebRTC 등을 이용해 실시간 화상 채팅 / 통화를 구현',
          check: false,
        },
      ],
    },

    {
      mainTitle: '타임존',
      sub: '국내 서비스인지, 시간대가 동일한 해외 한 곳의 서비스인지, 시간대가 다양한 서비스인지에따라 다름',
      inputType: 'radio',
      detail: [
        {
          title: '국내만 서비스, 타임존 불필요',
          price: 1000000,
          con: '국내 사용자 대상이기때문에 KST(서울시간기준)만 존재하면 되는 경우',
          check: false,
        },
        {
          title: '사용자별로 다른 타임존 필요',
          price: 1000000,
          con: '다양한 국가의 사용자 대상이여서 각 사용자의 타임존 설정이 필요한 경우',
          check: false,
        },
      ],
    },

    {
      mainTitle: '크롤링 / 스크래핑',
      sub: '특정 페이지의 데이터를 수집해오는 경우, 고객사에서 크롤링에 대한 법적인 권한과 이슈에 대해 검토 및 책임을 지셔야 합니다.',
      inputType: 'multiple',
      detail: [
        {
          title: '서버 to 서버 크롤링',
          price: 1000000,
          con: '수집 대상이 크롤링 서버 IP를 블락하거나 크롤링 페이지의 복잡도가 높은 경우 비용 추가 가능',
          check: false,
        },
        {
          title: '가상 브라우져 스크래핑',
          price: 1000000,
          con: '리액트 등의 프론트엔드 프레임워크가 쓰여 서버 to 서버 크롤링이 불가능한 경우',
          check: false,
        },
        {
          title: '멀티 서버 크롤링',
          price: 1000000,
          con: '크롤러 서버 블락 또는 크롤링 퍼포먼스를 위해 다량의 서버를 활용해야 하는 경우',
          check: false,
        },
      ],
    },

    {
      mainTitle: '기타 외부 API 활용',
      sub: '구글 API 등 외부 API의 사용 복잡도에 따라 다름',
      inputType: 'radio',
      detail: [
        {
          title: '1~2종류의 데이터가 단순한 API 호출',
          price: 1000000,
          con: '요청 건수가 작고 호출시 주고 받는 데이터가 단순한 경우',
          check: false,
        },
        {
          title: '복잡한 데이터를 주고 받는 API',
          price: 1000000,
          con: '4~5 종류의 요청을 보내야 하며 주고 받는 데이터 항목이 5가지 이상 복잡한 경우',
          check: false,
        },
        {
          title: '주기적인 데이터 호출 및 싱크',
          price: 1000000,
          con: '데이터를 호출 후 주기적으로 수집해 자체 DB에 저장해야하는 경우',
          check: false,
        },
      ],
    },

    {
      mainTitle: 'PDF 생성',
      sub: 'PDF 문서 포멧팅의 복잡도에 따라 다름',
      inputType: 'radio',
      detail: [
        {
          title: '형식이 없는 텍스트를 단순하게 PDF화',
          price: 1000000,
          con: '단순한 텍스트만으로 이루어진 PDF를 만들어내는 경우',
          check: false,
        },
        {
          title: '문서 양식에 맞춘 PDF 출력',
          price: 1000000,
          con: '제목, 단락, 표 등 양식이 있는 PDF를 만들어내는 경우',
          check: false,
        },
      ],
    },
    {
      mainTitle: '문서작업 지원',
      sub: '정부지원사업 제출을 위한 기술문서, 인수인계를 위한 주석/문서 작업',
      inputType: 'multiple',
      detail: [
        {
          title: '정부지원사업 제출 문서',
          price: 1000000,
          con: '기술적인 설명, 사업적인 설명을 문서화하는 작업이 필요한 경우 지원 가능',
          check: false,
        },
        {
          title: '세부 코드 주석 사업',
          price: 1000000,
          con: '프레임워크 특성상 주석 없이도 이해가 쉽지만 필요한 경우 주석 정리 가능',
          check: false,
        },
      ],
    },

    {
      mainTitle: '기타 기능',
      sub: '추가로 개발 가능한 기타 기능',
      inputType: 'radio',
      detail: [
        {
          title: '구글 애널리틱스 연동 기본형',
          price: 1000000,
          con: '복잡한 기능이 추가될 경우 견적 추가',
          check: false,
        },
        {
          title: '애니메이션 (아이템별 각각)',
          price: 1000000,
          con: '하트, 별 컬러 채우기, 화면 등장, 화면 전환 등의 경우',
          check: false,
        },
        {
          title: '회원등급 분리 ',
          price: 1000000,
          con: '사용자 구분이 다양한 경우 추가 공수 발생',
          check: false,
        },
      ],
    },
  ];

  constructor() {}

  setEstimate(requireData, additionalData) {
    this.requiredArr = requireData;
    this.additional = additionalData;
  }

  getInfoRequir() {
    return this.requiredArr;
  }
  getInfoAddi() {
    return this.additional;
  }
}
