<div class="modal-wrap">
  <div class="top-btn">
    <button
      (click)="cangeType('service')"
      [ngClass]="{ active: type == 'service' }"
    >
      이용약관
    </button>
    <button
      (click)="cangeType('privacy')"
      [ngClass]="{ active: type == 'privacy' }"
    >
      개인정보보호정첵
    </button>
  </div>
  <div class="content">
    {{ content }}
  </div>
  <div class="botton-btn">
    <button (click)="close()">닫기</button>
  </div>
</div>
