<div class="wrap">
  <header>
    <h3>견적서 정보</h3>
    <button (click)="closePop()">닫기</button>
  </header>

  <main>
    <form action="">
      <fieldset>
        <legend>견적서 정보를 작성해주세요.</legend>

        <p>
          <label for="uname">이름</label>
          <input
            type="text"
            id="uname"
            name="uname"
            placeholder="이름을 입력해주세요."
            [(ngModel)]="userInfo.username"
            (input)="checkInfo()"
          />
          <span *ngIf="nameSwitch">이름을 입력해주세요.</span>
        </p>

        <p>
          <label for="companyName">회사명</label>
          <input
            type="text"
            id="companyName"
            name="companyName"
            placeholder="회사명을 입력해주세요."
            [(ngModel)]="userInfo.company"
            (input)="checkInfo()"
          />
          <span *ngIf="companySwitch">회사명을 입력해주세요.</span>
        </p>
        <p>
          <label for="projectName">프로젝트명</label>
          <input
            type="text"
            id="projectName"
            name="projectName"
            placeholder="프로젝트명을 입력해주세요"
            [(ngModel)]="userInfo.project"
            (input)="checkInfo()"
          />
          <span *ngIf="projectSwitch">프로젝트명을 입력해주세요.</span>
        </p>
      </fieldset>
    </form>
  </main>
</div>

<button
  class="download"
  (click)="savaUserInfo()"
  [disabled]="
    !userInfo.username || !userInfo.company || !userInfo.project || !allComplete
  "
>
  견적서 PDF 다운로드
</button>
