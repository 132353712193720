<!-- <header-share class="main-scroll"></header-share> -->
<main id="main">
  <div class="all-wrap-2">
    <div class="inner-all-wrap">
      <section class="detail-header">
        <h1>포트폴리오</h1>
        <p>
          우리의 포트폴리오는 고객을 위해 구현한 설계 및 개발 솔루션을
          설명합니다.
        </p>
        <p>
          웹 및 모바일 개발 마케팅까지 접근하는 방법에 대한 정확한 통찰력으로
          가득합니다.
        </p>
      </section>

      <section class="appintro-wrap w1180">
        <div class="top">
          <div class="icon">
            <img src="assets/imgs/hanra_app_icon.png" alt="한라앱아이콘" />
          </div>
          <div class="txt-wrap">
            <h3>한라</h3>
            <p>영상으로 공부하는 학습 플랫폼</p>

            <p class="small">
              <span class="bolder">기간</span>2020.12 ~ 2021.04
            </p>
            <p class="small"><span class="bolder">플랫폼</span>Android, iOS</p>
          </div>
        </div>

        <!-- 앱소개이미지 -->
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <img
                src="assets/imgs/hanra_appintro_img_01.png"
                alt="한라앱소개이미지"
              />
            </div>
            <div class="swiper-slide">
              <img
                src="assets/imgs/hanra_appintro_img_02.png"
                alt="한라앱소개이미지"
              />
            </div>
            <div class="swiper-slide">
              <img
                src="assets/imgs/hanra_appintro_img_03.png"
                alt="한라앱소개이미지"
              />
            </div>
            <div class="swiper-slide">
              <img
                src="assets/imgs/hanra_appintro_img_04.png"
                alt="한라앱소개이미지"
              />
            </div>
          </div>
          <ng-container *ngIf="slideSwitch">
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </ng-container>
        </div>

        <div class="explanation-txt">
          <h4>서비스 설명</h4>
          <p>
            한국어가 어려운 외국인을 위해 관심 있는 영상으로 쉽게 배울 수 있도록
            도와주는 영상 학습 서비스
          </p>
        </div>
      </section>

      <section class="our-goal">
        <div class="inner-wrap w1180">
          <div class="title-wrap">
            <h2>우리의 목표</h2>
            <p>
              학습 서비스에서 가장 중요한 것은
              <span class="bolder"
                >'학습에 몰입할 수 있도록, 지속적으로 이어질 수 있도록 도와주는
                경험'</span
              >을 만드는 것 입니다.
            </p>
            <p>
              우리는 이 것을 목표로
              <span class="bolder"
                >'한국어를 쉽게 배우고 싶은 사람들에게 집중'</span
              >하여 오직 학습 사용자를 위한 서비스를 목표로 하였습니다.
            </p>
          </div>
          <div class="circle-wrap">
            <div class="circle">
              <p>자연스러운 탐색</p>
            </div>
            <p class="line"></p>
            <div class="circle">
              <p>
                고민없이 학습에 <br />
                몰입할 수 있는 환경
              </p>
            </div>
            <p class="line"></p>
            <div class="circle">
              <p>지속적인 복습 유도</p>
            </div>
          </div>
        </div>
      </section>

      <section class="user-flow w1180">
        <h2>부드럽게 이어지는 사용자 흐름</h2>
        <div class="chart-img">
          <img
            src="assets/imgs/user_flow_chart_img.png"
            alt="영상시청플로우차트"
          />
        </div>
        <div class="explanation-txt">
          <h4>영상 시청 플로우 차트</h4>
          <p>
            우리는 프로세스 수행에 필요한 단계와 결과를 시각적으로 표한하기 위해
            중점이 되는 설계의 흐름을 시각화 하였습니다.
          </p>
        </div>
      </section>

      <section class="info-structure">
        <div class="chart-wrap"></div>
        <div class="explanation-txt w1180">
          <h4>정보 구조도</h4>
          <p>
            사용자가 어떻게 원하는 기능을 쉽게 찾고, 정보를 자연스럽게 탐색할 수
            있는지 고민하여 같은 성격들의 메뉴들이 잘 그룹핑 되었는지 확인하여
            구조화 하였습니다.
          </p>
        </div>
      </section>

      <section class="ux-design">
        <!-- pc&tablet -->
        <div class="all-wrap pc w1180">
          <div class="inner-content">
            <div class="fixed">
              <div class="title-wrap hide">
                <h2 class="polygon">
                  이탈 없는 <br />
                  탄탄한 UX설계
                </h2>
                <p class="bolder polygon">
                  사용자 경험에 기반하여 사용자들이 쉽고 편한 서비스
                </p>
                <p class="polygon">
                  쉽게 학습 참여를 유도 할 수 있도록 UX를 구성하고, 불필요한
                  기능은 없는지, 누락 된 단계가 없는지 확인 단계를 거치며
                  이탈없는 탄탄한 UX를 설계 하였습니다.
                </p>
              </div>
              <div class="imgs-wrap off">
                <ul>
                  <li>
                    <img
                      src="assets/imgs/ux_design_img_01.png"
                      alt="UX설계이미지01"
                    />
                  </li>
                  <li>
                    <img
                      src="assets/imgs/ux_design_img_02.png"
                      alt="UX설계이미지02"
                    />
                  </li>
                  <li>
                    <img
                      src="assets/imgs/ux_design_img_03.png"
                      alt="UX설계이미지03"
                    />
                  </li>
                  <li>
                    <img
                      src="assets/imgs/ux_design_img_04.png"
                      alt="UX설계이미지04"
                    />
                  </li>
                </ul>
                <ul class="second">
                  <li>
                    <img
                      src="assets/imgs/ux_design_img_05.png"
                      alt="UX설계이미지05"
                    />
                  </li>
                  <li>
                    <img
                      src="assets/imgs/ux_design_img_06.png"
                      alt="UX설계이미지06"
                    />
                  </li>
                  <li>
                    <img
                      src="assets/imgs/ux_design_img_07.png"
                      alt="UX설계이미지07"
                    />
                  </li>
                  <li>
                    <img
                      src="assets/imgs/ux_design_img_08.png"
                      alt="UX설계이미지08"
                    />
                  </li>
                </ul>
                <ul>
                  <li>
                    <img
                      src="assets/imgs/ux_design_img_09.png"
                      alt="UX설계이미지09"
                    />
                  </li>
                  <li>
                    <img
                      src="assets/imgs/ux_design_img_10.png"
                      alt="UX설계이미지10"
                    />
                  </li>
                  <li>
                    <img
                      src="assets/imgs/ux_design_img_11.png"
                      alt="UX설계이미지11"
                    />
                  </li>
                  <li>
                    <img
                      src="assets/imgs/ux_design_img_12.png"
                      alt="UX설계이미지12"
                    />
                  </li>
                </ul>
                <!-- <img
              src="assets/imgs/hanra_uxdesign_img_off.png"
              alt="한라UX설계이미지"
            /> -->
              </div>
            </div>
          </div>
          <div class="inner-content">
            <div class="fixed">
              <div class="title-wrap show">
                <h2 class="polygon">
                  누구나 쉽게 이해할 수 있는 직관적인 디자인
                </h2>
                <p class="bolder polygon">
                  심플한 포맷의 메인 컬러를 활용해 콘텐츠의 시안성을 높이고
                  사용하기 쉬운 인터페이스
                </p>
                <p class="polygon">
                  심플한 UI로 콘텐츠의 집중력을 높이고, 영상에 최적화 된 기능을
                  기반으로 다양한 레이아웃을 구현하여 사용자는 몇 번의 스텝으로
                  쉽고 빠르게 원하는 화면과 기능을 사용할 수 있습니다.
                </p>
              </div>
              <div class="imgs-wrap on">
                <!-- <img
              src="../../../assets/imgs/hanra_uxdesign_img_on.png"
              alt="한라UX디자인이미지"
            /> -->
                <ul class="on">
                  <li>
                    <img
                      src="assets/imgs/ux_design_img_on_01.png"
                      alt="UX설계이미지01"
                    />
                  </li>
                  <li>
                    <img
                      src="assets/imgs/ux_design_img_on_02.png"
                      alt="UX설계이미지02"
                    />
                  </li>
                  <li>
                    <img
                      src="assets/imgs/ux_design_img_on_03.png"
                      alt="UX설계이미지03"
                    />
                  </li>
                  <li>
                    <img
                      src="assets/imgs/ux_design_img_on_04.png"
                      alt="UX설계이미지04"
                    />
                  </li>
                </ul>
                <ul class="on second">
                  <li>
                    <img
                      src="assets/imgs/ux_design_img_on_05.png"
                      alt="UX설계이미지05"
                    />
                  </li>
                  <li>
                    <img
                      src="assets/imgs/ux_design_img_on_06.png"
                      alt="UX설계이미지06"
                    />
                  </li>
                  <li>
                    <img
                      src="assets/imgs/ux_design_img_on_07.png"
                      alt="UX설계이미지07"
                    />
                  </li>
                  <li>
                    <img
                      src="assets/imgs/ux_design_img_on_08.png"
                      alt="UX설계이미지08"
                    />
                  </li>
                </ul>
                <ul class="on">
                  <li>
                    <img
                      src="assets/imgs/ux_design_img_on_09.png"
                      alt="UX설계이미지09"
                    />
                  </li>
                  <li>
                    <img
                      src="assets/imgs/ux_design_img_on_10.png"
                      alt="UX설계이미지10"
                    />
                  </li>
                  <li>
                    <img
                      src="assets/imgs/ux_design_img_on_11.png"
                      alt="UX설계이미지11"
                    />
                  </li>
                  <li>
                    <img
                      src="assets/imgs/ux_design_img_on_12.png"
                      alt="UX설계이미지12"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- mobile -->
        <div class="all-wrap mobile">
          <div class="inner-content">
            <div class="fixed">
              <ul class="off">
                <li>
                  <img
                    src="assets/imgs/ux_design_img_09.png"
                    alt="UX설계이미지09"
                  />
                </li>
                <li>
                  <img
                    src="assets/imgs/ux_design_img_10.png"
                    alt="UX설계이미지10"
                  />
                </li>
                <li>
                  <img
                    src="assets/imgs/ux_design_img_11.png"
                    alt="UX설계이미지11"
                  />
                </li>
                <li>
                  <img
                    src="assets/imgs/ux_design_img_12.png"
                    alt="UX설계이미지12"
                  />
                </li>
              </ul>
              <div class="title-wrap hide">
                <h2 class="polygon">
                  이탈 없는 <br />
                  탄탄한 UX설계
                </h2>
                <p class="bolder polygon">
                  사용자 경험에 기반하여 사용자들이 쉽고 편한 서비스
                </p>
                <p class="polygon">
                  쉽게 학습 참여를 유도 할 수 있도록 UX를 구성하고, 불필요한
                  기능은 없는지, 누락 된 단계가 없는지 확인 단계를 거치며
                  이탈없는 탄탄한 UX를 설계 하였습니다.
                </p>
              </div>
              <ul class="off">
                <li>
                  <img
                    src="assets/imgs/ux_design_img_09.png"
                    alt="UX설계이미지09"
                  />
                </li>
                <li>
                  <img
                    src="assets/imgs/ux_design_img_10.png"
                    alt="UX설계이미지10"
                  />
                </li>
                <li>
                  <img
                    src="assets/imgs/ux_design_img_11.png"
                    alt="UX설계이미지11"
                  />
                </li>
                <li>
                  <img
                    src="assets/imgs/ux_design_img_12.png"
                    alt="UX설계이미지12"
                  />
                </li>
              </ul>
            </div>
          </div>
          <div class="inner-content">
            <div class="fixed">
              <ul class="on">
                <li>
                  <img
                    src="assets/imgs/ux_design_img_on_01.png"
                    alt="UX디자인이미지01"
                  />
                </li>
                <li>
                  <img
                    src="assets/imgs/ux_design_img_on_02.png"
                    alt="UX디자인이미지02"
                  />
                </li>
                <li>
                  <img
                    src="assets/imgs/ux_design_img_on_03.png"
                    alt="UX디자인이미지03"
                  />
                </li>
                <li>
                  <img
                    src="assets/imgs/ux_design_img_on_04.png"
                    alt="UX디자인이미지04"
                  />
                </li>
              </ul>
              <div class="title-wrap show">
                <h2 class="polygon">
                  누구나 쉽게 이해할 수 있는 직관적인 디자인
                </h2>
                <p class="bolder polygon">
                  심플한 포맷의 메인 컬러를 활용해 콘텐츠의 시안성을 높이고
                  사용하기 쉬운 인터페이스
                </p>
                <p class="polygon">
                  심플한 UI로 콘텐츠의 집중력을 높이고, 영상에 최적화 된 기능을
                  기반으로 다양한 레이아웃을 구현하여 사용자는 몇 번의 스텝으로
                  쉽고 빠르게 원하는 화면과 기능을 사용할 수 있습니다.
                </p>
              </div>
              <ul class="on">
                <li>
                  <img
                    src="assets/imgs/ux_design_img_on_09.png"
                    alt="UX디자인이미지09"
                  />
                </li>
                <li>
                  <img
                    src="assets/imgs/ux_design_img_on_10.png"
                    alt="UX디자인이미지10"
                  />
                </li>
                <li>
                  <img
                    src="assets/imgs/ux_design_img_on_11.png"
                    alt="UX디자인이미지11"
                  />
                </li>
                <li>
                  <img
                    src="assets/imgs/ux_design_img_on_12.png"
                    alt="UX디자인이미지12"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section class="reliable-features w1180">
        <div class="title-wrap">
          <h2>서비스에 최적화 된 안정적인 기능</h2>
          <p class="bolder">
            대표 기능을 원활하게 강화된 유연성과 더 안정적인 기능
          </p>
          <p>
            떡볶이라는 아이콘을 통해 원하는 콘텐츠를 자유롭게 시청하며, 다양한
            학습을 할 수 있게 1단계, 2단계, 3단계로 나누어 학습 기능을 세분화
            하였습니다.
          </p>
        </div>

        <ul>
          <li class="left">
            <div class="img">
              <img
                src="assets/imgs/reliable_features_img_01.png"
                alt="안정적인기능이미지01"
              />
            </div>
            <div class="txt-wrap">
              <span class="num">1단계</span>
              <h3>대사, 단어 담기</h3>
              <p>
                관심있는 영상을 시청하며 원하는 대사와 단어를 담고, 한번에 모아
                다시 볼 수 있습니다. 중간 중간 중요한 표현을 중심으로 친절한
                코멘트 또한 설명합니다.
              </p>
            </div>
          </li>
          <li class="right">
            <div class="txt-wrap">
              <span class="num">2단계</span>
              <h3>대사 맞추기</h3>
              <p>
                지정해준 대사를 뽑아 반복재생을 하며, 빈칸을 채워 넣습니다. 긴
                문장들을 여러 번 반복해서 순서를 맞추다보면, 자연스럽게 대사를
                암기 할 수 있습니다.
              </p>
            </div>
            <div class="img">
              <img
                src="assets/imgs/reliable_features_img_02.png"
                alt="안정적인기능이미지02"
              />
            </div>
          </li>
          <li class="left">
            <div class="img">
              <img
                src="assets/imgs/reliable_features_img_03.png"
                alt="안정적인기능이미지03"
              />
            </div>
            <div class="txt-wrap">
              <span class="num">3단계</span>
              <h3>대사 복습하기</h3>
              <p>
                학습한 대사들을 한번에 모아 반복적으로 복습하여 지속적으로 공부
                유도를 할 수 있습니다.
              </p>
            </div>
          </li>
        </ul>
      </section>

      <section class="study-desire w1180">
        <p class="title">이 밖에 학습 욕구를 끌어 올려주는 기능들!</p>
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="img">
                <img
                  src="assets/imgs/study_desire_img_01.png"
                  alt="소통하기이미지"
                />
              </div>
              <div class="txt-wrap">
                <p class="bold">소통하기</p>
                <p class="regular">
                  사용자들과 다양한 소통으로 콘텐츠에 다양한 정보를 얻을 수
                  있습니다.
                </p>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="img">
                <img
                  src="assets/imgs/study_desire_img_02.png"
                  alt="목표달성이미지"
                />
              </div>
              <div class="txt-wrap">
                <p class="bold">목표 달성</p>
                <p class="regular">
                  학습할 시간을 설정하고, 목표를 달성하는 의지를 키울 수
                  있습니다.
                </p>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="img">
                <img
                  src="assets/imgs/study_desire_img_03.png"
                  alt="순위확인이미지"
                />
              </div>
              <div class="txt-wrap">
                <p class="bold">순위 확인</p>
                <p class="regular">
                  다른 유저와 나의 순위를 확인하고, 1등에게는 무료 이용권이
                  주어집니다.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="effective-marketing">
        <div class="w1280">
          <h2>
            지속적으로 <br />
            이용하게 만들어주는 <br />
            효과적인 마케팅까지!
          </h2>
          <div class="alarm-wrap">
            <div class="alarm01">
              <div class="inner-top">
                <p class="logo">한라</p>
                <p class="time">오전 09:10</p>
              </div>
              <p class="blk">
                다양한 주제로 80개의 영상이 업로드 되었어요!<br />지금 바로
                시청하러 가볼까요?
              </p>
            </div>
            <div class="alarm02">
              <div class="inner-top">
                <p class="logo">한라</p>
                <p class="time">오전 11:40</p>
              </div>
              <p class="blk">
                매일 한라를 이용해주시는 고객님께 이용권을<br />
                발급해드렸어요! 마음껏 시청해보세요!
              </p>
            </div>
            <div class="alarm03">
              <div class="inner-top">
                <p class="logo">한라</p>
                <p class="time">오전 02:20</p>
              </div>
              <p class="blk">
                6월 마지막 날 단 하루, 50% 할인 쿠폰 증정! <br />
                어서 서두르세요!
              </p>
            </div>
          </div>
        </div>
      </section>

      <section class="strategy">
        <div class="w1180">
          <p>반드시 필요한, 성공을 위한 전략, 우리의 전략이 궁금하신가요?</p>
          <button>
            <a (click)="goHome()">우리의 프로세스 알아보기 ></a>
          </button>
          <button>
            <a (click)="goInquiry()">문의하기 ></a>
          </button>
        </div>
        <!-- <sticky-button></sticky-button> -->
      </section>

      <section class="bottom-btn" *ngIf="leftItem && rightItem">
        <div class="left">
          <button class="prev">
            <a (click)="goPfDetail(leftItem.id)">
              <img src="assets/imgs/prev_icon.png" alt="이전버튼" />
              <span>{{ leftItem.title }}</span>
            </a>
          </button>
          <div class="bg" *ngIf="leftItem.introImg?.length >= 2">
            <img [src]="leftItem.introImg[0]" alt="" />
            <img [src]="leftItem.introImg[1]" alt="" />
          </div>
          <div
            class="bg"
            *ngIf="
              leftItem.introImg?.length < 2 &&
              itemIndex != 0 &&
              leftItem.appImg?.length >= 2
            "
          >
            <img [src]="leftItem.appImg[0]" alt="" />
            <img [src]="leftItem.appImg[1]" alt="" />
          </div>
        </div>
        <div class="right">
          <button class="next">
            <a (click)="goPfDetail(rightItem.id)">
              <span>{{ rightItem.title }}</span>
              <img src="assets/imgs/next_icon.png" alt="다음버튼" />
            </a>
          </button>

          <div class="bg" *ngIf="rightItem.introImg?.length >= 2">
            <img [src]="rightItem.introImg[0]" alt="" />
            <img [src]="rightItem.introImg[1]" alt="" />
          </div>
          <div
            class="bg"
            *ngIf="
              rightItem.introImg?.length < 2 && rightItem.appImg?.length >= 2
            "
          >
            <img [src]="rightItem.appImg[0]" alt="" />
            <img [src]="rightItem.appImg[1]" alt="" />
          </div>
        </div>
        <div class="opacity"></div>
      </section>
    </div>
    <!-- <div id="portfolio-footer">
    <div class="footer-inner"> -->
    <footer-share id="mainFooter"></footer-share>
    <!-- </div>
  </div> -->
  </div>
</main>
