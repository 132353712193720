import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sticky-button',
  templateUrl: './sticky-button.component.html',
  styleUrls: ['./sticky-button.component.scss'],
})
export class StickyButtonComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
