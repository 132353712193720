<footer id="footer"
  [class.white]="
    path == '/inquiry' ||
    path == '/portfolio-detail' ||
    path == '/portfolio-hanra' ||
    path == '/portfolio'
  ">
  <div class="footer-top"
    *ngIf="planSwitch || designSwitch || developSwitch || marketingSwitch">
    <div class="footer-inner">
      <div class="btns-wrap">
        <div class="btns-inner">
          <button class="prev-btn"
            *ngIf="designSwitch">
            <a (click)="goplan()"></a>UX기획&전략
          </button>
          <button class="prev-btn"
            *ngIf="developSwitch">
            <a (click)="godesign()"></a>UI 디자인
          </button>
          <button class="prev-btn"
            *ngIf="marketingSwitch">
            <a (click)="godevelopment()"></a>Android/iOS 개발
          </button>
          <button class="prev-btn"
            *ngIf="planSwitch">
            <a (click)="gomarketing()"></a>마케팅
          </button>
          <p>더 많은 프로세스를 만나보세요.</p>
          <button class="next-btn"
            *ngIf="marketingSwitch">
            <a (click)="goplan()"></a>UX기획&전략
          </button>
          <button class="next-btn"
            *ngIf="planSwitch">
            <a (click)="godesign()"></a>UI 디자인
          </button>
          <button class="next-btn"
            *ngIf="designSwitch">
            <a (click)="godevelopment()"></a>Android/iOS 개발
          </button>
          <button class="next-btn"
            *ngIf="developSwitch">
            <a (click)="gomarketing()"></a>마케팅
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="footer_wrap">
    <ul class="bt_menu">
      <li>
        <p>홈</p>
        <ul id="homeFooter">
          <li class="to-process">프로세스</li>
          <li class="to-portfolio">포트폴리오</li>
        </ul>
        <ul id="otherFooter">
          <li><a (click)="goHome()">프로세스</a></li>
          <li><a (click)="goPortfolio()">포트폴리오</a></li>
        </ul>
      </li>
      <li>
        <p>업무 서비스</p>
        <ul class="menu-area">
          <li><a (click)="goplan()">UX 기획 & 전략</a></li>
          <li><a (click)="godesign()">UI 디자인</a></li>
          <li><a (click)="godevelopment()">Android/iOS 개발 </a></li>
          <li><a (click)="gomarketing()">마케팅</a></li>
        </ul>
      </li>
      <li class="tab-m">
        <p>기타</p>
        <ul>
          <!-- <li><a href="/simple-estimate">간편 견적 내기</a></li> -->
          <li>
            <a href="https://blog.naver.com/kncurationkorea"
              target="_blank">공식 블로그</a>
          </li>
        </ul>
      </li>
      <li class="contact-m">
        <p>문의하기</p>
        <ul>
          <li class="contact-area">
            <button><a (click)="goInquiry()">문의하기</a></button>
            <ul class="contact-box">
              <li>이메일<span>&nbsp;&nbsp;contact@kncuration.com</span></li>
              <li class="float-right">
                전화상담<span>&nbsp;&nbsp;1644-5786</span>
              </li>
              <li class="add-list">
                주소
                <address>
                  <span class="spacing">&nbsp;&nbsp;</span>서울특별시 성동구 아차산로 17길 48 SKV1센터 1동 1706호,1707호
                </address>
              </li>
              <li class="no-margin">
                개인정보보호책임자<span>&nbsp;&nbsp;고현</span>
              </li>
              <li class="sns_box">
                <ul>
                  <li class="fb">
                    <a href="https://www.facebook.com/startapp.kn"
                      target="_blank">페이스북</a>
                  </li>
                  <!-- <li class="kakao"><a href="#">카카오톡</a></li> -->
                  <li class="insta">
                    <a href="https://www.instagram.com/startapp_kn/"
                      target="_blank">인스타그램</a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>

    <div class="top_btn">
      <button>위로가기</button>
    </div>
  </div>
</footer>
